import React, { useState } from 'react';
import {
    Alert,
    AlertProps,
    Button,
    Snackbar,
    SnackbarProps,
    Stack,
    Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdministrationPage } from 'src/templates/administration-page/AdministrationPage';
import { AdministrationHeader } from 'src/templates/administration-header/AdministrationHeader';
import { AgenciesTable } from 'src/templates/agencies-table/AgenciesTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Agency } from 'src/models/administration/agencyData.model';
import {
    AgencyCreationFormModal,
} from 'src/templates/agency-creation-form-modal/AgencyCreationFormModal';
import {
    AgencyEditionFormModal,
} from 'src/templates/agency-edition-form-modal/AgencyEditionFormModal';
import {
    AgencyConfigFormModal,
} from 'src/templates/agency-config-form-modal/AgencyConfigFormModal';
import {
    ConfigurationOwner,
} from 'src/models/administration/configurationData.model';
import {
    AgencyAlertWordsModal,
} from 'src/templates/agency-alert-words/AgencyAlertWordsModal';

import Grid from '@mui/material/Grid';
import {
    AdminSettingSearchFilter,
} from 'src/templates/admin-setting-search-filter/AdminSettingSearchBar';
import { userIsInRole } from 'src/utils/roleUtils';
import { Role } from 'src/models/roles.enum';

export function AgenciesManagement(): JSX.Element
{
    const { t } = useTranslation();
    const [isCreationModalOpen, setIsCreationModalOpen] = useState<boolean>(false);
    const [clientInEdition, setClientInEdition] = useState<Agency>();
    const [clientInConfig, setClientInConfig] = useState<Agency>();
    const [clientInAlert, setClientInAlert] = useState<Agency>();
    const [notificationState, setNotificationState] = useState<SnackbarProps & AlertProps>();

    // create or edit button
    function onModalClose(): void
    {
        setIsCreationModalOpen(false);
        setClientInEdition(undefined);
    }
    function openCreationModal(): void
    {
        setIsCreationModalOpen(true);
    }
    function onEdit(agency: Agency): void
    {
        setClientInEdition(agency);
    }

    // config button
    function onConfigModalClose(): void
    {
        setClientInConfig(undefined);
    }
    function onConfig(agency: Agency): void
    {
        setClientInConfig(agency);
    }

    // alertwords button
    function onAlertModalClose(): void
    {
        setClientInAlert(undefined);
    }
    function onAlert(agency: Agency): void
    {
        setClientInAlert(agency);
    }

    // All
    function onCloseNotification(): void
    {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }
    function onSaveFinish(success: boolean): () => void
    {
        const subject = t('common.nouns.agency');

        return () =>
        {
            setNotificationState({
                open: true,
                message: success
                    ? t('common.messages.saved-successfully', { subject })
                    : t('common.messages.something-wrong'),
                severity: success ? 'success' : 'error',
            });

            if (success)
            {
                onModalClose();
                onConfigModalClose();
                onAlertModalClose();
            }
        };
    }

    function onDeleteFinish(success: boolean, type?: string): () => void
    {
        const subject = t('common.nouns.agency');

        return () =>
        {
            let message;
            if (success)
            {
                message = type === 'DELETE'
                    ? t('common.messages.Delete-confirmation')
                    : t('common.messages.Undo-successfully', { subject });
            }
            else
            {
                message = t('common.messages.something-wrong');
            }
            setNotificationState({
                open: true,
                message,
                severity: success ? 'success' : 'error',
            });

            if (success)
            {
                onModalClose();
                onConfigModalClose();
                onAlertModalClose();
            }
        };
    }



    return (
        <>
            <AdministrationPage>
                <AdministrationHeader
                    labels={[t('menu.agency-management')]}
                />
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    padding={'1em 0'}
                >
                    <Box sx={{ mr: 5 }}>
                        <Grid
                            item
                            md={3}
                            sm={12}
                            xs={12}
                        >
                            <AdminSettingSearchFilter/>
                        </Grid>
                    </Box>

                    {
                        (userIsInRole([Role.Administrator, Role.Employee])) ? (
                            <Button
                                startIcon={<AddCircleIcon />}
                                onClick={openCreationModal}
                            >
                                {t('agency.add')}
                            </Button>
                        )
                            : null
                    }
                </Stack>
                <AgenciesTable
                    onEdit={onEdit}
                    onConfig={onConfig}
                    onAlert={onAlert}
                />
            </AdministrationPage>
            <AgencyCreationFormModal
                open={isCreationModalOpen}
                onClose={onModalClose}
                onSuccess={onSaveFinish(true)}
                onFailure={onSaveFinish(false)}
            />
            {
                clientInEdition
                    ? (
                        <AgencyEditionFormModal
                            agency={clientInEdition}
                            open={!!clientInEdition}
                            onClose={onModalClose}
                            onSuccess={onSaveFinish(true)}
                            onFailure={onSaveFinish(false)}
                            onDeleteFinish={onDeleteFinish(true, 'DELETE')}
                            onUndo={onDeleteFinish(true, 'UNDO')}
                        />
                    )
                    : null
            }
            {
                clientInConfig
                    ? (
                        <AgencyConfigFormModal
                            agency={clientInConfig}
                            context={ConfigurationOwner.Agency}
                            open={!!clientInConfig}
                            onClose={onConfigModalClose}
                            onSuccess={onSaveFinish(true)}
                            onFailure={onSaveFinish(false)}
                        />
                    )
                    : null
            }
            {
                clientInAlert
                    ? (
                        <AgencyAlertWordsModal
                            agency={clientInAlert}
                            open={!!clientInAlert}
                            onClose={onAlertModalClose}
                        />
                    )
                    : null
            }
            <Snackbar
                autoHideDuration={6000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert
                    severity={notificationState?.severity}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}
