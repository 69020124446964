import React, { useState, useEffect } from 'react';
import 'src/styles/svg.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ScreenCaptureData } from 'src/models/captures/screenCaptureData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getScreenCaptures,
    changeScreenCapture,
    selectFilters,
    selectScreenCaptures,
    selectScreenCapturesStatus,
    changeFilters,
    updateSeenListStatus,
    resetscreenCapturesData,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { Button, CircularProgress, IconButton, Typography } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ScreenCaptureAlertsDetails } from './ScreenCaptureAlertsDetails';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import { ReactComponent as ScoutRed } from 'src/assets/icons/scout-red.svg';
import ScreenCaptureAlertsGrid from './ScreenCaptureAlertsGrid';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { onPageChange, updateEntriesPerPage } from 'src/utils/common';

export function ScreenCaptureAlertsTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();

    const filters = useSelector(selectFilters);
    const screenCapturesStatus = useSelector(selectScreenCapturesStatus);
    const screenCapturesData = useSelector(selectScreenCaptures);

    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [showDetails, setShowDetails] = useState(false);
    const [imageError, setImageError] = useState<string[]>([]);
    const [currentFilter, setCurrentFilter] = useState({});
    const desktopScreen = useDesktopScreen();

    useEffect(() =>
    {
        dispatch(resetscreenCapturesData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.isGridView]);

    useEffect(() =>
    {
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if (filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const rowsPerPage = filters?.rowsPerPage ?? entriesPerPage;
        dispatch(getScreenCaptures({
            isGridView: filters.isGridView,
            pageNumber: checkFilterChange(currentFilter, filters) ? currentPage : 1,
            entriesPerPage: filters.isGridView === true ? 100 : rowsPerPage,
            ...filters,
            alertwordsOnly: filters.isGridView === true ? filters.alertwordsOnly : false,
        }));
        setCurrentFilter(filters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const [datas, setDatas] = useState<string[] | undefined>(undefined);

    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };
    function showScreenCaptureDetails(screenCapture: ScreenCaptureData): void
    {
        openRecordData(screenCapture.id);

        dispatch(changeScreenCapture(screenCapture));
        if (!screenCapture.isSeen)
        {
            dispatch(updateSeenListStatus({
                detailsId: screenCapture.id,
                dataType: DataType.SCREEN_CAPTURES,
            }));
        }
        setShowDetails(true);
    }

    const getAiScanStatusColumn = (data: ScreenCaptureData): React.ReactNode =>
    {
        if (data.aiScanStatus === 2) return <ScoutRed />;
        else if (data.aiScanStatus === 0) return (
            <HourglassEmptyOutlinedIcon
                sx={{ fill: 'lightGrey' }}
            />
        );
        else return ('');
    };
    const getFlagColumn = (data: ScreenCaptureData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.SCREEN_CAPTURES}
            onChange={() =>
            {
                onPageChange(screenCapturesData?.currentPage ?? 1, setCurrentPage);
            }}
        />
    );
    const getImageColumn = (data: ScreenCaptureData): React.ReactNode => (
        imageError.includes(data.id) ? <AccessTimeIcon /> : (
            <img
                height="50"
                width="100"
                src={data.fileUrl}
                onError={() => setImageError([...imageError, data.id])}
                alt={new Date(data.capturedDate).toLocaleString()}
            />
        )
    );
    const openDetailColumn = (data: ScreenCaptureData): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => showScreenCaptureDetails(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.ai'),
            size: 1,
            align: 'center' as const,
            sortByType: 'aiScanStatus',
            format: (data: ScreenCaptureData) => getAiScanStatusColumn(data),
        },
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: ScreenCaptureData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: ScreenCaptureData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: ScreenCaptureData) => new Date(data.capturedDate).toLocaleString(),
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: ScreenCaptureData) => new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: ScreenCaptureData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.program'),
            size: 3,
            value: (data: ScreenCaptureData) => data.appOnFocus,
            sortByType: 'appOnFocus',
        },
        {
            label: t('data-type.table.image'),
            size: 2,
            align: 'center' as const,
            format: (data: ScreenCaptureData) => getImageColumn(data),
        },
        {
            label: t('data-type.table.alert-word'),
            size: 3,
            value: (data: ScreenCaptureData) => data.alertWords?.join(', '),
            sortByType: 'alertWords',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: ScreenCaptureData) => openDetailColumn(data),
        },
    ];

    useEffect(() =>
    {
        if (screenCapturesData)
        {
            pdfExportSetter({
                type: DataType.SCREEN_CAPTURES,
                recordIds: screenCapturesData.contents.map((item) => item.id),
                sortBy: filters.sortBy,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(screenCapturesData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.SCREEN_CAPTURES)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenCapturesData, pdfExportSetter]);
    useEffect(()=>
    {
        if(filters)
        {
            setCurrentPage(1);
        }
    }, [filters]);

    return (
        <>
            <ScreenCaptureAlertsDetails
                open={showDetails}
                onClose={() => setShowDetails(false)}
            />
            <>
                {filters.isGridView === false &&
                    (
                        desktopScreen ? (
                            <DataTable<ScreenCaptureData>
                                isLoading={screenCapturesStatus === RequestStatus.InProgress}
                                columns={columns}
                                entriesPerPage={entriesPerPage}
                                onPageChange={(pageNumber) =>
                                {
                                    onPageChange(pageNumber, setCurrentPage);
                                }}
                                onChangeEntriesPerPage={(entriesPerPage) =>
                                {
                                    updateEntriesPerPage(
                                        entriesPerPage,
                                        setEntriesPerPage,
                                        setCurrentPage,
                                        dispatch
                                    );
                                }}
                                datas={datas}
                                {...screenCapturesData}
                            />
                        ) : (
                            <DataTableMobile
                                contents={screenCapturesData?.contents || []}
                                setOpenModal={showScreenCaptureDetails}
                                isLoading={screenCapturesStatus === RequestStatus.InProgress}
                            />
                        )
                    )
                }
                {filters.isGridView === true &&
                    screenCapturesData && screenCapturesData?.contents?.length > 0 ? (
                        <>
                            <ScreenCaptureAlertsGrid showDetails={showScreenCaptureDetails} />
                            {screenCapturesStatus === RequestStatus.InProgress && (
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <CircularProgress
                                        style={{
                                            width: '4rem',
                                            height: '4rem',
                                            display: 'inline-block',
                                            marginTop: '-4%',
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    ) :
                    filters.isGridView === true &&
                    screenCapturesData && screenCapturesData?.contents?.length === 0 &&
                    screenCapturesStatus === RequestStatus.Success && (
                        <>
                            {filters.alertwordsOnly && (
                                <Button
                                    variant="contained"
                                    sx={{ float: 'right', top: '-4px' }}
                                    onClick={(e) =>
                                    {
                                        dispatch(changeFilters({
                                            alertwordsOnly: !filters.alertwordsOnly,
                                        }));
                                    }}
                                >
                                    {!filters.alertwordsOnly
                                        ? t('form.View-alert-words')
                                        : t('form.No-alert-words')
                                    }
                                </Button>
                            )}
                            <Typography
                                variant="h3"
                                sx={{
                                    color: (theme) => theme.palette.common.lightGrey,
                                    margin: '4rem auto',
                                    textAlign: 'center',
                                }}
                            >
                                {t('data-type.table.no-content')}
                            </Typography>
                        </>
                    )}
                {filters.isGridView === true &&
                    screenCapturesData && screenCapturesData?.contents?.length === 0 &&
                    screenCapturesStatus === RequestStatus.InProgress && (
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <CircularProgress
                            style={{
                                width: '4rem',
                                height: '4rem',
                                display: 'inline-block',
                            }}
                        />
                    </div>
                )}
            </>
        </>
    );
}
