import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ScreenCaptureData } from 'src/models/captures/screenCaptureData.model';
import { selectScreenCapture } from 'src/state/captures/capturesSlice';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { CommentType } from 'src/models/commentType.enum';
import { Button, CircularProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {
    downloadImages,
    handleDragStart,
    toggleFullScreen,
    zoomImage,
} from 'src/utils/common';
import { USER_TIME_ZONE } from 'src/utils/environment';
import { timeZoneFormat } from 'src/utils/dateUtils';
export function ScreenCaptureAlertsDetails(
    { open, onClose }: { readonly open: boolean, readonly onClose: () => void}
): React.ReactElement
{
    const { t } = useTranslation();
    const screenCapture = useSelector(selectScreenCapture);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [scale, setScale] = useState(1);
    const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
    // eslint-disable-next-line
    const handleScroll = (event: any): void =>
    {
        zoomImage(setScale, setImagePosition, event);
    };

    useEffect(() =>
    {
        window.addEventListener('wheel', handleScroll);
        return () =>
        {
            window.removeEventListener('wheel', handleScroll);
        };
    }, [isFullScreen]);

    useEffect(() =>
    {
        setIsFullScreen(false);
        setScale(1);
    }, [open]);

    const dragFunction = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void =>
    {
        handleDragStart(scale, imagePosition, setImagePosition, event);
    };

    const detailItems = [
        {
            label: t('data-type.table.received-date'),
            text: (data: ScreenCaptureData) => timeZoneFormat(
                new Date(data.receivedDate),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.table.captured-date'),
            text: (data: ScreenCaptureData) => timeZoneFormat(
                new Date(data.capturedDate),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: ScreenCaptureData) => data.device,
        },
        {
            label: t('data-type.table.user-name'),
            text: (data: ScreenCaptureData) => data?.username,
        },
        {
            label: t('data-type.table.program'),
            text: (data: ScreenCaptureData) => data?.appOnFocus,
        },
        {
            label: t('data-type.table.platform'),
            text: (data: ScreenCaptureData) => data.platform,
        },
        {
            label: t('data-type.details.alert-words'),
            text: (data: ScreenCaptureData) => data?.alertWords?.join(', '),
        },
    ];

    const imageUrl = screenCapture?.fileUrl;

    return (
        <DataDetailsModal<ScreenCaptureData>
            title={t('data-type.details.screencaptures-title')}
            open={open}
            data={screenCapture}
            detailItems={detailItems}
            onClose={onClose}
            comments={{
                contentId: screenCapture?.id,
                type: CommentType.SCREEN_CAPTURES,
            }}
        >
            {screenCapture && (
                <>
                    <Button
                        variant="text"
                        startIcon={
                            isLoading ?
                                <CircularProgress size="1rem"/> :
                                <DownloadIcon />
                        }
                        onClick={() =>
                        {
                            if(imageUrl)
                            {
                                downloadImages(
                                    imageUrl,
                                    setIsLoading,
                                    null,
                                    screenCapture.fileId
                                );
                            }
                        }}
                    >
                        JPEG
                    </Button>
                    <br/>

                    <div>
                        {isFullScreen ? (
                            <div
                                style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: 999,
                                    cursor: 'pointer',
                                }}
                                onMouseDown={dragFunction}
                            >
                                <button
                                    onClick={() =>
                                    {
                                        toggleFullScreen(
                                            isFullScreen,
                                            setIsFullScreen,
                                            setScale,
                                            setImagePosition
                                        );
                                    }}
                                    style={{
                                        position: 'fixed',
                                        top: '5%',
                                        right: '5%',
                                        background: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontSize: 'xx-large',
                                        color: 'white',
                                        zIndex: 999,
                                    }}
                                >
                                    X
                                </button>
                                <img
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                        transform: `scale(${scale}) 
                                    translate(${imagePosition.x}px, ${imagePosition.y}px)`,
                                        transition: 'transform 0.3s',
                                        cursor: imagePosition.x > 0 || imagePosition.y > 0 ?
                                            'grabbing' : 'pointer',
                                    }}
                                    src={ screenCapture.fileUrl }
                                    alt={ screenCapture.capturedDate }
                                />
                            </div>
                        ) : (
                            <div
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                {
                                    toggleFullScreen(
                                        isFullScreen,
                                        setIsFullScreen,
                                        setScale,
                                        setImagePosition
                                    );
                                }}
                                onKeyDown={(e) => e.preventDefault()}
                            >
                                <img
                                    style={{ width: '100%', height: 'auto' }}
                                    src={ screenCapture.fileUrl }
                                    alt={ screenCapture.capturedDate }
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </DataDetailsModal>
    );
}
