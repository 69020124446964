// eslint-disable-next-line
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TopBar } from 'src/components/top-bar/TopBar';
import { AdministrationPage } from 'src/templates/administration-page/AdministrationPage';
import { UsersTable } from 'src/templates/users-table/UsersTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Role } from 'src/models/roles.enum';
import {
    OfficerCreationFormModal,
} from 'src/templates/officer-creation-modal/OfficerCreationFormModal';
import {
    OfficerEditionFormModal,
} from 'src/templates/officer-edition-modal/OfficerEditionFormModal';
import { User } from 'src/models/userModels';

import Grid from '@mui/material/Grid';
import {
    AdminSettingSearchFilter,
} from 'src/templates/admin-setting-search-filter/AdminSettingSearchBar';
import { Alert, AlertProps, Box, Snackbar, SnackbarProps, Stack } from '@mui/material';
import {
    OfficerConfigFormModal,
} from 'src/templates/officer-config-form-modal/OfficerConfigFormModal';
import {
    ConfigurationOwner,
} from 'src/models/administration/configurationData.model';
import { Officer } from 'src/models/administration/officerData.model';
import { ClientAlertWordsModal } from 'src/templates/client-alert-words/ClientAlertWordsModal';

export function OfficerList(): JSX.Element
{
    const { t } = useTranslation();
    const [isCreationModalOpen, setIsCreationModalOpen] =
        useState<boolean>(false);
    const [isEditionModalOpen, setIsEditionModalOpen] = useState<boolean>(false);
    const [officerInEdition, setOfficerInEdition] = useState<User>();
    const [officerInConfig, setOfficerInConfig] = useState<User>();
    const [officerInAlert, setOfficerInAlert] = useState<Officer>();
    // eslint-disable-next-line
    const [notificationState, setNotificationState] =
        useState<SnackbarProps & AlertProps>();

    // create or edit button
    function onModalClose(): void
    {
        setIsCreationModalOpen(false);
        setIsEditionModalOpen(false);
    }
    function onConfigModalClose(): void
    {
        setOfficerInConfig(undefined);
    }
    function onConfig(Officer: User): void
    {
        setOfficerInConfig(Officer);

    }

    // alertwords button
    function onAlertModalClose(): void
    {
        setOfficerInAlert(undefined);
    }
    function onAlert(user: Officer): void
    {
        setOfficerInAlert(user);
    }

    function onEdit(Officer: User): void
    {
        setOfficerInEdition(Officer);
        setIsEditionModalOpen(true);
    }

    function onCloseNotification(): void
    {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }

    function onSaveFinish(success: boolean): () => void
    {
        const subject = t('common.nouns.configuration');

        return () =>
        {
            setNotificationState({
                open: true,
                message: success
                    ? t('common.messages.saved-successfully', { subject })
                    : t('common.messages.something-wrong'),
                severity: success ? 'success' : 'error',
            });

            if (success)
            {
                onModalClose();
                onConfigModalClose();
                onAlertModalClose();
            }
        };
    }

    return (
        <AdministrationPage>
            <Stack>
                <TopBar

                    labels={[
                        <span key="index" >
                            {t('menu.settings')}
                            {' '}
                            {''}
                            |
                            {' '}
                            {''}
                            {t('menu.officer-and-supervisors-management')}
                        </span>,
                    ]}
                    icon={(
                        <SettingsRoundedIcon
                            fontSize="large"
                        />
                    )}
                />
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    padding={'-1em 0'}
                >
                    <Box sx={{ mr: 3, mt: 2 }}>
                        <Grid
                            item
                            md={3}
                            sm={12}
                            xs={12}
                        >
                            <AdminSettingSearchFilter />
                        </Grid>
                    </Box>
                    <TopBar
                        buttons={[
                            {
                                text: (
                                    <span
                                        style={{
                                            width: '110px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {t('user.create-new-officer')}
                                    </span>
                                ),
                                icon: <AddCircleIcon />,
                                onClick: () => setIsCreationModalOpen(true),
                            },
                        ]}
                    />
                </Stack>
            </Stack>
            <UsersTable
                onEdit={onEdit}
                onConfig={onConfig}
                onAlert={onAlert}
                roles={[Role.Officer, Role.OfficerSupervisor]}
            />
            <OfficerCreationFormModal
                open={isCreationModalOpen}
                onClose={onModalClose}
            />
            {officerInEdition? (
                <OfficerEditionFormModal
                    open={isEditionModalOpen}
                    user={officerInEdition}
                    onClose={onModalClose}
                />
            ) : null}
            {officerInConfig ? (
                <OfficerConfigFormModal
                    user={officerInConfig}
                    context={ConfigurationOwner.Officer}
                    open={!!officerInConfig}
                    onClose={onConfigModalClose}
                    onSuccess={onSaveFinish(true)}
                    onFailure={onSaveFinish(false)}
                />
            ) : null}
            {officerInAlert ? (
                <ClientAlertWordsModal
                    user={officerInAlert}
                    open={!!officerInAlert}
                    onClose={onAlertModalClose}
                />
            ) : null}
            <Snackbar
                autoHideDuration={6000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert
                    severity={notificationState?.severity}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </AdministrationPage>
    );
}
