import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Device } from 'src/models/administration/deviceData.model';
import { noop } from 'src/utils/common';
import { DEFAULT_VALUES, DeviceForm, DeviceFormValues } from '../device-form/DeviceForm';
import { Modal } from 'src/components/modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetSendCodeActivationStatus,
    selectDeviceSaveStatus,
    selectSendActivationCodeStatus,
    sendActivationCode,
    sendDeleteDevice,
    selectDeleteDeviceStatus,
    updateDevice,
} from 'src/state/administration/administrationSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import ConfirmationPanel from '../confirmation-panel/ConfirmationPanel';

export interface DeviceEditionModalProps {
    device: Device;
    activationEmail: string;
    open?: boolean;
    onClose?: () => void;
    onEditSuccess?: () => void;
    onSendingActivationCodeSuccess?: (device: Device, email: string) => void;
    onSendingActivationCodeFailure?: (device: Device, email: string) => void;
}

export function DeviceEditionModal({
    device,
    activationEmail,
    open = false,
    onClose = noop,
    onEditSuccess = noop,
    onSendingActivationCodeSuccess = noop,
    onSendingActivationCodeFailure = noop,
}: Readonly<DeviceEditionModalProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const codeActivationStatus = useSelector(selectSendActivationCodeStatus);
    const updateStatus = useSelector(selectDeviceSaveStatus);
    const deleteStatus = useSelector(selectDeleteDeviceStatus);
    const [initialValues, setInitialValues] = useState<DeviceFormValues>(DEFAULT_VALUES);
    const loading = [codeActivationStatus, updateStatus].includes(RequestStatus.InProgress);
    const loadingDelete = [deleteStatus].includes(RequestStatus.InProgress);
    const [deletePerformed, setDeletePerformed] = useState(false);
    const [openAlertModal, setOpenAlertModal] = useState(false);

    function onSubmit(data: DeviceFormValues): void
    {
        dispatch(updateDevice({
            id: device.id,
            alias: data.alias,
            enabled: data.enabled,
            disableDevice: data.disableDevice,
        }));
    }

    function onSendActivationCode(): void
    {
        dispatch(sendActivationCode({
            device: device.id,
            email: activationEmail,
        }));
    }

    function onSendDelete(): void
    {
        if (!deletePerformed)
        {
            setDeletePerformed(true);
            setOpenAlertModal(true);
        }
    }
    function DeleteSubmit(): void
    {
        if (device)
        {
            dispatch(sendDeleteDevice(device.id));
            setOpenAlertModal(false);
        }
    }
    function DeleteModal(): void
    {
        setDeletePerformed(false);
        setOpenAlertModal(false);
    }

    useEffect(() =>
    {
        if (device)
        {
            setInitialValues(device);
        }
    }, [device]);

    useEffect(() =>
    {
        if (codeActivationStatus === RequestStatus.Success)
        {
            onSendingActivationCodeSuccess(device, activationEmail);
            dispatch(resetSendCodeActivationStatus());
        }
        else if (codeActivationStatus === RequestStatus.Failure)
        {
            onSendingActivationCodeFailure(device, activationEmail);
            dispatch(resetSendCodeActivationStatus());
        }
    }, [
        codeActivationStatus,
        dispatch,
        onSendingActivationCodeSuccess,
        onSendingActivationCodeFailure,
        device,
        activationEmail,
    ]);

    useEffect(() =>
    {
        if (
            updateStatus === RequestStatus.Success ||
             deleteStatus === RequestStatus.Success
        )
        {
            if (deletePerformed)
            {
                onEditSuccess();
                onSendDelete();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteStatus]);

    return (
        <>
            <Modal
                open={open}
                title={t('devices.edit-device')}
                onClose={onClose}
                showCloseButton={true}
                maxWidth="sm"
                fullWidth
            >
                <Box>
                    <DeviceForm
                        id={device.id}
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onCancel={onClose}
                        edit={true}
                        loading={loading}
                        loadingDelete={loadingDelete}
                        onSendActivationCode={onSendActivationCode}
                        onSendDelete={onSendDelete}
                    />
                </Box>
            </Modal>
            <Modal
                title={t('devices.delete-device')}
                open={openAlertModal}
                maxWidth="md"
                fullWidth
                onClose={DeleteModal}
                showCloseButton={true}
            >
                <Box>
                    <ConfirmationPanel
                        data={device?.alias ?? ''}
                        DeleteModal={DeleteModal}
                        DeleteSubmit={DeleteSubmit}
                        type={'Device'}
                    />
                </Box>
            </Modal>
        </>
    );
}
