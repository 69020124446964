import React from 'react';

export interface CreateButtonProps{
    width: string,
    label: string,
}

export function CreateButton({ width, label }: Readonly<CreateButtonProps>): JSX.Element
{
    return (
        <span
            style={{
                width,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            {label}
        </span>
    );
}
