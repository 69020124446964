import React from 'react';
import Box from '@mui/material/Box';

import { Header } from 'src/templates/header/Header';
import { DataTypeFilters } from 'src/templates/data-type-filters/DataTypeFilters';
import TableExporterProvider from 'src/components/table-exporter/TableExporterProvider';
import TableDownloadBar from 'src/components/table-download-bar/TableDownloadBar';
import Grid from '@mui/material/Grid';
import { DataTypeFlagFilter } from '../data-type-flag-filter/DataTypeFlagFilter';
import { DataTypeSelect } from '../data-type-select/DataTypeSelect';
import { DataTypeSearchFilter } from '../data-type-search-filter/DataTypeSearchFilter';
import { DataTypeProfileSelect } from '../data-type-profile/DataTypeProfileSelect';

export interface DataTypePageProps
{
    children: React.ReactNode;
}

export function DataTypePage(
    { children }: Readonly<DataTypePageProps>
): React.ReactElement
{
    return (
        <>
            <Header />
            <DataTypeFilters />

            <Box m={4}>
                <TableExporterProvider>
                    <Grid container sx={{ m: '10px 0' }}>
                        <Grid
                            item
                            md={7}
                            sm={12}
                            xs={12}
                        >
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    md={3}
                                    sm={12}
                                    xs={12}
                                >
                                    <DataTypeSelect />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    sm={12}
                                    xs={12}
                                >
                                    <DataTypeProfileSelect />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    sm={12}
                                    xs={12}
                                >
                                    <DataTypeFlagFilter />
                                </Grid>
                                <Grid
                                    item
                                    md={3}
                                    sm={12}
                                    xs={12}
                                >
                                    <DataTypeSearchFilter />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            md={5}
                            sm={12}
                            xs={12}
                        >
                            <TableDownloadBar isRefresh={true}/>
                        </Grid>
                    </Grid>
                    {children}
                </TableExporterProvider>
            </Box>
        </>
    );
}
