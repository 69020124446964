import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { ReportDevicesSelector } from '../reports-table/ReportDevicesSelector';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { Report } from 'src/models/administration/reportData.model';
import { Frequency, frequencyTranslationKey } from 'src/models/frequency.enum';
import { DataType, dataTypeStringKey } from 'src/models/dataType.enum';
import {
    createReport,
    getUsersNoPageable,
    selectReportCreateStatus,
    selectReportError,
    selectReportUpdateStatus,
    selectUsersNoPageable,
    updateReport,
} from 'src/state/administration/administrationSlice';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { REPORTS_MANAGMENT } from 'src/utils/routes';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { AnimatedEllipsis } from 'src/components/animated-ellipsis/AnimatedEllipsis';
import { User } from 'src/models/userModels';
import { getErrorConstraints, handleError, handleErrorText } from 'src/utils/errorUtils';
import { DataDetailComment } from '../data-type-detail/DataDetailComment';
import { CommentType } from 'src/models/commentType.enum';
import { endOfDay, isToday } from 'date-fns';
import {
    getDeviceFilterOptions,
    selectDeviceFilterOptions,
} from 'src/state/captures/capturesSlice';
import {
    DropdownOption,
    DropdownSelector,
} from 'src/components/dropdown-selector/DropdownSelector';
import { Theme } from '@mui/material';
import { Flag, flagStringKey } from 'src/models/flag.enum';
import { FlagIcon } from 'src/components/flag-icon/FlagIcon';
import { ReactComponent as ScoutRed } from 'src/assets/icons/scout-red.svg';
import { ReportsFormat } from 'src/models/reportsFormat.enum';

const chipStyle = {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    '.MuiSvgIcon-root': {
        color: '#FFFFFF73',
    },
    '.MuiSvgIcon-root:hover': {
        color: '#FFFFFFAB',
    },
    margin: '0 0 5px 5px',
};

export function ReportForm(props: Readonly<Report>): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const reportCreateStatus = useSelector(selectReportCreateStatus);
    const reportUpdateStatus = useSelector(selectReportUpdateStatus);
    const reportError = useSelector(selectReportError);
    const users = useSelector(selectUsersNoPageable);
    const devices = useSelector(selectDeviceFilterOptions);

    const [report, setReport] = useState<Report>(props);

    const deviceName = (report.devices ?? []).map((deviceId) =>
    {
        const matchingDevice = devices ?
            devices.find((device) => device.value === deviceId) : undefined;

        if (matchingDevice)
        {
            return {
                name: matchingDevice.name,
                value: matchingDevice.value,
            };
        }
        return null;
    }).filter((device) => device !== null);

    const Flags: DropdownOption[] = Object.values(Flag).map((value) =>
    {
        return {
            name: t(flagStringKey(value)),
            value,
            icon: <FlagIcon style={{ width: 14, height: 14 }} flag={value} />,
        };
    }).filter((flag) => flag.value !== 'NONE');
    Flags.push({
        name: 'AiScanStatus',
        value: 'aiScanStatus',
        icon: <ScoutRed style={{ width: 14, height: 14 }} />,
    });

    const ReportFormats: DropdownOption[] = Object.values(ReportsFormat).map((value) =>
    {
        return {
            name: t(value),
            value,
        };
    });

    const [selectedFlags, setSelectedFlags] =
        useState(Flags.map(flag => flag.value));

    const [selectedFormat, setSelectedFormat] =
        useState(ReportsFormat.PDF);

    const deviceNameValues = deviceName
        .map(device => device?.name as string);

    useEffect(() =>
    {
        if(!props.id)
        {
            setReport({
                ...props,
                flags: selectedFlags,
                reportFormat: selectedFormat,
                isActive: true,
            });
        }

        dispatch(getUsersNoPageable({ }));
        dispatch(getDeviceFilterOptions({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(
            RequestStatus.Success === reportCreateStatus
            || RequestStatus.Success === reportUpdateStatus
        )
        {
            navigate(REPORTS_MANAGMENT);
        }
    }, [navigate, reportCreateStatus, reportUpdateStatus]);

    const frequencies = Object.values(Frequency).map((value) =>
    {
        return {
            name: t(frequencyTranslationKey(value)),
            value,
        };
    });

    const dataTypes = Object.values(DataType)
        .map((value) => ({
            name: t(dataTypeStringKey(value)),
            value,
        }))
        .filter((option) => option.name.trim() !== '');

    function handleSubmit(event: React.FormEvent<HTMLFormElement>): void
    {
        event.preventDefault();
        if(report.id)
        {
            dispatch(updateReport(report));
        }
        else
        {
            dispatch(createReport(report));
        }
    }

    function isLoading(): boolean
    {
        return RequestStatus.InProgress === reportCreateStatus
        || RequestStatus.InProgress === reportUpdateStatus;
    }

    function getUsersLabel(user: User) : string
    {
        return `${user.firstName} ${user.lastName} <${user.email}>`;
    }

    return (
        <>
            {reportError?.messages?.length ? (
                <Alert severity="error" variant="filled" sx={{ mb: 5 }}>
                    <AlertTitle sx={{ fontSize: 16 }}>
                        {t('form.validation-errors')}
                    </AlertTitle>
                    <ul>
                        {reportError?.messages.map((error, key) => (
                            <li key={`row-${key + 1}`}>
                                {error}
                            </li>
                        ))}
                    </ul>
                </Alert>
            ) : ''
            }
            { users ? (
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <Grid container spacing={5}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                        >
                            <Grid spacing={5} direction={'column'}>
                                <Grid item style={{ paddingBottom: '40px' }}>
                                    <TextField
                                        fullWidth
                                        id="name"
                                        value={report?.name}
                                        onChange={(event) =>
                                            setReport({
                                                ...report,
                                                name: event.target.value,
                                            })}
                                        label={t('form.name')+ ' *'}
                                        name="name"
                                        autoFocus
                                        error={handleError(reportError, 'name')}
                                        helperText={handleErrorText(reportError, 'name')}
                                    />
                                </Grid>
                                <Grid item style={{ paddingBottom: '40px' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="frequency"
                                        value={
                                            frequencies.find(
                                                frequency => report?.frequency === frequency.value
                                            )
                                        }
                                        options={frequencies ?? []}
                                        onChange={(_, data) =>
                                            setReport({ ...report, frequency: data?.value })}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value}
                                        getOptionLabel={(option) => option.name}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t('form.frequency')+ ' *'}
                                                error={handleError(reportError, 'frequency')}
                                                helperText={handleErrorText(
                                                    reportError,
                                                    'frequency'
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                {report?.frequency === Frequency.ONCE ? (
                                    <>
                                        <Grid item style={{ paddingBottom: '30px' }}>
                                            <Grid container spacing={2} >
                                                <Grid item xs={6}>
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                    >
                                                        <DatePicker
                                                            label={t('form.start-date')}
                                                            value={report.startDate ?? null}
                                                            onChange={(date) =>
                                                                setReport(
                                                                    {
                                                                        ...report,
                                                                        startDate: date
                                                                        ?? undefined,
                                                                    }
                                                                )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    fullWidth
                                                                    {...params}
                                                                    error={
                                                                        handleError(
                                                                            reportError,
                                                                            'startDate'
                                                                        )}
                                                                    helperText={
                                                                        handleErrorText(
                                                                            reportError,
                                                                            'startDate'
                                                                        )}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={6} style={{ paddingBottom: '30px' }}>
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                    >
                                                        <DatePicker
                                                            label={t('form.end-date')}
                                                            value={report.endDate ?? null}
                                                            onChange={(date) =>
                                                            {
                                                                let endDate = date ?? undefined;
                                                                if (endDate)
                                                                {
                                                                    if (isToday(endDate))
                                                                    {
                                                                        endDate = new Date();
                                                                    }
                                                                    else
                                                                    {
                                                                        endDate = endOfDay(endDate);
                                                                    }
                                                                }
                                                                setReport(
                                                                    {
                                                                        ...report,
                                                                        endDate,
                                                                    }
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    fullWidth
                                                                    {...params}
                                                                    error={handleError(
                                                                        reportError,
                                                                        'endDate'
                                                                    )}
                                                                    helperText={
                                                                        handleErrorText(
                                                                            reportError,
                                                                            'endDate'
                                                                        )}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {
                                            (report.endDate && report.endDate > new Date()) && (
                                                <Grid item>
                                                    <Alert severity="warning">
                                                        {t('reports.end-date-alert')}
                                                    </Alert>
                                                </Grid>
                                            )
                                        }
                                    </>
                                )
                                    : ''}
                                <Grid item style={{ paddingBottom: '40px' }}>
                                    <Autocomplete
                                        disablePortal
                                        id="dataType"
                                        value={
                                            dataTypes.find(data => report.dataType === data.value)
                                        }
                                        options={dataTypes ?? []}
                                        onChange={(_, data) => setReport({
                                            ...report,
                                            dataType: data?.value,
                                        })}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value}
                                        getOptionLabel={(option) => option.name}
                                        sx={{ width: '100%' }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t('form.data-type')+ ' *'}
                                                error={handleError (
                                                    reportError,
                                                    'dataType'
                                                )}
                                                helperText={handleErrorText(
                                                    reportError,
                                                    'dataType'
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item style={{ paddingBottom: '40px' }}>
                                    <DropdownSelector
                                        options={Flags}
                                        multiple
                                        fullWidth
                                        disabled={false}
                                        checkBoxSelection
                                        label={t('data-type.filters.filter-by-flag')}
                                        value={(report?.flags ?? []).length > 0 ?
                                            report.flags : selectedFlags
                                        }
                                        onChange={(value) =>
                                        {
                                            const Value = value as string[];
                                            setReport({ ...report, flags: Value });
                                            setSelectedFlags(Value);
                                        }}
                                        inputProps={{
                                            sx: {
                                                backgroundColor:
                                                    (theme: Theme) => theme.palette.common.white,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item style={{ paddingBottom: '40px' }}>
                                    <DropdownSelector
                                        options={ReportFormats}
                                        fullWidth
                                        label={t('File Format')}
                                        value={ report?.reportFormat ?? selectedFormat }
                                        onChange={(value) =>
                                        {
                                            const Value = value as ReportsFormat;
                                            setReport({ ...report, reportFormat: Value });
                                            setSelectedFormat(Value);
                                        }}
                                        inputProps={{
                                            sx: {
                                                backgroundColor:
                                                    (theme: Theme) => theme.palette.common.white,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item style={{ paddingBottom: '40px' }}>
                                    <FormControlLabel
                                        control={(
                                            <ToggleSwitch
                                                sx={{ m: 2 }}
                                                checked={report.isActive}
                                                onChange={(_, value) => setReport({
                                                    ...report,
                                                    isActive: value,
                                                })}
                                            />
                                        )}
                                        label={t('form.report-status')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                        >
                            <Grid spacing={5} direction={'column'}>
                                <Grid item style={{ paddingBottom: '40px' }}>
                                    <ReportDevicesSelector
                                        deviceAlias={deviceNameValues}
                                        values={report.devices}
                                        onChange={(values) => setReport({
                                            ...report,
                                            devices: values,
                                        })}
                                        error={handleError(reportError, 'devices')}
                                        helperText={handleErrorText(
                                            getErrorConstraints('devices', reportError),
                                            'devices'
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        multiple
                                        id="users"
                                        value={
                                            users?.filter(
                                                user => report.users?.find(
                                                    reportUser => reportUser === user.id
                                                )
                                            )
                                        }
                                        size="small"
                                        options={users ?? []}
                                        getOptionLabel={getUsersLabel}
                                        filterSelectedOptions
                                        onChange={(_, values) =>
                                            setReport({
                                                ...report,
                                                users: values.map(user => user.id as string),
                                            })}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t('form.reports-recipients')+ ' *'}
                                                error={handleError(reportError, 'users')}
                                                helperText={handleErrorText(
                                                    getErrorConstraints('users', reportError),
                                                    'users'
                                                )}
                                            />
                                        )}
                                        ChipProps={{ sx: chipStyle }}
                                    />
                                </Grid>
                                {report.id && (
                                    <Grid item>
                                        <DataDetailComment
                                            type={CommentType.REPORT}
                                            contentId={report.id}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
                        <Button
                            sx={{
                                mr: 1,
                                backgroundColor: 'transparent',
                                color: theme => theme.palette.common.goldYellow,
                                '&.Mui-disabled': {
                                    backgroundColor: 'inherit',
                                    color: theme => theme.palette.common.goldYellow,
                                },
                            }}
                            onClick={() => navigate(REPORTS_MANAGMENT)}
                        >
                            {t('form.cancel')}
                        </Button>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                                mr: 1,
                                minWidth: '7.5rem',
                                '&.Mui-disabled': {
                                    backgroundColor: theme => theme.palette.primary.main,
                                    color: theme => theme.palette.primary.contrastText,
                                },
                            }}
                            disabled={isLoading()}
                        >
                            {t('form.save-changes')}
                            {isLoading() && <AnimatedEllipsis />}
                        </Button>
                    </Box>
                </Box>
            ) : '' }
        </>

    );
}
