import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { Frequency, frequencyTranslationKey } from 'src/models/frequency.enum';
import {
    createNotificationConfiguration,
    getUsersNoPageable,
    selectNotificationConfigurationCreateStatus,
    selectNotificationConfigurationError,
    selectNotificationConfigurationUpdateStatus,
    selectUsersNoPageable,
    updateNotificationConfiguration,
} from 'src/state/administration/administrationSlice';
import { NOTIFICATIONS_MANAGMENT } from 'src/utils/routes';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { AnimatedEllipsis } from 'src/components/animated-ellipsis/AnimatedEllipsis';
import { User } from 'src/models/userModels';
import { getErrorConstraints, handleError, handleErrorText } from 'src/utils/errorUtils';
import { NotificationConfiguration } from 'src/models/administration/notificationData.model';

import { NotificationDevicesSelector } from '../notifications-table/NotificationDevicesSelector';
import {
    NotificationType,
    notificationTypeTranslationKey,
} from 'src/models/administration/notificationType.enum';
import { removeDuplicates } from 'src/utils/common';
import {
    getDeviceFilterOptions,
    selectDeviceFilterOptions,
} from 'src/state/captures/capturesSlice';

const chipStyle = {
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    '.MuiSvgIcon-root': {
        color: '#FFFFFF73',
    },
    '.MuiSvgIcon-root:hover': {
        color: '#FFFFFFAB',
    },
    margin: '0 0 5px 5px',
};

export function NotificationsForm(props: Readonly<NotificationConfiguration>): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const notificationConfigurationCreateStatus = useSelector(
        selectNotificationConfigurationCreateStatus
    );
    const notificationConfigurationUpdateStatus = useSelector(
        selectNotificationConfigurationUpdateStatus
    );
    const notificationConfigurationError = useSelector(selectNotificationConfigurationError);
    const users = useSelector(selectUsersNoPageable);
    const devices = useSelector(selectDeviceFilterOptions);

    const [notificationConfiguration, setNotificationConfiguration] =
        useState<NotificationConfiguration>(props);
    const deviceName = (notificationConfiguration.devices ?? []).map((deviceId) =>
    {
        const matchingDevice = devices ?
            devices.find((device) => device.value === deviceId) : undefined;

        if (matchingDevice)
        {
            return {
                name: matchingDevice.name,
                value: matchingDevice.value,
            };
        }
        return null;
    }).filter((device) => device !== null);

    const deviceNameValues = deviceName
        .map(device => device?.name as string);
    useEffect(() =>
    {
        if (!props.id)
        {
            setNotificationConfiguration({ ...props, isActive: true });
        }

        dispatch(getUsersNoPageable({}));
        dispatch(getDeviceFilterOptions({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if (
            RequestStatus.Success === notificationConfigurationCreateStatus ||
            RequestStatus.Success === notificationConfigurationUpdateStatus
        )
        {
            navigate(NOTIFICATIONS_MANAGMENT);
        }
    }, [navigate, notificationConfigurationCreateStatus, notificationConfigurationUpdateStatus]);

    const frequencies = Object.values(Frequency).map(value =>
    {
        return {
            name: t(frequencyTranslationKey(value)),
            value,
        };
    });
    const notificationTypes = Object.values(NotificationType).map(value =>
    {
        return {
            name: t(notificationTypeTranslationKey(value)),
            value,
        };
    });

    function handleSubmit(event: React.FormEvent<HTMLFormElement>): void
    {
        event.preventDefault();
        const updatedNotificationConfiguration =
            notificationConfiguration.triggers?.alertWords &&
        notificationConfiguration.triggers.alertWords.length > 0
                ? notificationConfiguration
                : { ...notificationConfiguration, triggers: undefined };

        if (notificationConfiguration.id)
        {
            dispatch(updateNotificationConfiguration(updatedNotificationConfiguration));
        }
        else
        {
            dispatch(createNotificationConfiguration(updatedNotificationConfiguration));
        }
    }

    function isLoading(): boolean
    {
        return (
            RequestStatus.InProgress === notificationConfigurationCreateStatus ||
            RequestStatus.InProgress === notificationConfigurationUpdateStatus
        );
    }

    function getUsersLabel(user: User): string
    {
        return `${user.firstName} ${user.lastName} <${user.email}>`;
    }

    function onChangeWordList(
        event: React.SyntheticEvent<Element, Event>,
        value: (string | string[])[]
    ): void
    {
        const words = value.map((word) =>
        {
            if (Array.isArray(word))
            {
                return word.join('');
            }

            return word;
        }).flat();

        setNotificationConfiguration({
            ...notificationConfiguration,
            triggers: { alertWords: removeDuplicates(words) },
        });
    }

    return (
        <>
            {notificationConfigurationError?.messages?.length ? (
                <Alert severity="error" variant="filled" sx={{ mb: 5 }}>
                    <AlertTitle sx={{ fontSize: 16 }}>
                        {t('form.validation-errors')}
                    </AlertTitle>
                    <ul>
                        {notificationConfigurationError?.messages.map((error, key) => (
                            <li key={`row-${key + 1}`}>
                                {error}
                            </li>
                        ))}
                    </ul>
                </Alert>
            ) : (
                ''
            )}
            {users ? (
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={5}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                        >
                            <Grid container spacing={5} direction={'column'}>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        id="name"
                                        value={notificationConfiguration?.name}
                                        onChange={event =>
                                            setNotificationConfiguration({
                                                ...notificationConfiguration,
                                                name: event.target.value,
                                            })
                                        }
                                        label={t('form.name')+ ' *'}
                                        name="name"
                                        autoFocus
                                        error={handleError(notificationConfigurationError, 'name')}
                                        helperText={handleErrorText(
                                            notificationConfigurationError,
                                            'name'
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        disablePortal
                                        id="frequency"
                                        value={frequencies.find(
                                            frequency =>
                                                notificationConfiguration.frequency ===
                                                frequency.value
                                        )}
                                        options={frequencies ?? []}
                                        onChange={(_, data) =>
                                            setNotificationConfiguration({
                                                ...notificationConfiguration,
                                                frequency: data?.value,
                                            })
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option?.value === value?.value
                                        }
                                        getOptionLabel={option => option.name}
                                        sx={{ width: '100%' }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={t('form.frequency')+ ' *'}
                                                error={handleError(
                                                    notificationConfigurationError,
                                                    'frequency'
                                                )}
                                                helperText={handleErrorText(
                                                    notificationConfigurationError,
                                                    'frequency'
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        disablePortal
                                        id="notificationType"
                                        value={notificationTypes.find(
                                            data => notificationConfiguration.type === data.value
                                        )}
                                        options={notificationTypes ?? []}
                                        onChange={(_, data) =>
                                            setNotificationConfiguration({
                                                ...notificationConfiguration,
                                                type: data?.value,
                                            })
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option?.value === value?.value
                                        }
                                        getOptionLabel={option => option.name}
                                        sx={{ width: '100%' }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={t('form.notification-type')+ ' *'}
                                                error={handleError(
                                                    notificationConfigurationError,
                                                    'type'
                                                )}
                                                helperText={handleErrorText(
                                                    notificationConfigurationError,
                                                    'type'
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        multiple
                                        id="notificationAlertWords"
                                        options={[]}
                                        filterSelectedOptions
                                        freeSolo
                                        onChange={onChangeWordList}
                                        value={
                                            notificationConfiguration?.triggers?.alertWords ?? []
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t('notifications.alert-words')+ ' *'}
                                                error={handleError(
                                                    notificationConfigurationError,
                                                    'triggers'
                                                )}
                                                helperText={handleErrorText(
                                                    notificationConfigurationError,
                                                    'triggers'
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={(
                                            <ToggleSwitch
                                                sx={{ m: 2 }}
                                                checked={notificationConfiguration.isActive}
                                                onChange={(_, value) =>
                                                    setNotificationConfiguration({
                                                        ...notificationConfiguration,
                                                        isActive: value,
                                                    })
                                                }
                                            />
                                        )}
                                        label={t('form.notification-status')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                        >
                            <Grid container spacing={5} direction={'column'}>
                                <Grid item>
                                    <NotificationDevicesSelector
                                        deviceAlias={deviceNameValues}
                                        values={notificationConfiguration.devices}
                                        onChange={values =>
                                            setNotificationConfiguration({
                                                ...notificationConfiguration,
                                                devices: values,
                                            })
                                        }
                                        error={handleError(
                                            notificationConfigurationError,
                                            'deviceIds'
                                        )}
                                        helperText={handleErrorText(
                                            getErrorConstraints('deviceIds', notificationConfigurationError),
                                            'deviceIds'
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        multiple
                                        id="users"
                                        value={users?.filter(user =>
                                            notificationConfiguration.users?.find(
                                                notificationUser => notificationUser === user.id
                                            ))}
                                        size="small"
                                        options={users ?? []}
                                        getOptionLabel={getUsersLabel}
                                        filterSelectedOptions
                                        onChange={(_, values) =>
                                            setNotificationConfiguration({
                                                ...notificationConfiguration,
                                                users: values.map(user => user.id as string),
                                            })
                                        }
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label={t('form.notification-recipients')+ ' *'}
                                                error={handleError(
                                                    notificationConfigurationError,
                                                    'userIds'
                                                )}
                                                helperText={handleErrorText(
                                                    getErrorConstraints('userIds', notificationConfigurationError),
                                                    'userIds'
                                                )}
                                            />
                                        )}
                                        ChipProps={{ sx: chipStyle }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
                        <Button
                            sx={{
                                mr: 1,
                                backgroundColor: 'transparent',
                                color: theme => theme.palette.common.goldYellow,
                                '&.Mui-disabled': {
                                    backgroundColor: 'inherit',
                                    color: theme => theme.palette.common.goldYellow,
                                },
                            }}
                            onClick={() => navigate(NOTIFICATIONS_MANAGMENT)}
                        >
                            {t('form.cancel')}
                        </Button>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{
                                mr: 1,
                                minWidth: '7.5rem',
                                '&.Mui-disabled': {
                                    backgroundColor: theme => theme.palette.primary.main,
                                    color: theme => theme.palette.primary.contrastText,
                                },
                            }}
                            disabled={isLoading()}
                        >
                            {t('form.save-changes')}
                            {isLoading() && <AnimatedEllipsis />}
                        </Button>
                    </Box>
                </Box>
            ) : (
                ''
            )}
        </>
    );
}
