import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import {
    clearNotificationConfigurations,
    getNotificationConfigurations,
    selectNotificationConfiguration,
    selectNotificationConfigurationCreateStatus,
    selectNotificationConfigurationFilters,
    selectNotificationConfigurations,
    selectNotificationConfigurationUpdateStatus,
    selectNotificationConfigurationDeleteStatus,
    selectNotificationsStatus,
    setNotificationConfiguration,
    deleteNotificationConfiguration,
    selectUsersNoPageable,
    getUsersNoPageable,
} from 'src/state/administration/administrationSlice';
import { DataTable, ColumnData } from 'src/components/data-table/DataTable';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { NOTIFICATIONS_EDIT } from 'src/utils/routes';
import { ContextMenu } from 'src/components/context-menu/ContextMenu';
import { NotificationDetails } from './NotificationDetails';
import { NotificationsModal } from './NotificationsModal';
import { NotificationConfiguration } from 'src/models/administration/notificationData.model';
import {
    getDeviceFilterOptions,
    selectDeviceFilterOptions,
} from 'src/state/captures/capturesSlice';
import { Modal } from 'src/components/modal/Modal';
import { Box } from '@mui/material';
import ConfirmationPanel from 'src/templates/confirmation-panel/ConfirmationPanel';

export function NotificationsTable(): React.ReactElement
{
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const items = useSelector(selectNotificationConfigurations);
    const notificationConfiguration = useSelector(
        selectNotificationConfiguration
    );
    const filters = useSelector(selectNotificationConfigurationFilters);
    const status = useSelector(selectNotificationsStatus);
    const notificationConfigurationCreateStatus = useSelector(
        selectNotificationConfigurationCreateStatus
    );
    const notificationConfigurationEditStatus = useSelector(
        selectNotificationConfigurationUpdateStatus
    );
    const notificationConfigurationDeleteStatus = useSelector(
        selectNotificationConfigurationDeleteStatus
    );
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [
        notificationConfigurationCreated,
        setNotificationConfigurationCreated,
    ] = useState(false);
    const [
        notificationConfigurationUpdated,
        setNotificationConfigurationUpdated,
    ] = useState(false);
    const [
        notificationConfigurationDeleted,
        setNotificationConfigurationDeleted,
    ] = useState(false);
    const [openAlertModal, setOpenAlertModal] = React.useState(false);
    const users = useSelector(selectUsersNoPageable);
    const devices = useSelector(selectDeviceFilterOptions);

    function DeleteSubmit(): void
    {
        if (notificationConfiguration)
        {
            dispatch(deleteNotificationConfiguration(notificationConfiguration.id as string));
            setOpenAlertModal(false);
        }
    }
    function DeleteModal(): void
    {
        setOpenAlertModal(false);
    }

    function onPageChange(pageNumber: number): void
    {
        dispatch(
            getNotificationConfigurations({ pageNumber, entriesPerPage, ...filters })
        );
    }

    useEffect(() =>
    {
        setTimeout(() => dispatch(clearNotificationConfigurations()), 1000);
        setNotificationConfigurationCreated(
            notificationConfigurationCreateStatus === RequestStatus.Success
        );
        setNotificationConfigurationUpdated(
            notificationConfigurationEditStatus === RequestStatus.Success
        );
        setNotificationConfigurationDeleted(
            notificationConfigurationDeleteStatus === RequestStatus.Success
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationConfigurationDeleteStatus]);

    useEffect(() =>
    {
        dispatch(
            getNotificationConfigurations({
                pageNumber: 1,
                entriesPerPage,
                ...filters,
            })
        );
        dispatch(getUsersNoPageable({}));
        dispatch(getDeviceFilterOptions({}));
    }, [dispatch,
        entriesPerPage,
        filters,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        notificationConfigurationDeleteStatus === RequestStatus.Success]);

    const detailItems = [
        {
            label: t('form.name'),
            text: (data: NotificationConfiguration) => data.name?.toString() ?? '',
        },
        {
            label: t('form.notification-type'),
            text: (data: NotificationConfiguration) => data.type?.toString() ?? '',
        },
        {
            label: t('form.frequency'),
            text: (data: NotificationConfiguration) =>
                data.frequency?.toString() ?? '',
        },
        {
            label: t('form.notification-status'),
            text: (data: NotificationConfiguration) =>
                data.isActive ? t('common.toggle.on') : t('common.toggle.off'),
        },
        {
            label: t('form.devices'),
            text: (data: NotificationConfiguration) =>
            {
                const deviceNames = (data.devices ?? []).map((deviceId) =>
                {
                    const matchingDevice = devices ?
                        devices.find((device) => device.value === deviceId) : undefined;
                    return matchingDevice ? matchingDevice.name : '';
                });

                return deviceNames.join(', ');
            },
        },
        {
            label: t('form.notification-recipients'),
            text: (data: NotificationConfiguration) =>
            {
                const recipientsInformations = (data.users ?? []).map((userId) =>
                {
                    const matchingUser = users ?
                        users.find((user) => user.id === userId) : undefined;
                    return matchingUser ?
                        `${matchingUser.firstName}
                         ${matchingUser.lastName}
                         (${matchingUser.email})` : '';
                });

                return recipientsInformations.join(', ');
            },
        },
    ];

    const options = [
        {
            label: t('notifications.edit-notification'),
            onClick: (data: NotificationConfiguration) =>
            {
                dispatch(setNotificationConfiguration(data));
                navigate(generatePath(NOTIFICATIONS_EDIT, { id: data.id as string }));
            },
        },
        {
            label: t('notifications.view-notifications'),
            onClick: (data: NotificationConfiguration) =>
            {
                dispatch(setNotificationConfiguration(data));
                setOpen(true);
            },
        },
        {
            label: t('notifications.delete-notification'),
            onClick: (data: NotificationConfiguration) =>
            {
                dispatch(setNotificationConfiguration(data));
                setOpenAlertModal(true);
            },
        },
    ];

    const getActionColumn = (data: NotificationConfiguration): React.ReactNode => (
        <ContextMenu<NotificationConfiguration> options={options} data={data} />
    );

    const columns: ColumnData<NotificationConfiguration>[] = [
        {
            label: t('data-type.table.date-of-creation'),
            size: 3,
            value: (data: NotificationConfiguration) =>
                new Date(data?.createdAt ?? '')?.toLocaleString(),
        },
        {
            label: t('data-type.table.notification-title'),
            size: 8,
            value: (data: NotificationConfiguration) => data.name,
        },
        {
            label: t('data-type.table.notification-type'),
            size: 8,
            value: (data: NotificationConfiguration) => data.type,
        },
        {
            label: t('data-type.table.frequency'),
            size: 4,
            value: (data: NotificationConfiguration) => data.frequency,
        },
        {
            label: t('data-type.table.action'),
            size: 1,
            align: 'center' as const,
            format: (data: NotificationConfiguration) => getActionColumn(data),
        },
    ];

    return (
        <>
            <Snackbar
                open={notificationConfigurationCreated}
                autoHideDuration={6000}
                onClose={() => setNotificationConfigurationCreated(false)}
            >
                <Alert severity="success">
                    {t('notifications.notification-created')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={notificationConfigurationUpdated}
                autoHideDuration={6000}
                onClose={() => setNotificationConfigurationUpdated(false)}
            >
                <Alert severity="success">
                    {t('notifications.notification-updated')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={notificationConfigurationDeleted}
                autoHideDuration={6000}
                onClose={() => setNotificationConfigurationDeleted(false)}
            >
                <Alert severity="success">
                    {t('notifications.notification-deleted')}
                </Alert>
            </Snackbar>
            <NotificationsModal
                title={t('notifications.notification-list')}
                detailItems={detailItems}
                open={open}
                data={notificationConfiguration}
                showComments={false}
                onClose={() => setOpen(false)}
            >
                <NotificationDetails />
            </NotificationsModal>
            <DataTable<NotificationConfiguration>
                isLoading={status === RequestStatus.InProgress}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={setEntriesPerPage}
                {...items}
            />
            <Modal
                title={t('notifications.delete-notification')}
                open={openAlertModal}
                maxWidth="md"
                fullWidth
                onClose={DeleteModal}
                showCloseButton={true}
            >
                <Box>
                    <ConfirmationPanel
                        data={notificationConfiguration?.name ?? ''}
                        DeleteModal={DeleteModal}
                        DeleteSubmit={DeleteSubmit}
                        type={'Notification'}
                    />
                </Box>
            </Modal>
        </>
    );
}
