export enum Role {
    Administrator = 'Administrator',
    AgencyAdministrator = 'AgencyAdministrator',
    Employee = 'Employee',
    Monitor = 'Monitor',
    OfficerSupervisor = 'OfficerSupervisor',
    Officer = 'Officer',
}

export function roleStringKey(role: Role): string
{
    switch (role)
    {
    case Role.Administrator:
        return 'common.role.administrator';
    case Role.AgencyAdministrator:
        return 'common.role.agency-administrator';
    case Role.Employee:
        return 'common.role.employee';
    case Role.OfficerSupervisor:
        return 'common.role.officer-supervisor';
    case Role.Officer:
        return 'common.role.officer';
    case Role.Monitor:
        return 'common.role.monitor';
    default:
        return '';
    }
}
export enum Actions {
    DELETE = 'DELETE',
    VISIT = 'visit',
    STOP = 'STOP',
    NON_AI_AW_DELETE_ON = 'NON-AI/AW DELETE-ON',
    NON_AI_AW_DELETE_OFF = 'NON-AI/AW DELETE-OFF',
  }
