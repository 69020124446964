import React, { useEffect, useState } from 'react';
import { Alert, AlertProps, Box, CircularProgress, Snackbar, SnackbarProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdministrationHeader } from 'src/templates/administration-header/AdministrationHeader';
import { useParams } from 'react-router-dom';
import {
    clearConfigs,
    createConfiguration,
    getConfiguration,
    getParentConfiguration,
    getPartialConfiguration,
    selectConfiguration,
    selectSaveConfigurationStatus,
    selectParentConfiguration,
    selectPartialConfiguration,
    updateConfiguration,
    resetSaveConfigurationStatus,
    selectItemInConfiguration,
    selectItemInConfigurationStatus,
    setItemInConfiguration,
} from 'src/state/administration/administrationSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    ConfigurationOwner,
    ConfigurationParams,
    ConfigurationDetail,
    ParentConfigurationParams,
} from 'src/models/administration/configurationData.model';
import { ConfigurationPanel } from 'src/templates/configuration-panel/ConfigurationPanel';
import { Header } from 'src/templates/header/Header';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { Device } from 'src/models/administration/deviceData.model';
import { Agency } from 'src/models/administration/agencyData.model';
import { Client } from 'src/models/administration/clientData.model';
import { User } from 'src/models/userModels';

const containerStyle = {
    textAlign: 'center',
};

export type AppSettingsPathParams = {
    id: string,
}

export interface ApplicationSettingsManagementProps {
    context: ConfigurationOwner;
}

const titles: { [key: string]: string } = {
    [ConfigurationOwner.Agency]: 'agency-management',
    [ConfigurationOwner.Client]: 'clients-management',
    [ConfigurationOwner.Device]: 'device-management',
    [ConfigurationOwner.Officer]: 'user-management',
};

const emptyPartial: ConfigurationDetail = {
    services: [],
    blockedApps: [],
    blockedUrls: [],
    blockUninstall: false,
    blockAccessSetting: false,
    disableConfig: false,
    isLogCapture: false,
    isUninstallNotification: true,
    permissionScreenAccess: true,
};

export function ApplicationSettingsManagement({
    context,
}: Readonly<ApplicationSettingsManagementProps>): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams<AppSettingsPathParams>();
    const owner = useSelector(selectItemInConfiguration);
    const ownerRetrieveStatus = useSelector(selectItemInConfigurationStatus);
    const [configurationId, setConfigurationId] = useState<string>('');
    const configuration = useSelector(selectConfiguration);
    const parentConfiguration = useSelector(selectParentConfiguration);
    const partialConfiguration = useSelector(selectPartialConfiguration);
    const saveStatus = useSelector(selectSaveConfigurationStatus);
    const [notificationState, setNotificationState] = useState<SnackbarProps & AlertProps>();
    const [
        partialConfigurationToUse,
        setPartialConfigurationToUse,
    ] = useState<ConfigurationDetail|undefined>();
    const [
        fallbackConfigurationToUse,
        setFallbackConfigurationToUse,
    ] = useState<ConfigurationDetail|undefined>();

    function onSave(configurationDetail: ConfigurationDetail): void
    {
        if (configurationId)
        {
            partialConfiguration && dispatch(updateConfiguration({
                ...partialConfiguration,
                ...configurationDetail,
            }));
        }
        else if (params.id)
        {
            dispatch(createConfiguration({
                ...partialConfiguration,
                id: configurationId,
                ownerType: context,
                ownerId: params.id,
                ...configurationDetail,
            }));
        }
    }

    function onCancel(): void
    {
        setPartialConfigurationToUse(
            partialConfiguration
                ? { ...partialConfiguration }
                : { ...emptyPartial }
        );
        setFallbackConfigurationToUse(
            parentConfiguration
                ? { ...parentConfiguration }
                : undefined
        );
    }

    function onCloseNotification(): void
    {
        dispatch(resetSaveConfigurationStatus());
    }

    function getOwnerTitle(owner: Agency | Client | Device | User): string
    {
        switch (context)
        {
        case ConfigurationOwner.Agency:
            return (owner as Agency).agencyName ?? '';
        case ConfigurationOwner.Client:
        {
            const client = (owner as Client);
            return `${client.firstName} ${client.lastName}`;
        }
        case ConfigurationOwner.Device:
            return (owner as Device).alias;

        case ConfigurationOwner.Officer: {
            const officer = owner as User;
            return `${officer.firstName} ${officer.lastName}`;
        }
        default:
            return '';
        }
    }

    useEffect(() =>
    {
        if (partialConfiguration && partialConfiguration.ownerId === params.id)
        {
            setPartialConfigurationToUse(partialConfiguration);
            setConfigurationId(partialConfiguration.ownerId);
        }
        else
        {
            setPartialConfigurationToUse(undefined);
        }
    }, [partialConfiguration, params]);

    useEffect(() =>
    {
        if (configuration)
        {
            if (configuration.ownerId === params.id && configuration.id)
            {
                dispatch(getPartialConfiguration(configuration.id));
            }
            else
            {
                setPartialConfigurationToUse(emptyPartial);
            }
        }
    }, [configuration, params, dispatch]);

    useEffect(() =>
    {
        if (parentConfiguration) setFallbackConfigurationToUse(parentConfiguration);
    }, [parentConfiguration]);

    useEffect(() =>
    {
        if (owner && params.id)
        {

            const configurationParams: ConfigurationParams = {
                ownerId: params.id,
                ownerType: context,
            };

            const parentConfigurationParams: ParentConfigurationParams = {
                child: owner,
                ownerType: context,
            };

            dispatch(getConfiguration(configurationParams));
            dispatch(getParentConfiguration(parentConfigurationParams));
        }
    }, [owner, dispatch, context, params]);

    useEffect(() =>
    {
        if (params.id)
        {
            const configurationParams: ConfigurationParams = {
                ownerId: params.id,
                ownerType: context,
            };

            dispatch(setItemInConfiguration(configurationParams));
        }
    }, [params, context, dispatch]);

    useEffect(() =>
    {
        let message = '';
        const subject = t('common.nouns.configuration');

        if (saveStatus === RequestStatus.Success)
        {
            message = t('common.messages.saved-successfully', { subject });
        }
        else if (saveStatus === RequestStatus.Failure)
        {
            message = t('common.messages.something-wrong');
        }

        setNotificationState({
            open: !!message,
            message,
            severity: saveStatus === RequestStatus.Success ? 'success' : 'error',
        });
    }, [saveStatus, t]);

    useEffect(() =>
    {
        return () =>
        {
            dispatch(clearConfigs());
        };
    }, [dispatch]);

    if (!params.id)
    {
        return <CircularProgress />;
    }

    return (
        <>
            <Header />
            <Box m={4}>
                {
                    ownerRetrieveStatus === RequestStatus.InProgress || !owner
                        ? <CircularProgress />
                        : (
                            <>
                                <AdministrationHeader
                                    labels={[
                                        t(`menu.${titles[context]}`),
                                        getOwnerTitle(owner),
                                        t('menu.application-settings'),
                                    ]}
                                />
                                <Box sx={containerStyle}>
                                    {
                                        (
                                            partialConfigurationToUse
                                            && fallbackConfigurationToUse
                                        )
                                            ? (
                                                <ConfigurationPanel
                                                    context={context}
                                                    ownerId={params.id}
                                                    configuration={partialConfigurationToUse}
                                                    fallbackConfiguration={
                                                        fallbackConfigurationToUse
                                                    }
                                                    onSave={onSave}
                                                    onCancel={onCancel}
                                                    isLoading={
                                                        saveStatus === RequestStatus.InProgress
                                                    }
                                                    disableConfig={false}
                                                    isLogCapture={false}
                                                    isUninstallNotification={false}
                                                />
                                            )
                                            : <CircularProgress />
                                    }
                                </Box>
                            </>
                        )
                }
            </Box>
            <Snackbar
                autoHideDuration={6000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert
                    severity={notificationState?.severity}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}
