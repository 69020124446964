import React, { useEffect, useState } from 'react';
import {
    InstalledProgramsData,
    ProgramData,
} from 'src/models/captures/installedProgramsData.model';
import { selectAppcompares } from 'src/state/captures/capturesSlice';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { DataTable } from 'src/components/data-table/DataTable';
import { PaginatedContent } from 'src/models/paginatedResult.model';

export function InstalledProgramsDetails(
    data: Readonly<InstalledProgramsData>
): React.ReactElement
{
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const appscompareData = useSelector(selectAppcompares);
    const [appsData, setAppsData]=useState<PaginatedContent<ProgramData>>();

    const [page, setPage] = useState(1);
    const[loader, setLoader]=useState(true);
    useEffect(()=>
    {
        if(appscompareData)
        {
            const startIndex = (page - 1) * entriesPerPage;
            const endIndex = startIndex + entriesPerPage;
            const paginatedArray = appscompareData.slice(startIndex, endIndex);
            setAppsData({
                contents: paginatedArray,
                currentPage: page,
                numberOfEntries: appscompareData.length,
            });
            if(appscompareData.length>0)
            {
                setLoader(false);
            }
        }

    }, [appscompareData, entriesPerPage, page]);

    if (!appscompareData)
    {
        return (
            <div>
                <CircularProgress />
            </div>
        );
    }

    const getPreviousColumn = (program: ProgramData): React.ReactNode => (
        <p>
            {program.previousProgram && (
                program.previousProgram.programName === '' &&
            program.previousProgram.status === '' &&
            program.previousProgram.version === ''
            ) ? (
            // eslint-disable-next-line react/jsx-indent
                    <>
                    -
                    </>
                ) : (
                    <>
                        {program.previousProgram.status}
                        (
                        {
                            program.previousProgram.version
                        }
                        )
                    </>
                )
            }
        </p>
    );
    const columns = [
        {
            label: 'Action',
            size: 1,
            align: 'center' as const,
            value: (data: ProgramData) => data.currentProgram.programName,

        },
        {
            label: 'Previous',
            size: 1,
            align: 'center' as const,
            format: (data: ProgramData) => getPreviousColumn(data),

        },
        {
            label: 'Latest',
            size: 1,
            align: 'center' as const,
            value: (data: ProgramData) =>
                `${data.currentProgram.status} (${data.currentProgram.version})`,
        },
        {
            label: 'Date',
            size: 1,
            align: 'center' as const,
            value: (data: ProgramData) => new Date(
                data.currentProgram.installedAt?.toString()
            ).toLocaleString(),

        },


    ];
    const handleEntriesPerPageChange = (newEntriesPerPage: number): void =>
    {
        setEntriesPerPage(newEntriesPerPage);
        setPage(1);
        setLoader(true);

    };
    function onPageChange(pageNumber: number): void
    {
        setPage(pageNumber);
        setLoader(true);
    }
    return (
        <div>
            <DataTable<ProgramData>
                isLoading={loader}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={handleEntriesPerPageChange}
                datas={appsData}
                {...appsData}
            />

        </div>
    );
}
