import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SearchData } from 'src/models/captures/searchData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    changeColumns,
    changeFilters,
    changeTableDataType,
    changeTotalRecord,
    getSearchDetails,
    getSearches,
    selectFilters,
    selectSearchDetails,
    selectSearchDetailsStatus,
    selectSearches,
    selectSearchesStatus,
    selectallPageData,
    updateSeenListStatus,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import { IconButton } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { CommentType } from 'src/models/commentType.enum';
import { HighlightText } from 'src/components/highlight-text/HighlightText';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { USER_TIME_ZONE } from 'src/utils/environment';
import { timeZoneFormat } from 'src/utils/dateUtils';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';

export function SearchesTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();
    const filters = useSelector(selectFilters);
    const searchesStatus = useSelector(selectSearchesStatus);
    const searchesData = useSelector(selectSearches);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [currentFilter, setCurrentFilter] = useState({});
    const searchDetailsStatus = useSelector(selectSearchDetailsStatus);
    const searchDetailsData = useSelector(selectSearchDetails);
    const [datas, setDatas] = useState<string[] | undefined>(undefined);
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const allPageData = useSelector(selectallPageData);
    const [lastPage, setLastPage]=useState(0);
    const prevPage = useRef(1);
    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };
    const handleOpen = (data: SearchData): void =>
    {
        openRecordData(data.id);

        dispatch(getSearchDetails(data.id));
        if (!data.isSeen)
        {
            dispatch(updateSeenListStatus({
                detailsId: data.id,
                dataType: DataType.WEBSITES_SEARCH,
            }));
        }
        setShowModal(true);
    };

    function onPageChange(pageNumber: number): void
    {
        if (searchesData && pageNumber!==lastPage &&
            (pageNumber === prevPage.current + 1 || pageNumber === prevPage.current - 1))
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(searchesData?.contents[searchesData.contents.length - 1]?.id);
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(Array.isArray(lastRecordDatas) && lastRecordDatas.length > 0 ?
                    lastRecordDatas[lastRecordDatas.length - 1]?.id : '');
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        prevPage.current = pageNumber;
    }
    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }
    useEffect(() =>
    {
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if (filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const rowsPerPage = filters?.rowsPerPage !== undefined ?
            filters.rowsPerPage :
            entriesPerPage;

        dispatch(getSearches({
            skipRecordId,
            pageNumber: checkFilterChange(currentFilter, filters) ? currentPage : 1,
            entriesPerPage: rowsPerPage,
            ...filters,
        }));
        setCurrentFilter(filters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const getFlagColumn = (data: SearchData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.WEBSITES_SEARCH}
            onChange={() =>
            {
                onPageChange(searchesData?.currentPage ?? 1);
            }}
        />
    );
    const getQueryColumn = (data: SearchData): React.ReactNode => (
        <HighlightText
            textToHighlight={data?.query || ''}
            highlightWords={data?.alertWords || ['']}
        />
    );
    useEffect(()=>
    {
        if(filters)
        {
            setCurrentPage(1);
        }
    }, [filters]);
    const openDetailColumn = (data: SearchData): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: SearchData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: SearchData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: SearchData) => new Date(data.capturedDate)?.toLocaleString(),
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: SearchData) => new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: SearchData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.user-agent'),
            size: 2,
            value: (data: SearchData) => data.userAgent,
            sortByType: 'userAgent',
        },
        {
            label: t('data-type.table.search-engine'),
            size: 2,
            value: (data: SearchData) => data.searchEngine,
            sortByType: 'searchEngine',
        },
        {
            label: t('data-type.table.query'),
            size: 4,
            value: (data: SearchData) => data?.query,
            sortByType: 'query',
            format: (data: SearchData) => getQueryColumn(data),
        },
        {
            label: t('data-type.details.alert-words'),
            value: (data: SearchData) => data?.alertWords?.join(', '),
            sortByType: 'alertWords',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: SearchData) => openDetailColumn(data),
        },
    ];

    const detailItems = [
        {
            label: t('data-type.details.received-date'),
            text: (data: SearchData) => timeZoneFormat(
                new Date(data?.receivedDate),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.table.captured-date'),
            text: (data: SearchData) => timeZoneFormat(
                new Date(data?.capturedDate),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: SearchData) => data.device,
        },

        {
            label: t('data-type.table.search-engine'),
            text: (data: SearchData) => data.searchEngine,
        },
        {
            label: t('data-type.details.alert-words'),
            text: (data: SearchData) => data?.alertWords?.join(', '),
        },
        {
            label: t('data-type.table.platform'),
            text: (data: SearchData) => data.platform,
        },
        {
            label: t('data-type.table.user-agent'),
            text: (data: SearchData) => data.userAgent,
        },
        {
            label: t('data-type.table.query'),
            text: (data: SearchData) => data?.query,
        },
    ];

    useEffect(() =>
    {
        if (searchesData)
        {
            setLastPage(Math.floor(searchesData.numberOfEntries/entriesPerPage));
            pdfExportSetter({
                type: DataType.WEBSITES_SEARCH,
                recordIds: searchesData.contents.map((item) => item.id),
                sortBy: filters.sortBy,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(searchesData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.WEBSITES_SEARCH)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchesData, pdfExportSetter, entriesPerPage, filters.sortBy]);

    return (
        <>
            <DataDetailsModal<SearchData>
                title={t('data-type.details.searches-title')}
                open={showModal}
                data={searchDetailsData}
                detailItems={detailItems}
                isLoading={searchDetailsStatus === RequestStatus.InProgress}
                onClose={() => setShowModal(false)}
                comments={{
                    contentId: searchDetailsData?.id,
                    type: CommentType.WEBSITES_SEARCH,
                }}
            />
            {useDesktopScreen() ? (
                <DataTable<SearchData>
                    isLoading={searchesStatus === RequestStatus.InProgress}
                    columns={columns}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber)=>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage)=>
                    {
                        updateEntriesPerPage(entriesPerPage );
                    }}
                    datas={datas}
                    {...searchesData}
                />
            ) : (
                <DataTableMobile
                    isLoading={searchesStatus === RequestStatus.InProgress}
                    contents={searchesData?.contents || []}
                    setOpenModal={handleOpen}
                    onPageChange={(pageNumber)=>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage)=>
                    {
                        updateEntriesPerPage(entriesPerPage );
                    }}
                    entriesPerPage={entriesPerPage}
                    numberOfEntries={searchesData?.numberOfEntries}
                    currentPage={searchesData?.currentPage}
                    enablePagination
                />
            )}
        </>
    );
}
