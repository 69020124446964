import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { AlertsDetailsData } from 'src/models/captures/alertsData.model';
import { HighlightText } from 'src/components/highlight-text/HighlightText';
import { secondsToHHMMSS } from 'src/utils/dateUtils';
import { Platform } from 'src/models/platforms.enum';
import { TextSMSDetails } from '../text-sms-alerts-table/TextSMSDetails';
import { SMSType } from 'src/models/captures/textSMSData.model';
import { GeolocationDetails } from '../geolocation-alerts-table/GeolocationDetails';
import { LogonEventDetails } from '../logon-event-alerts-table/LogonEventDetails';
import { LogonEventData } from 'src/models/captures/logonEventData.model';
import { PrintedDocumentDetail } from '../printed-document-alerts-table/PrintedDocumentDetail';
import { downloadImages, handleDragStart, toggleFullScreen, zoomImage } from 'src/utils/common';
import { Button, CircularProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {
    InstalledProgramsDetails,
} from '../installed-programs-alerts-table/InstalledProgramsDetails';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAppsCompareDetails,
    selectInstalledProgramsDetails,
} from 'src/state/captures/capturesSlice';
import { InstalledProgramsData } from 'src/models/captures/installedProgramsData.model';

export function LandingPageDetails({
    receivedDate,
    dataType,
    fileUrl,
    id,
    deviceId,
    details,
    alertWords,
    flag,
}: Readonly<AlertsDetailsData>): React.ReactElement
{
    function extractGeolocationDetails(details: string ): [number, number, string]
    {
        const coordinatesRegex =
            /Latitude:\s*(-?\d+\.\d+),\s*Longitude:\s*(-?\d+\.\d+),\s*Date:\s*(.*)/;
        const matches = coordinatesRegex.exec(details);
        if (matches && matches.length === 4)
        {
            const latitude = parseFloat(matches[1].trim());
            const longitude = parseFloat(matches[2].trim());
            const dateStr = matches[3].trim();
            const date = new Date(dateStr);
            const formattedDate = date.toISOString();

            return [latitude, longitude, formattedDate];
        }
        else
        {
            return [0, 0, ''];
        }
    }

    function extractSMSDetails(details: string): [string, string, string]
    {
        const coordinatesRegex = /externalNumber: (.*), deviceNumber: (.*), username: (.*)/;
        const matches = coordinatesRegex.exec(details);
        if (matches && matches.length === 4)
        {
            const externalNumber = matches[1].trim();
            const deviceNumber = matches[2].trim();
            const username = matches[3].trim();

            return [externalNumber, deviceNumber, username];
        }
        else
        {
            return ['', '', ''];
        }
    }

    const [latitude, longitude, formattedDate] = extractGeolocationDetails(details);
    const alertsGeolocationData = {
        latitude,
        longitude,
        receivedDate: formattedDate,
        id,
        device: deviceId,
        username: '',
        captureStartDate: '',
        captureEndDate: '',
        capturedDate: '',
        flag,
        platform: Platform.Android,
    };

    const [externalNumber, deviceNumber, username] = extractSMSDetails(details);
    const alertSMSData = {
        alertWords: [],
        captureEndDate: '',
        captureStartDate: '',
        capturedDate: '',
        contactName: '5129377020',
        date: new Date(formattedDate),
        device: '',
        deviceId,
        deviceNumber,
        externalNumber,
        fileId: '',
        fileUrl: '',
        flag,
        id,
        message: '',
        platform: Platform.Android,
        receivedDate: '2023-02-03T21:13:15.722Z',
        smsType: SMSType.INCOMING,
        username,
    };

    const logonEventData = {
        receivedDate,
        type: details,
    };

    const isImg =
        dataType === 'MMS' ||
        dataType === 'Photos' ||
        dataType === 'Screen Captures' ||
        dataType === 'Screenshots';

    const isCall = dataType === 'Calls';
    const isGeolocation = dataType === 'Geolocation';
    const isSMS = dataType === 'SMS';
    const isEmail = dataType === 'Emails';
    const isLogonEvents = dataType === 'Logon Events';
    const isPrintDocs = dataType === 'Printed Docs';
    const isProgram = dataType === 'Programs';

    const shouldHighlightText = alertWords && details;

    const [isLoading, setIsLoading] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [scale, setScale] = useState(1);
    const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });

    const installedProgramsDetails = useSelector(selectInstalledProgramsDetails);

    const dispatch = useDispatch();

    useEffect(()=>
    {
        if(isProgram)
        {
            dispatch(getAppsCompareDetails(id));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isProgram]);

    // eslint-disable-next-line
    const handleScroll = (event: any): void =>
    {
        zoomImage(setScale, setImagePosition, event);
    };

    useEffect(() =>
    {
        if(isFullScreen)
        {
            window.addEventListener('wheel', handleScroll);
            return () =>
            {
                window.removeEventListener('wheel', handleScroll);
            };
        }
    }, [isFullScreen]);

    useEffect(() =>
    {
        setIsFullScreen(false);
        setScale(1);
    }, []);

    const dragFunction = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void =>
    {
        handleDragStart(scale, imagePosition, setImagePosition, event);
    };

    return (
        <Box>

            {isPrintDocs && (
                <Box>
                    <PrintedDocumentDetail fileUrl={fileUrl} />
                </Box>
            )}

            {isProgram && (
                <Box>
                    <InstalledProgramsDetails
                        {...(installedProgramsDetails as InstalledProgramsData)}
                    />
                </Box>
            )}

            {isLogonEvents && (
                <Box>
                    <LogonEventDetails {...(logonEventData as LogonEventData)} />
                </Box>
            )}

            {isEmail && (
                <Box>
                    <iframe
                        title="my-iframe"
                        width="100%"
                        height="500px"
                        frameBorder={'none'}
                        srcDoc={details}
                        sandbox=""
                    />
                </Box>
            )}

            {isImg && (
                <>
                    <Button
                        style={{ marginTop: '-58px', left: '15%' }}
                        variant="text"
                        startIcon={
                            isLoading ?
                                <CircularProgress size="1rem"/> :
                                <DownloadIcon />
                        }
                        onClick={() =>
                        {
                            if (fileUrl)
                            {
                                downloadImages(
                                    fileUrl,
                                    setIsLoading,
                                    null,
                                    id
                                );
                            }
                        }}
                    >
                        JPEG
                    </Button>
                    {' '}
                    <br />
                    <div>
                        {isFullScreen ? (
                            <div
                                style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: 999,
                                    cursor: 'pointer',
                                }}
                                onMouseDown={dragFunction}
                            >
                                <button
                                    onClick={() =>
                                    {
                                        toggleFullScreen(
                                            isFullScreen,
                                            setIsFullScreen,
                                            setScale,
                                            setImagePosition
                                        );
                                    }}
                                    style={{
                                        position: 'fixed',
                                        top: '5%',
                                        right: '5%',
                                        background: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                        fontSize: 'xx-large',
                                        color: 'white',
                                        zIndex: 999,
                                    }}
                                >
                                    X
                                </button>
                                <img
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                        transform: `scale(${scale}) 
                                        translate(${imagePosition.x}px, ${imagePosition.y}px)`,
                                        transition: 'transform 0.3s',
                                        cursor: imagePosition.x > 0 || imagePosition.y > 0 ?
                                            'grabbing' : 'pointer',
                                    }}
                                    src={fileUrl}
                                    alt={id}
                                />
                            </div>
                        ) : (
                            <div
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                {
                                    toggleFullScreen(
                                        isFullScreen,
                                        setIsFullScreen,
                                        setScale,
                                        setImagePosition
                                    );
                                }}
                                onKeyDown={(e) => e.preventDefault()}
                            >
                                <img
                                    style={{ width: '100%', height: 'auto' }}
                                    src={fileUrl}
                                    alt={id}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}

            {isCall && (
                <Box>
                    <Box style={{ wordBreak: 'break-word' }}>
                        {details ? (
                            <Box>
                                Duration:
                                {' '}
                                {secondsToHHMMSS(Number(details))}
                            </Box>
                        ) : (
                            <Box>
                                {'Duration: 00:00:00'}
                            </Box>
                        )}
                    </Box>
                </Box>
            )}

            {isGeolocation && (
                <Box>
                    <Box style={{ wordBreak: 'break-word' }}>
                        {details}
                    </Box>
                    <Box>
                        <GeolocationDetails {...alertsGeolocationData} />
                    </Box>
                </Box>
            )}

            {isSMS && (
                <Box>

                    <Box style={{ wordBreak: 'break-word' }}>
                        {shouldHighlightText && (
                            <HighlightText
                                textToHighlight={details}
                                highlightWords={alertWords || ['']}
                            />
                        )}
                    </Box>
                    <Box>
                        <TextSMSDetails {...alertSMSData} />
                    </Box>
                </Box>
            )}

            {
                !isImg &&
                !isCall &&
                !isGeolocation &&
                !isSMS &&
                !isEmail && !isLogonEvents && !isPrintDocs && !isProgram && (
                    <Box sx={{ mb: 6 }}>
                        <Box style={{ wordBreak: 'break-word' }}>
                            {shouldHighlightText && (
                                <HighlightText
                                    textToHighlight={details}
                                    highlightWords={alertWords || ['']}
                                />
                            )}
                        </Box>
                        <Box style={{ wordBreak: 'break-word' }} />
                    </Box>
                )
            }
        </Box>

    );
}

