import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'src/components/data-table/DataTable';
import { ExtensionManagement } from 'src/models/administration/extensionData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getExtensions,
    selectExtensiontypes,
    resetExtensionUpdateStatus,
    resetExtensionSaveStatus,
    sendDeleteExtension,
    selectAdminFilters,
    selectDeleteExtensionStatus,
    selectExtensionsStatus,
} from 'src/state/administration/administrationSlice';
import EditIcon from '@mui/icons-material/Edit';
import { Modal } from 'src/components/modal/Modal';
import { Button, Stack, IconButton, Alert, Snackbar, SnackbarProps, Box } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ExtensionEditionModal } from 'src/templates/extension-edit-modal/ExtensionEditionModal';
import { ExtensionAdditionModal } from 'src/templates/extension-add-modal/ExtensionAdditionModal';
import ConfirmationPanel from '../confirmation-panel/ConfirmationPanel';
import DeleteIcon from '@mui/icons-material/Delete';

export function Extensions(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(selectExtensionsStatus);
    const extensiontype = useSelector(selectExtensiontypes); 
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [extensionEditModal, setExtensionEditModal] = useState<boolean>(false);
    const [extensionAddModal, setExtensionAddModal] = useState<boolean>(false);
    const [editData, setEditData] = useState<ExtensionManagement>();
    const [notificationState, setNotificationState] = useState<SnackbarProps>();
    const filters = useSelector(selectAdminFilters);
    const deleteStatus = useSelector(selectDeleteExtensionStatus);
    const [open, setOpen] = React.useState(false);
    function showModal(data: ExtensionManagement):void
    {
        setExtensionEditModal(true);
        setEditData(data);
    }

    function showAddModal():void
    {
        setExtensionAddModal(true);
        setEditData(undefined);
    }

    function closeAddModal(): void
    {
        setExtensionAddModal(false);
    }

    function closeModal(): void
    {
        setExtensionEditModal(false);
    }

    function onComplete(): void
    {
        setTimeout(() => dispatch(
            getExtensions({ pageNumber: 1, entriesPerPage, ...filters })
        ), 1000);
    }
    function onPageChange(pageNumber: number): void
    {
        dispatch(getExtensions({ pageNumber, entriesPerPage, ...filters }));
    }


    useEffect(() =>
    {
        dispatch(getExtensions({ pageNumber: 1, entriesPerPage, ...filters }));
    }, [dispatch, entriesPerPage, filters]);


    function onSendDelete(data: ExtensionManagement): void
    {
        if(data)
        {
            setOpen(true);
            setEditData(data);
        }

    }
    useEffect(()=>
    {
        if(deleteStatus === RequestStatus.Success)
        {
            const subject = t('extension-form.extension');
            setNotificationState({
                open: true,
                message: t('common.messages.Delete-successfully', { subject }),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteStatus]);

    function onClose(): void
    {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }

    const getActionColumn = (data: ExtensionManagement): React.ReactNode => (
        <>
            <IconButton
                aria-label="edit"
                onClick={() =>
                {
                    showModal(data);
                    dispatch(resetExtensionUpdateStatus());
                    dispatch(resetExtensionSaveStatus());
                }}
            >
                <EditIcon />
            </IconButton>
            <IconButton
                aria-label="edit"
                onClick={() =>
                {
                    onSendDelete(data);
                }}
            >
                <DeleteIcon />
            </IconButton>
        </>
    );

    const columns = [
        {
            label: t('extension-form.extension'),
            size: 1,
            value: (data: ExtensionManagement) => data.extension,
        },
        {
            label: t('extension-form.platform'),
            size: 1,
            value: (data: ExtensionManagement) => data.platform,
        },
        {
            label: t('extension-form.action'),
            size: 1,
            format: (data : ExtensionManagement) => getActionColumn(data),
        },
    ];
    function DeleteModal(): void
    {
        setOpen(false);
    }
    
        function DeleteSubmit(): void
        {
            if (editData)
            {
                dispatch(sendDeleteExtension(editData.id as string));
                setOpen(false);
                setTimeout(() => dispatch(getExtensions({ pageNumber: 1, entriesPerPage })), 200);
            }
        }
    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-end"
                padding={'1em 0'}
                marginTop={-8}
            >
                <Button
                    onClick={() =>
                    {
                        showAddModal();
                        dispatch(resetExtensionUpdateStatus());
                        dispatch(resetExtensionSaveStatus());
                    }}
                    startIcon={<AddCircleIcon />}
                >
                    {t('extension-form.create')}
                </Button>
            </Stack>

            <DataTable<ExtensionManagement>
                isLoading={status === RequestStatus.InProgress}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={setEntriesPerPage}
                {...extensiontype}
            />
            {extensionEditModal && (
                <ExtensionEditionModal
                    data={editData}
                    open={extensionEditModal}
                    onClose={closeModal}
                    onActionComplete={onComplete}
                />
            )}

            {extensionAddModal && (
                <ExtensionAdditionModal
                    open={extensionAddModal}
                    onActionComplete={onComplete}
                    onClose={closeAddModal}
                />
            )}
             {open? <Modal
                            title={t('menu.delete-extension')}
                            open={open}
                            maxWidth="md"
                            fullWidth
                            onClose={DeleteModal}
                            showCloseButton={true}
                    >
                    <Box>
                    <ConfirmationPanel
                        data={editData?.extension as string}
                        DeleteModal={DeleteModal}
                        DeleteSubmit={DeleteSubmit}
                        type={'Extension'}
                    />
                </Box>
            </Modal>:''}
            
            <Snackbar
                open={notificationState?.open}
                autoHideDuration={2000}
                onClose={onClose}
            >
                <Alert severity="success">
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}







