import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {
    updateVersion,
    selectUpdateVersionStatus,
    selectVersionUpdateError,
    createVersion,
    resetVersionFormStatus,
    selectCreateVersionStatus,
    getPlatformVersion,
    selectAdminFilters,
} from 'src/state/administration/administrationSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    AnimatedEllipsis,
} from 'src/components/animated-ellipsis/AnimatedEllipsis';
import {
    PlatformVersion,
} from 'src/models/administration/platformVersionData.model';
import {
    Platform, platformStringKey,
} from 'src/models/platforms.enum';
import {
    FilterOption, PlatformOption,
} from 'src/models/common/filterOptionData.model';
import { handleError, handleErrorText } from 'src/utils/errorUtils';
import { ErrorData } from 'src/models/errors/errorData.model';
import {
    getAgencyFilterOptions,
    selectAgencyFilterOptions,
    selectAgencyFilterOptionsStatus,
} from 'src/state/captures/capturesSlice';
import { Checkbox } from '@mui/material';

function FormErrors(errorData: Readonly<ErrorData>): JSX.Element
{
    const { t } = useTranslation();
    let errors: string[] = [];
    if (errorData?.messages)
    {
        errors = errorData?.messages;
    }

    if (errorData?.message && !Array.isArray(errorData?.message))
    {
        errors.push(errorData?.message);
    }

    if (errors.length)
    {
        return (
            <Alert severity="error" variant="filled" sx={{ mb: 5 }}>
                <AlertTitle sx={{ fontSize: 15 }}>
                    {t('form.validation-errors')}
                </AlertTitle>
                <ul>
                    {errors.map((error, key) => (
                        <li key={`row-${key + 1}`}>
                            {error}
                        </li>
                    ))}
                </ul>
            </Alert>
        );
    }
    return <></>;
}

export function BatchUpdateForm(props: Readonly<PlatformVersion>): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const versionUpdateStatus = useSelector(selectUpdateVersionStatus);
    const versionCreateStatus = useSelector(selectCreateVersionStatus);
    const versionError = useSelector(selectVersionUpdateError);

    const [platformData, setPlatformData] = useState<PlatformVersion>(props);
    // eslint-disable-next-line
    const [assigneeData,setAssigneeData]=useState<any>([])
    const agencyFiltersStatus = useSelector(selectAgencyFilterOptionsStatus);
    const agencyFilters = useSelector(selectAgencyFilterOptions);
    const [isAgencyChanged, setIsAgencyChanged] = useState<boolean>(false);

    const filters = useSelector(selectAdminFilters);
    const onChangeAgency = (selectedAgency: FilterOption[]): void =>
    {
        setIsAgencyChanged(true);
        setPlatformData((prevData) => ({
            ...prevData,
            agencyIds: selectedAgency.map((item)=>item.value),
        }));
        setAssigneeData(selectedAgency.map((item)=>item.value));
    };

    const platforms = Object.values(Platform).map((value) =>
    {
        return getRoleOption(value);
    });

    function getRoleOption(platform: Platform): PlatformOption
    {
        return {
            value: platform,
            name: t(platformStringKey(platform)),
        };
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>): void
    {
        event.preventDefault();
        if (platformData)
        {
            if (!platformData.id)
            {
                dispatch(createVersion(platformData));
            }
            else
            {
                dispatch(updateVersion(platformData));
            }
        }
        if (platformData.downloadUrl && platformData.platform && platformData.version)
        {
            props.closeModal(false);
            props.onComplete(true);
        }
    }

    function isLoading(): boolean
    {
        return RequestStatus.InProgress === versionUpdateStatus ||
            RequestStatus.InProgress === versionCreateStatus;
    }

    function onChangePlatform(data: FilterOption | null): void
    {
        if (data)
        {
            setPlatformData((prevData) => ({
                ...prevData,
                platform: data.value,
            }));
        }
    }
    useEffect(() =>
    {
        dispatch(getPlatformVersion({ pageNumber: 1, entriesPerPage: 10, ...filters }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() =>
    {
        dispatch(getAgencyFilterOptions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>
    {
        return setAssigneeData(props?.agencyIds?.map((item) => item.agency));
    }, [props?.agencyIds]);

    return (
        <>
            <FormErrors {...versionError} />
            <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
            >
                <Grid container spacing={5}>
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <Autocomplete
                            disablePortal
                            id="platform"
                            value={
                                platforms.find(
                                    platform => platformData?.platform === platform.value
                                )
                            }
                            options={platforms ?? []}
                            onChange={(_, data) => onChangePlatform(data)}
                            isOptionEqualToValue={(option, value) =>
                                option.value === value.value}
                            getOptionLabel={(option) => option.name}
                            sx={{ width: '100%' }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('common.nouns.platform')}
                                    error={handleError(versionError, 'platform')}
                                    helperText={handleErrorText(versionError, 'platform')}
                                />
                            )}
                            disabled={!!platformData.id}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <TextField
                            disabled
                            fullWidth
                            id="version"
                            value={platformData?.version}
                            onChange={(event) =>
                                setPlatformData({
                                    ...platformData,
                                    version: event.target.value,
                                })}
                            label={t('data-type.table.version')}
                            name="version"
                            autoFocus
                            error={handleError(versionError, 'version')}
                            helperText={handleErrorText(versionError, 'version')}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <Autocomplete
                            id="agency"
                            value={agencyFilters?.filter(
                                awl => assigneeData?.includes(awl.value)
                            ) ?? []}
                            filterSelectedOptions
                            onChange={(_, data) => onChangeAgency(data)}
                            options={agencyFilters ?? []}
                            loading={agencyFiltersStatus === RequestStatus.InProgress}
                            getOptionLabel={(option) =>
                            {
                                const matchingAgency =
                                    platformData?.agencyIds?.find(
                                        agency => agency.agency === option.value
                                    );
                                const PlatformActiveCount =
                                    matchingAgency?.platforms?.find(
                                        (platform:{platform:string}) =>
                                            platform.platform === platformData.platform
                                    )?.totalDevices || 0;

                                return option.name + (matchingAgency
                                    ? ` (${PlatformActiveCount})`
                                    : '');
                            }}
                            fullWidth
                            multiple
                            style={{ maxHeight: '200px', overflowY: 'auto' }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('form.select-agency')}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.value}>
                                    {option.name}
                                </li>
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        marginRight: 'auto',
                        mt: 3,
                    }}
                >
                    <Checkbox
                        checked={platformData.downgrade}
                        disabled={!!platformData.id}
                    />
                    {t('common.actions.down-version')}
                </Grid>
                <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button
                        sx={{
                            mr: 1,
                            backgroundColor: 'transparent',
                            color: theme => theme.palette.common.goldYellow,
                            '&.Mui-disabled': {
                                backgroundColor: 'inherit',
                                color: theme => theme.palette.common.goldYellow,
                            },
                        }}
                        onClick={() =>
                        {
                            props.closeModal(false);
                            dispatch(resetVersionFormStatus());
                        }}
                    >
                        {t('form.cancel')}
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                            mr: 1,
                            minWidth: '7.5rem',
                        }}
                        disabled={isLoading() || !isAgencyChanged}
                    >
                        {t('form.save-changes')}
                        {isLoading() && <AnimatedEllipsis />}
                    </Button>
                </Box>
            </Box>
        </>
    );
}
