import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'src/components/modal/Modal';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    createAgency,
    selectAgencySaveStatus,
    resetAgencySaveStatus,
} from 'src/state/administration/administrationSlice';
import { noop } from 'src/utils/common';
import { AgencyForm, AgencyFormValues, DEFAULT_VALUES } from '../agency-form/AgencyForm';

export interface AgencyCreationFormModalProps {
    open?: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
    onFailure?: () => void;
}

export function AgencyCreationFormModal({
    open = false,
    onClose = noop,
    onSuccess = noop,
    onFailure = noop,
}: Readonly<AgencyCreationFormModalProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(selectAgencySaveStatus);
    const [initialValues, setInitialValues] = useState<AgencyFormValues>(DEFAULT_VALUES);

    function onSubmit(agency: AgencyFormValues, logo?: File): void
    {
        if(agency && logo)
        {
            dispatch(createAgency({ agency, logo }));
        }
    }

    function onCancel(): void
    {
        onClose();
    }

    useEffect(() =>
    {
        if (status === RequestStatus.Success)
        {
            setInitialValues({ ...DEFAULT_VALUES });
            onSuccess();
            dispatch(resetAgencySaveStatus());
        }
        else if (status === RequestStatus.Failure)
        {
            onFailure();
            dispatch(resetAgencySaveStatus());
        }
    }, [status, setInitialValues, onSuccess, onFailure, dispatch]);

    useEffect(() =>
    {
        return () =>
        {
            dispatch(resetAgencySaveStatus());
        };
    }, [dispatch]);

    return (
        <Modal
            title={t('form.add-agency')}

            open={open}
            onClose={onCancel}
            showCloseButton={true}
            maxWidth="md"
            fullWidth
        >
            <Box>
                <AgencyForm
                    buttonLabel="create"
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    loading={status === RequestStatus.InProgress}
                />
            </Box>
        </Modal>
    );
}
