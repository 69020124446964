import { APICaptureData, readAPICaptureData } from './captures';
import { PrintedDocumentData }
    from 'src/models/captures/printedDocumentData.model';


export interface APIPrintedDocumentsResponse extends APICaptureData {
    timeSubmitted: Date;
    deviceName: string;
    printerName: string;
    fileId: string;
    fileUrl: string;
    alertWords: string[];
}

export function readAPIPrintedDocumentsResponse(
    data: APIPrintedDocumentsResponse
): PrintedDocumentData
{
    const {
        timeSubmitted,
        printerName,
        fileId,
        fileUrl,
        alertWords,
        ...captureData
    } = data;

    return {
        ...readAPICaptureData(captureData),
        timeSubmitted,
        printerName,
        fileId,
        fileUrl,
        alertWords,
    };
}
