import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/modal/Modal';
import { noop } from 'src/utils/common';
import { useSelector, useDispatch } from 'react-redux';
import {
    getUserlogDetails,
    selectUserLogDetails,
    selectuserlogStatus,
} from 'src/state/administration/administrationSlice';
import { makeStyles } from '@mui/styles';
import { UserLogResponse } from 'src/models/administration/userSessionData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import 'src/styles/timeline.css';
import { Actions } from 'src/models/roles.enum';
export interface UserLogActivityModalProps {
    // eslint-disable-next-line
    data?: any;
    // eslint-disable-next-line
    versionData?: any,
    // eslint-disable-next-line
    editData?: any;
    versionPlatform?: string,
    agencyType?: string;
    open?: boolean;
    onClose?: () => void;
    onActionComplete?: () => void;
    // eslint-disable-next-line
    nonComplianceAgencyIds?: any;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    scrollableContent: {
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto',
    },
}));

export function UserLogActivityModal({
    data,
    open = true,
    editData,
    onClose = noop,
}: Readonly<UserLogActivityModalProps>): React.ReactElement
{
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userlogDetailsData = useSelector(selectUserLogDetails);
    const userLogStatus = useSelector(selectuserlogStatus);
    const [userNavigationDetails, setUserNavigationDetails] = useState<UserLogResponse[]>([]);
    const [page, setPage] = useState(1);
    const [atBottom, setAtBottom] = useState(false);
    const scrollableRef = useRef<HTMLDivElement>(null);
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const handleScroll = () =>
    {
        const element = scrollableRef.current;
        if (element)
        {
            const { scrollTop, clientHeight, scrollHeight } = element;
            // Check if the scrollbar is at the bottom
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 1;
            setAtBottom(isAtBottom);
        }
    };

    useEffect(() =>
    {
        if (atBottom)
        {
            // Call your function or perform any action when scrolled to the bottom
            dispatch(
                getUserlogDetails({
                    pageNumber: page + 1,
                    entriesPerPage: 10,
                    userId: data.userId,
                    fromDate: data.loggedInAt,
                    toDate: data.trackedAt,
                })
            );
            setPage(page + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [atBottom]);
    useEffect(() =>
    {
        if (userLogStatus === RequestStatus.Success)
        {
            if (userlogDetailsData?.contents &&
                userlogDetailsData.contents.length > 0)
            {
                setUserNavigationDetails(
                    [...userNavigationDetails,
                        ...userlogDetailsData.contents]
                );
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userlogDetailsData]);
    return (
        <Modal
            title={t('data-type.details.userlog-activity')}
            open={open}
            showCloseButton={true}
            maxWidth="md"
            fullWidth
            onClose={onClose}
        >
            <div
                className={classes.scrollableContent}
                ref={scrollableRef}
                onScroll={handleScroll}
            >
                <div className="timeline">
                    {userNavigationDetails
                    && userNavigationDetails.length > 0
                    && userNavigationDetails.map((item, index) =>
                    {
                        const oddIndex = index % 2 === 0;

                        let content = null;

                        if (item.action_type === 'visit')
                        {
                            content = (
                                <p style={{ wordBreak: 'break-word' }}>
                                    {t('data-type.details.navigate-url')}
                                    {' '}
                                    {item.action}
                                </p>
                            );
                        }
                        else if ([Actions.NON_AI_AW_DELETE_ON,
                            Actions.NON_AI_AW_DELETE_OFF].includes(item.action_type as Actions))
                        {
                            const actionStatus =
                            item.action_type as Actions === Actions.NON_AI_AW_DELETE_ON
                                ? 'Activated' : 'Deactivated';
                            content = (
                                <p>
                                    Updated
                                    {' '}
                                    {item.info?.trackingType ?? ''}
                                    {' '}
                                    info for
                                    {' '}
                                    <strong>
                                        {item.info?.deletedObjectName ?? ''}
                                    </strong>
                                    {' '}
                                    and
                                    {' '}
                                    <strong>
                                        {actionStatus}
                                    </strong>
                                    {' '}
                                    deletion of NON-AI/AW records.
                                </p>
                            );
                        }
                        else
                        {
                            content = (
                                <p>
                                    {t('data-type.details.delete-track')}
                                    {' '}
                                    {item.info?.trackingType ?? ''}
                                    {' '}
                                    <strong>
                                        {item.info?.deletedObjectName ?? ''}
                                    </strong>
                                </p>
                            );
                        }

                        return (
                            <div
                                className={oddIndex ? 'container right' : 'container left'}
                                key={item.id}
                            >
                                <div className="date">
                                    {new Date(item.createdAt).toLocaleString()}
                                </div>
                                <div className="content">
                                    <h3 style={{ marginTop: '-5px' }}>
                                        {data.userName}
                                    </h3>
                                    {content}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Modal>
    );

}
