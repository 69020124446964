import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EmailData } from 'src/models/captures/emailData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    changeEmail,
    getEmails,
    selectFilters,
    selectEmail,
    selectEmails,
    selectEmailsStatus,
    getEmailAttachments,
    updateSeenListStatus,
    selectallPageData,
    changeFilters,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import attachedIcon from 'src/assets/images/attached.png';
import AttachmentIcon from '@mui/icons-material/Attachment';
import IconButton from '@mui/material/IconButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { EmailDetails } from './EmailDetails';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { truncate } from 'src/utils/stringUtils';
import EmailAttachments from './EmailAttachments';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { CommentType } from 'src/models/commentType.enum';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { USER_TIME_ZONE } from 'src/utils/environment';
import { timeZoneFormat } from 'src/utils/dateUtils';
enum CurrentView {
    Table,
    Details,
    Attachments,
}

export function EmailAlertsTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();
    const filters = useSelector(selectFilters);
    const emailsStatus = useSelector(selectEmailsStatus);
    const emailsData = useSelector(selectEmails);
    const emailData = useSelector(selectEmail);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [open, setOpen] = React.useState(CurrentView.Table);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentFilter, setCurrentFilter] = useState({});
    const allPageData = useSelector(selectallPageData);
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const prevPage = useRef(1);
    const [lastPage, setLastPage] = useState(0);
    const [datas, setDatas] = useState<string[] | undefined>(undefined);

    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };
    const handleOpen = (data: EmailData): void =>
    {
        openRecordData(data.id);
        dispatch(changeEmail(data));
        if (data.attachments?.length)
        {
            dispatch(getEmailAttachments(data.attachments));
        }
        if (!data.isSeen)
        {
            dispatch(updateSeenListStatus({
                detailsId: data.id,
                dataType: DataType.EMAILS,
            }));
        }
        setOpen(CurrentView.Details);
    };
    function onPageChange(pageNumber: number): void
    {
        if (emailsData && pageNumber !== lastPage &&
            (pageNumber === prevPage.current + 1 ||
                pageNumber === prevPage.current - 1))
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(emailsData?.contents[
                    emailsData.contents.length - 1]?.id);
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(Array.isArray(lastRecordDatas) &&
                    lastRecordDatas.length > 0 ?
                    lastRecordDatas[lastRecordDatas.length - 1]?.id : '');
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        prevPage.current = pageNumber;
    }
    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }
    useEffect(() =>
    {
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if (filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const rowsPerPage = filters?.rowsPerPage !== undefined ?
            filters.rowsPerPage :
            entriesPerPage;

        dispatch(getEmails({
            skipRecordId,
            pageNumber: checkFilterChange(currentFilter, filters) ?
                currentPage : 1,
            entriesPerPage: rowsPerPage,
            ...filters,
        }));
        setCurrentFilter(filters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    useEffect(()=>
    {
        if(filters)
        {
            setCurrentPage(1);
        }
    }, [filters]);

    const detailItems = [
        {
            label: t('data-type.table.received-date'),
            text: (data: EmailData) =>
                timeZoneFormat(new Date(data.receivedDate), USER_TIME_ZONE),
        },
        {
            label: t('data-type.table.captured-date'),
            text: (data: EmailData) =>
                timeZoneFormat(new Date(data.capturedDate), USER_TIME_ZONE),
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: EmailData) => data.device,
        },
        {
            label: t('data-type.table.user-name'),
            text: (data: EmailData) => data.username,
        },
        {
            label: t('data-type.table.from'),
            text: (data: EmailData) => data.sender,
        },
        {
            label: t('data-type.table.to'),
            text: (data: EmailData) => data.recipients.join(', '),
        },
        {
            label: t('data-type.table.subject'),
            text: (data: EmailData) => data.subject,
        },
        {
            label: t('data-type.table.attached'),
            text: (data: EmailData) =>
                (data.attachments.length > 0 ? 'Yes' : 'No'),
        },
        {
            label: t('data-type.table.alert-word'),
            text: (data: EmailData) => data.alertWords?.join(', '),
        },
    ];

    const getFlagColumn = (data: EmailData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.EMAILS}
            onChange={() =>
            {
                onPageChange(emailsData?.currentPage ?? 1);
            }}
        />
    );
    const getAttachmentColumn = (data: EmailData): React.ReactNode =>
    {
        if (data.attachments?.length)
        {
            return (
                <img
                    style={{ height: 14, width: 13.2 }}
                    src={attachedIcon}
                    alt="Atached icon"
                />
            );
        }
        else if (data.fileSizeExceed?.toString() === 'true')
        {
            return 'Attachment limit Exceeded';
        }
    };
    const openDetailColumn = (data: EmailData): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: EmailData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: EmailData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: EmailData) =>
                new Date(data.capturedDate).toLocaleString(),
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value:
                (data: EmailData) =>
                    new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: EmailData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.from'),
            size: 2,
            value: (data: EmailData) => data.sender,
            sortByType: 'sender',
        },
        {
            label: t('data-type.table.to'),
            size: 2,
            value: (data: EmailData) => data.recipients?.join(', '),
            sortByType: 'recipients',
        },
        {
            label: t('data-type.table.subject'),
            size: 2,
            value: (data: EmailData) => truncate(data.subject, 50),
            sortByType: 'subject',
        },
        {
            label: t('data-type.table.attached'),
            size: 1,
            align: 'center' as const,
            sortByType: 'attachments',
            format: (data: EmailData) => getAttachmentColumn(data),
        },
        {
            label: t('data-type.table.alert-word'),
            size: 2,
            value: (data: EmailData) => data.alertWords?.join(', '),
            sortByType: 'alertWords',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: EmailData) => openDetailColumn(data),
        },
    ];

    useEffect(() =>
    {
        if (emailsData)
        {
            setLastPage(
                Math.floor(emailsData.numberOfEntries / entriesPerPage)
            );
            pdfExportSetter({
                type: DataType.EMAILS,
                recordIds: emailsData.contents.map((item) => item.id),
                sortBy: filters.sortBy,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(emailsData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.EMAILS)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailsData, pdfExportSetter]);

    return (
        <>
            <DataDetailsModal<EmailData>
                title={t('data-type.details.email-title')}
                open={open === CurrentView.Details}
                data={emailData}
                detailItems={detailItems}
                onClose={() => setOpen(CurrentView.Table)}
                buttons={
                    emailData?.attachments.length
                        ? [
                            {
                                text: t('common.actions.open-attachments'),
                                onClick:
                                    () => setOpen(CurrentView.Attachments),
                                icon: <AttachmentIcon />,
                            },
                        ]
                        : []
                }
                comments={{
                    contentId: emailData?.id,
                    type: CommentType.EMAILS,
                }}
            >
                <EmailDetails {...(emailData as EmailData)} />
            </DataDetailsModal>
            <EmailAttachments
                open={open === CurrentView.Attachments}
                onClose={() => setOpen(CurrentView.Details)}
            />
            {useDesktopScreen() ? (
                <DataTable<EmailData>
                    isLoading={emailsStatus === RequestStatus.InProgress}
                    columns={columns}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    datas={datas}
                    {...emailsData}
                />
            ) : (
                <DataTableMobile
                    isLoading={emailsStatus === RequestStatus.InProgress}
                    contents={emailsData?.contents || []}
                    setOpenModal={handleOpen}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    numberOfEntries={emailsData?.numberOfEntries}
                    currentPage={emailsData?.currentPage}
                    enablePagination
                />
            )}
        </>
    );
}
