import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/modal/Modal';
import { noop } from 'src/utils/common';
import {
    ExtensionAddForm,
} from 'src/templates/extension-add-form/ExtensionAddForm';
export interface ExtensionManagementModalProps {
    open?: boolean;
    onClose?: () => void;
    onActionComplete?: () => void;
}

export function ExtensionAdditionModal({
    open = true,
    onClose = noop,
    onActionComplete = noop,
}: Readonly<ExtensionManagementModalProps>): React.ReactElement
{
    const { t } = useTranslation();
    function closeAddModal():void
    {
        setTimeout(() => onClose(), 500);
    }

    function onActionFinished():void
    {
        onActionComplete();
    }
    return (
        <Modal
            title={t('extension-form.new-extension')}
            open={open}
            onClose={closeAddModal}
            showCloseButton={true}
            maxWidth="md"
            fullWidth
        >
            <Box>
                <ExtensionAddForm
                    closeAddModal={closeAddModal}
                    onComplete={onActionFinished}
                />
            </Box>
        </Modal>
    );
}
