import { Button, CircularProgress } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
    TableExportFormat,
} from 'src/components/table-exporter/TableExporterProvider';
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector } from 'react-redux';
import { processAndGenerateAllPdf } from 'src/utils/common';
import { useTranslation } from 'react-i18next';
import {
    selectFilters,
    selectTableDatatype,
    selectTableTotalCount,
    selectExportAllRecords,
    selectcolumnValue,
    selectscreenshotGridView,
    selectAlertsFilter,
    selectGeolocationFilter,
} from 'src/state/captures/capturesSlice';
import { ColumnSelectorAll } from '../column-selector/ColumnSelectorAll';
import { ALERTSPAGE } from 'src/models/alertsType.enum';
import { selectAdminSettingsMenu } from 'src/state/administration/administrationSlice';
import { AdminSettings } from 'src/models/adminSettings.enum';
import { selectDeviceDetailsFilters, selectDeviceStatus } from 'src/state/administrationMeta/administrationMetaSlice';
interface ALLtoExportProps {
    selectedValue: () => Promise<void>;
    formatAll: string | undefined;
    setFormatAll: Dispatch<SetStateAction<string | undefined>>;
    xlsxExportClick: () => Promise<void>;
    allToPdfEnabled: boolean;
    isGeolocation?:boolean;
    isDeviceInformationPageData?:boolean;
}

export function ALLtoExport({
    selectedValue,
    formatAll,
    setFormatAll,
    xlsxExportClick,
    allToPdfEnabled = true,
    isGeolocation,
    isDeviceInformationPageData

}: Readonly<ALLtoExportProps>): React.ReactElement
{
    const { t } = useTranslation();
    const exportAllData = useSelector(selectExportAllRecords);
    const screenshotGridView = useSelector(selectscreenshotGridView);
    const filters = useSelector(selectFilters);
    const alertsFilter = useSelector(selectAlertsFilter);
    const geolocationFilters = useSelector(selectGeolocationFilter);
    const dataType = useSelector(selectTableDatatype);
    const totalRecordCount = useSelector(selectTableTotalCount);
    const [allToXlsx, setAllToXlsx] = useState(false);
    const columnsData = useSelector(selectcolumnValue);
    const [downloadPercentage, setDownloadPercentage] = useState(0);
    const isAlertsPage = window.location.pathname === ALERTSPAGE;
    const deviceDetailsfilters = useSelector(selectDeviceDetailsFilters);
    const deviceStatusFilter = useSelector(selectDeviceStatus);
    const adminSettingMenu = useSelector(selectAdminSettingsMenu);
    const isDeviceInformationPage = adminSettingMenu === AdminSettings.DeviceInformation;
    let filtersValue;
    if (isGeolocation)
    {
        filtersValue = geolocationFilters;
    }
    else if (isAlertsPage)
    {
        filtersValue = alertsFilter;
    }
    else if (isDeviceInformationPage)
        {
            const updatedData = {
                ...deviceDetailsfilters,
                agencyId:deviceDetailsfilters.agency,
                clientId:deviceDetailsfilters.client,
                deviceId:deviceDetailsfilters.device,
                userId:deviceDetailsfilters.user,
                deviceStatus:deviceStatusFilter
            }
            filtersValue = updatedData;
        }
    else
    {
        filtersValue = filters;
    }
    const handlePDFExport = async (): Promise<void> =>
    {
        let filterData;
        if (isGeolocation)
        {
            filterData = geolocationFilters;
        }
        else if (isAlertsPage)
        {
            filterData = alertsFilter;
        }
        else if (isDeviceInformationPageData)
            {
                const updatedData = {
                    ...deviceDetailsfilters,
                    agencyId:deviceDetailsfilters.agency,
                    clientId:deviceDetailsfilters.client,
                    deviceId:deviceDetailsfilters.device,
                    userId:deviceDetailsfilters.user,
                    platform: deviceDetailsfilters.platform,
                    
                    deviceStatus:deviceStatusFilter
                }
                filterData = updatedData;
            }
        else
        {
            filterData = filters;
        }

        await selectedValue();
        const filterValue = filterData;
        processAndGenerateAllPdf(
            totalRecordCount,
            dataType,
            filterValue,
            setDownloadPercentage,
            null,
            setFormatAll,
            isGeolocation
        );
    };
    const handleXLSXExport = async (): Promise<void> =>
    {
        setAllToXlsx(true);
        await xlsxExportClick();

    };

    return (
        <>
            {allToPdfEnabled && (filters.isGridView === false || screenshotGridView=== false ) ? (
                <Button
                    variant="text"
                    startIcon={
                        formatAll === TableExportFormat.PDF
                            ? <CircularProgress size="1rem" />
                            : <DownloadIcon />
                    }
                    disabled={formatAll === TableExportFormat.PDF}

                    onClick={handlePDFExport}
                >
                    {t('common.button.all-to-pdf')}
                    {' '}
                    {formatAll === TableExportFormat.PDF &&
                        Math.round(downloadPercentage) + '%'}
                </Button>
            ) : ''
            }
            {' '}
            <Button
                variant="text"
                startIcon={
                    formatAll === TableExportFormat.XLSX
                        ? <CircularProgress size="1rem" />
                        : <DownloadIcon />
                }
                disabled={formatAll === TableExportFormat.XLSX}
                onClick={() =>
                {
                    handleXLSXExport();
                }}
            >
                {t('common.button.all-to-xlsx')}
            </Button>
            {allToXlsx &&
                exportAllData &&
                exportAllData?.contents.length > 0 && (
                <ColumnSelectorAll
                    format={TableExportFormat.ALLXLSX}
                    columns={columnsData}
                    data={{
                        contents: exportAllData?.contents,
                        currentPage: 1,
                        numberOfEntries: 0,
                    }}
                    onClose={() => setFormatAll(undefined)}
                    filters={filtersValue}
                    setAllToXlsx={setAllToXlsx}
                    totalRecordCount={totalRecordCount}
                    dataType={dataType}
                    isGeolocation={isGeolocation}
                />
            )}
        </>
    );
}
