import { DataType } from 'src/models/dataType.enum';
import { APICaptureData, readAPICaptureData } from './captures';
import { TextSMSData, SMSType } from 'src/models/captures/textSMSData.model';


export interface APITextSMSResponse extends APICaptureData {
    deviceNumber: string
    externalNumber: string
    contactName: string
    message: string
    smsType: SMSType
    date:Date
    fileId: string
    fileUrl: string
    alertWords: string[]
    aiScanStatus?: number
    datatype: DataType
}

export function readAPITextSMSResponse(data: APITextSMSResponse): TextSMSData
{
    const {
        deviceNumber,
        externalNumber,
        contactName,
        message,
        smsType,
        date,
        alertWords,
        fileId,
        fileUrl,
        aiScanStatus,
        datatype,
        ...captureData
    } = data;

    return {
        ...readAPICaptureData(captureData),
        deviceNumber,
        externalNumber,
        contactName,
        message,
        smsType,
        date,
        alertWords,
        fileId,
        fileUrl,
        aiScanStatus,
        datatype,
    };
}
