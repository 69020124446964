import {
    Box,
    Button,
    CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnData, DataTable } from 'src/components/data-table/DataTable';
import { CustomAlertWordsList } from 'src/models/administration/alertWordListData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import EditIcon from '@mui/icons-material/Edit';
import {
    getCustomAWLs,
    selectCustomAWLRetrieveStatus,
    selectCustomAWLs,
} from 'src/state/administration/administrationSlice';
import {
    useConfiguration,
    useConfigurationRetrieveStatus,
} from '../awl-index-tabs/AgencyAWLConfigurationContext';
import { noop } from 'src/utils/common';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';


export interface CustomAWLTableProps {
    agencyId?: string;
    editable?: boolean;
    blocked?: boolean;
    selectIsModalEdit?: (setid: string) => void;
}

export function CustomAWLTable({
    agencyId,
    editable = false,
    blocked = false,
    selectIsModalEdit = noop,
}: Readonly<CustomAWLTableProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const items = useSelector(selectCustomAWLs);
    const status = useSelector(selectCustomAWLRetrieveStatus);
    const [entriesPerPage, setEntriesPerPage] = useState(10);

    // Agency AWL Configuration
    const configuration = useConfiguration();
    const configurationRetrieveStatus = useConfigurationRetrieveStatus();

    function onPageChange(pageNumber: number, entriesPerPage: number): void
    {
        dispatch(getCustomAWLs({ pageNumber, entriesPerPage, agencyId }));
    }

    function onChangeEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        dispatch(getCustomAWLs({ pageNumber: 1, entriesPerPage, agencyId }));
    }

    const getEnabledColumn = (isEnabled:boolean): React.ReactNode => (
        <ToggleSwitch
            checked={isEnabled}
            disabled
        />
    );
    const getEditColumn = (id:string): React.ReactNode => (
        <Button
            startIcon={<EditIcon />}
            onClick={() => selectIsModalEdit(id)}

        />
    );

    const columns: ColumnData<CustomAlertWordsList>[] = [
        {
            label: t('common.nouns.name'),
            size: 20,
            value: ({ name }: CustomAlertWordsList) => name,
        },
        {
            label: t('common.nouns.profile'),
            size: 20,
            value: ({ profileName }: CustomAlertWordsList) => profileName,
        },
        {
            label: t('common.nouns.enabled'),
            size: 10,
            format: ({ isEnabled }) => getEnabledColumn(isEnabled),
        },
    ];

    if (editable)
    {
        columns.push({
            label: t('common.actions.edit'),
            size: 3,
            format: ({ id }) => getEditColumn(id),
        });
    }

    useEffect(() =>
    {
        dispatch(getCustomAWLs({ agencyId, pageNumber: 1, entriesPerPage: 10 }));
    }, [dispatch, agencyId]);

    function renderBody(): React.ReactElement
    {
        if ([configurationRetrieveStatus, status].includes(RequestStatus.InProgress))
        {
            return <CircularProgress />;
        }
        else if (
            [configurationRetrieveStatus, status].includes(RequestStatus.Failure)
            || !configuration
        )
        {
            return (
                <div>
                    {t('common.messages.something-wrong')}
                </div>
            );
        }
        else
        {
            return (
                <DataTable<CustomAlertWordsList>
                    isLoading={status === RequestStatus.InProgress}
                    columns={columns}
                    onPageChange={onPageChange}
                    entriesPerPage={entriesPerPage}
                    onChangeEntriesPerPage={onChangeEntriesPerPage}
                    { ...items }
                />
            );
        }
    }

    return (
        <Box
            display="flex"
            alignItems={
                [configurationRetrieveStatus, status].includes(RequestStatus.InProgress)
                    ? 'center' : ''
            }
            flexDirection="column"
        >
            {renderBody()}
        </Box>
    );
}
