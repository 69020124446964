import {
    createAsyncThunk,
    createSelector,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../store';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getDevicesCacheDetails as getDevicesCacheDetailsRequest,
    deleteDeviceCacheData as deleteDeviceCacheDataRequest,
} from 'src/services/administration/deviceCacheDetails';
import {
    getDevicesDetails as getDevicesDetailsRequest,
    getDevicesCountDetails as getDevicesCountDetailsRequest,
} from 'src/services/administration/deviceDetails';
import { CaptureFilters } from 'src/models/captures/capturedData.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    DeviceDetails,
    DeviceDetailsPaginatedParams,
    DeviceDetailsParams,
    DevicesCountDetails,
} from 'src/models/administration/deviceDetailsData.model';
import {
    DeviceLogParams,
    DeviceLogs,
    DevicePermissions,
    DevicePermissionsParams,
} from 'src/models/administration/deviceData.model';
import {
    getLogs as getLogsRequest,
    getPermissions,
    getPermissionsIds,
} from 'src/services/administration/devices';
import {
    deleteCustomAWL as deleteCustomAWLRequest,
} from 'src/services/administration/alertWordList';
import { AWLDeleteParams } from 'src/models/administration/alertWordListData.model';
import {
    DeleteDeviceCacheParams, DeviceCacheDetails, DeviceCacheDetailsPaginatedParams,
} from 'src/models/administration/deviceCacheDetailsData.model';
import { DeviceStatus } from 'src/models/deviceSelectionModels';
interface AdministrationMetaState {
    deviceDetailsFilters: CaptureFilters;
    deviceStatus:DeviceStatus[];
    deviceDetails?: PaginatedContent<DeviceDetails>;
    deviceDetailsStatus: RequestStatus;
    deviceCountDetails?: DevicesCountDetails[];
    deviceCountDetailsStatus: RequestStatus;
    deviceCacheDetails?: PaginatedContent<DeviceCacheDetails>;
    deviceCacheDetailsStatus: RequestStatus;
    deleteDeviceCacheStatus?: RequestStatus;
    deviceLogs?: PaginatedContent<DeviceLogs>;
    deviceLogsStatus: RequestStatus;
    devicePermissions?: PaginatedContent<DevicePermissions>
    devicePermissionsIds?: string[]
    devicePermissionStatus?: RequestStatus,
    devicePermissionsIdsStatus?: RequestStatus,
    deleteCustomAWLStatus?: RequestStatus;
    deviceCacheSearchValue?: string;
}

const initialState: AdministrationMetaState = {
    deviceDetailsFilters: {
        sortBy: '{"status": 1}',
    },
    deviceStatus :Object.values(DeviceStatus).map((flag) => flag) ,
    deviceDetailsStatus: RequestStatus.NotStarted,
    deviceCountDetailsStatus: RequestStatus.NotStarted,
    deviceLogsStatus: RequestStatus.NotStarted,
    deviceCacheDetailsStatus: RequestStatus.NotStarted,
    deleteDeviceCacheStatus: RequestStatus.NotStarted,
    deleteCustomAWLStatus: RequestStatus.NotStarted,
};

export const getDevicesDetails = createAsyncThunk(
    'administration/devices',
    async (params: DeviceDetailsPaginatedParams) => getDevicesDetailsRequest(params)
);

export const getDevicesCountDetails = createAsyncThunk(
    'administration/devicesCount',
    async (params: DeviceDetailsParams) => getDevicesCountDetailsRequest(params)
);

export const getDeviceLogs = createAsyncThunk(
    'administration/deviceLogs',
    async (params: DeviceLogParams) => getLogsRequest(params)
);

export const getDevicePermissions = createAsyncThunk(
    'administration/devicePermissions',
    async (params: DevicePermissionsParams) => getPermissions(params)
);
export const getDevicePermissionsIds = createAsyncThunk(
    'administration/devicePermissionsIds',
    async (params: DevicePermissionsParams) => getPermissionsIds(params)
);
export const deleteCustomAWL = createAsyncThunk(
    'administration/deleteCustomAWL',
    async (params: AWLDeleteParams) => deleteCustomAWLRequest(params)
);

export const getDevicesCacheDetails = createAsyncThunk(
    'administration/getDevicesCacheDetails',
    async (params: DeviceCacheDetailsPaginatedParams) => getDevicesCacheDetailsRequest(params)
);

export const deleteDeviceCacheData = createAsyncThunk(
    'administration/deleteDeviceCacheData',
    async (params: DeleteDeviceCacheParams) => deleteDeviceCacheDataRequest(params)
);

export const administrationMetaSlice = createSlice({
    name: 'administrationMeta',
    initialState,
    reducers: {
        changeDeviceDetailsFilters: (state, action: PayloadAction<CaptureFilters>) =>
        {
            state.deviceDetailsFilters = { ...state.deviceDetailsFilters, ...action.payload };
        },
        changeAgencyDeviceFilter: (state, action: PayloadAction<string>) =>
        {
            state.deviceDetailsFilters = {
                ...state.deviceDetailsFilters,
                agency: action.payload,
                officer: undefined,
                client: undefined,
                device: undefined,
                user: undefined,
                platform: undefined,
                version: undefined,
            };
        },
        changeOfficerDeviceFilter: (state, action: PayloadAction<string>) =>
        {
            state.deviceDetailsFilters = {
                ...state.deviceDetailsFilters,
                officer: action.payload,
                client: undefined,
                device: undefined,
                platform: undefined,
                version: undefined,
            };
        },
        changeClientDeviceFilter: (state, action: PayloadAction<string>) =>
        {
            state.deviceDetailsFilters = {
                ...state.deviceDetailsFilters,
                client: action.payload,
                device: undefined,
                platform: undefined,
                version: undefined,
            };
        },
        changeDeviceFilter: (state, action: PayloadAction<string>) =>
        {
            state.deviceDetailsFilters = {
                ...state.deviceDetailsFilters,
                device: action.payload,
                platform: undefined,
                version: undefined,
            };
        },
        changeDeviceStatusFilter: (state, action: PayloadAction<DeviceStatus[]>) =>
            {
                state.deviceStatus =  action.payload
            },
        changeDevicePlatformFilter: (state, action: PayloadAction<string>) =>
        {
            state.deviceDetailsFilters = {
                ...state.deviceDetailsFilters,
                platform: action.payload,
                version: undefined,
            };
        },
        resetDeviceDetailsStatus: (state) =>
        {
            state.deviceDetailsStatus = RequestStatus.NotStarted;
        },
        resetDevicePermissionsStatus: (state) =>
        {
            state.devicePermissionsIds = [];
            state.devicePermissionsIdsStatus = RequestStatus.NotStarted;
        },
        resetDeleteCustomAWLStatus: (state) =>
        {
            state.deleteCustomAWLStatus = RequestStatus.NotStarted;
        },
        clearDeviceInfoSearchValue: (state) =>
        {
            state.deviceDetailsFilters.searchValue = '';
        },
        resetDeleteDeviceCacheStatus: (state) =>
        {
            state.deleteDeviceCacheStatus = RequestStatus.NotStarted;
        },
        setDeviceCacheSearchValue: (state, action: PayloadAction<string>) => 
        {
            state.deviceCacheSearchValue = action.payload;
        },
    },
    extraReducers: (builder) =>
    {
        builder
            .addCase(getDevicesDetails.pending, (state) =>
            {
                state.deviceDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getDevicesDetails.fulfilled, (state, action) =>
            {
                state.deviceDetailsStatus = RequestStatus.Success;
                state.deviceDetails = action.payload;
            })
            .addCase(getDevicesDetails.rejected, (state) =>
            {
                state.deviceDetailsStatus = RequestStatus.Failure;
                state.deviceDetails = undefined;
            })
            .addCase(getDevicesCountDetails.pending, (state) =>
            {
                state.deviceCountDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getDevicesCountDetails.fulfilled, (state, action) =>
            {
                state.deviceCountDetailsStatus = RequestStatus.Success;
                state.deviceCountDetails = action.payload;
            })
            .addCase(getDevicesCountDetails.rejected, (state) =>
            {
                state.deviceCountDetailsStatus = RequestStatus.Failure;
                state.deviceCountDetails = undefined;
            })
            .addCase(getDeviceLogs.pending, (state) =>
            {
                state.deviceLogsStatus = RequestStatus.InProgress;
            })
            .addCase(getDeviceLogs.fulfilled, (state, action) =>
            {
                state.deviceLogsStatus = RequestStatus.Success;
                state.deviceLogs = action.payload;
            })
            .addCase(getDeviceLogs.rejected, (state) =>
            {
                state.deviceLogsStatus = RequestStatus.Failure;
                state.deviceLogs = undefined;
            })
            .addCase(getDevicePermissions.pending, (state) =>
            {
                state.devicePermissionStatus = RequestStatus.InProgress;
            })
            .addCase(getDevicePermissions.fulfilled, (state, action) =>
            {
                state.devicePermissionStatus = RequestStatus.Success;
                state.devicePermissions = action.payload;
            })
            .addCase(getDevicePermissions.rejected, (state, action) =>
            {
                state.devicePermissionStatus = RequestStatus.Failure;
            })
            .addCase(getDevicePermissionsIds.pending, (state) =>
            {
                state.devicePermissionsIdsStatus = RequestStatus.InProgress;
            })
            .addCase(getDevicePermissionsIds.fulfilled, (state, action) =>
            {
                state.devicePermissionsIdsStatus = RequestStatus.Success;
                state.devicePermissionsIds = action.payload;
            })
            .addCase(getDevicePermissionsIds.rejected, (state, action) =>
            {
                state.devicePermissionsIdsStatus = RequestStatus.Failure;
            })
            .addCase(deleteCustomAWL.pending, (state) =>
            {
                state.deleteCustomAWLStatus = RequestStatus.InProgress;
            })
            .addCase(deleteCustomAWL.fulfilled, (state, action) =>
            {
                state.deleteCustomAWLStatus = RequestStatus.Success;
            })
            .addCase(deleteCustomAWL.rejected, (state, action) =>
            {
                state.deleteCustomAWLStatus = RequestStatus.Failure;
            })
            .addCase(getDevicesCacheDetails.pending, (state) =>
            {
                state.deviceCacheDetailsStatus = RequestStatus.InProgress;
            })
            .addCase(getDevicesCacheDetails.fulfilled, (state, action) =>
            {
                state.deviceCacheDetailsStatus = RequestStatus.Success;
                state.deviceCacheDetails = action.payload;
            })
            .addCase(getDevicesCacheDetails.rejected, (state) =>
            {
                state.deviceCacheDetailsStatus = RequestStatus.Failure;
                state.deviceCacheDetails = undefined;
            })
            .addCase(deleteDeviceCacheData.pending, (state) =>
            {
                state.deleteDeviceCacheStatus = RequestStatus.InProgress;
            })
            .addCase(deleteDeviceCacheData.fulfilled, (state, action) =>
            {
                state.deleteDeviceCacheStatus = RequestStatus.Success;
            })
            .addCase(deleteDeviceCacheData.rejected, (state, action) =>
            {
                state.deleteDeviceCacheStatus = RequestStatus.Failure;
            });
    },
});

function selectState(state: RootState): AdministrationMetaState
{
    return state.administrationMeta;
}
export const selectDeviceDetailsFilters = createSelector(
    selectState,
    (state) => state.deviceDetailsFilters
);
export const selectDeviceDetails = createSelector(selectState, (state) => state.deviceDetails);
export const selectDeviceDetailsStatus = createSelector(
    selectState,
    (state) => state.deviceDetailsStatus
);
export const selectDeleteDeviceCacheStatus= createSelector(
    selectState,
    (state) => state.deleteDeviceCacheStatus
);
export const selectDeviceCountDetails = createSelector(
    selectState,
    (state) => state.deviceCountDetails
);export const selectDeviceCountDetailsStatus = createSelector(
    selectState,
    (state) => state.deviceCountDetailsStatus
);
export const selectDeviceLogs = createSelector(
    selectState,
    (state) => state.deviceLogs
);
export const selectDeviceLogsStatus = createSelector(
    selectState,
    (state) => state.deviceLogsStatus
);
export const selectDevicePermissions = createSelector(
    selectState,
    state => state.devicePermissions
);
export const selectDevicePermissionsStatus = createSelector(
    selectState,
    state => state.devicePermissionStatus
);
export const selectDevicePermissionsIds = createSelector(
    selectState,
    state => state.devicePermissionsIds
);
export const selectDevicePermissionsStatusIds = createSelector(
    selectState,
    state => state.devicePermissionsIdsStatus
);
export const selectDeleteCustomAWLStatus = createSelector(
    selectState,
    state => state.deleteCustomAWLStatus
);
export const selectDeviceCacheDetails = createSelector(
    selectState,
    (state) => state.deviceCacheDetails
);
export const selectDeviceCacheDetailsStatus = createSelector(
    selectState,
    (state) => state.deviceCacheDetailsStatus
);
export const selectDeviceCacheSearchValue = createSelector(
    selectState,
    (state) => state.deviceCacheSearchValue
);
export const selectDeviceStatus = createSelector(
    selectState,
    (state) => state.deviceStatus
);


export const {
    resetDeviceDetailsStatus,
    changeDeviceDetailsFilters,
    changeAgencyDeviceFilter,
    changeOfficerDeviceFilter,
    changeDeviceStatusFilter,
    changeClientDeviceFilter,
    changeDeviceFilter,
    changeDevicePlatformFilter,
    resetDevicePermissionsStatus,
    resetDeleteCustomAWLStatus,
    clearDeviceInfoSearchValue,
    resetDeleteDeviceCacheStatus,
    setDeviceCacheSearchValue,
} = administrationMetaSlice.actions;

export default administrationMetaSlice.reducer;
