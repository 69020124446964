import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    InstalledProgram,
    InstalledProgramsData,
} from 'src/models/captures/installedProgramsData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    changeInstalledProgramsDetails,
    getInstalledPrograms,
    selectFilters,
    selectInstalledPrograms,
    selectInstalledProgramsDetails,
    selectInstalledProgramsStatus,
    getAppsCompareDetails,
    changeProgram,
    updateSeenListStatus,
    selectallPageData,
    changeFilters,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import IconButton from '@mui/material/IconButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { InstalledProgramsDetails } from './InstalledProgramsDetails';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { CommentType } from 'src/models/commentType.enum';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { USER_TIME_ZONE } from 'src/utils/environment';
import { timeZoneFormat } from 'src/utils/dateUtils';

export function InstalledProgramsAlertsTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();
    const filters = useSelector(selectFilters);
    const installedProgramsStatus = useSelector(selectInstalledProgramsStatus);
    const installedProgramsData = useSelector(selectInstalledPrograms);
    const installedProgramsDetails = useSelector(selectInstalledProgramsDetails);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [open, setOpen] = React.useState(false);
    const [currentFilter, setCurrentFilter] = useState({});
    const [datas, setDatas] = useState<string[] | undefined>(undefined);
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const allPageData = useSelector(selectallPageData);
    const [lastPage, setLastPage] = useState(0);
    const prevPage = useRef(1);

    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };
    const handleOpen = (data: InstalledProgramsData): void =>
    {
        openRecordData(data.id);

        dispatch(changeInstalledProgramsDetails(data));
        dispatch(getAppsCompareDetails(data.id));
        if (!data.isSeen)
        {
            dispatch(updateSeenListStatus({
                detailsId: data.id,
                dataType: DataType.PROGRAMS,
            }));
        }
        setOpen(true);
    };

    function onPageChange(pageNumber: number): void
    {
        if (
            installedProgramsData &&
            pageNumber !== lastPage &&
            (
                pageNumber === prevPage.current + 1 ||
                pageNumber === prevPage.current - 1
            )
        )
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(
                    installedProgramsData.contents?.[
                        installedProgramsData.contents.length - 1
                    ]?.id ?? ''
                );
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(
                    Array.isArray(lastRecordDatas) && lastRecordDatas.length > 0 ?
                        lastRecordDatas[lastRecordDatas.length - 1]?.id :
                        ''
                );
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        prevPage.current = pageNumber;
    }

    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }
    const handleCloseModal = (): void =>
    {
        setOpen(false);
        dispatch(changeProgram([]));
    };

    useEffect(() =>
    {
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if (filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const rowsPerPage = filters?.rowsPerPage !== undefined ?
            filters.rowsPerPage :
            entriesPerPage;

        dispatch(getInstalledPrograms({
            skipRecordId,
            pageNumber: checkFilterChange(currentFilter, filters) ? currentPage : 1,
            entriesPerPage: rowsPerPage,
            ...filters,
        }));
        setCurrentFilter(filters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const detailItems = [
        {
            label: t('data-type.table.received-date'),
            text: (data: InstalledProgramsData) => timeZoneFormat(
                new Date(data.receivedDate),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.table.captured-date'),
            text: (data: InstalledProgramsData) => timeZoneFormat(
                new Date(data.capturedDate),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: InstalledProgramsData) => data.device,
        },
        {
            label: t('data-type.table.user-name'),
            text: (data: InstalledProgramsData) => data.username,
        },

    ];

    const getFlagColumn = (data: InstalledProgramsData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.PROGRAMS}
            onChange={() =>
            {
                onPageChange(installedProgramsData?.currentPage ?? 1);
            }}
        />
    );
    const openDetailColumn = (data: InstalledProgramsData): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const mapPrograms = (programs: InstalledProgram[]): Array<object> =>
    {
        return programs.slice(-5).map((program) =>
        {
            const status = program.status || '';
            return {
                programName: program.programName,
                status,
                isBlocked: program.isBlocked ?? false,
            };
        });
    };
    //  eslint-disable-next-line
    const renderPrograms = (programNames: any[]): React.ReactNode => {
        return programNames.map((program, key) =>
        {
            let color;
            const status = program.status || '';
            if (status === 'updated')
            {
                color = 'orange';
            }
            else if (status === 'installed')
            {
                color = 'green';
            }
            else if (status === 'uninstalled')
            {
                color = 'red';
            }
            else
            {
                color = 'black';
            }

            const isLastRecord = key === programNames.length - 1;
            return (
                <div key={`row-${key + 1}`}>
                    <span
                        style={{
                            backgroundColor: program.isBlocked ? '#FFFF99' : 'transparent',
                        }}
                    >
                        <span style={{ color }}>
                            {program.programName}
                        </span>
                        {status && (
                            <span>
                                <span style={{ color }}>
                                    {isLastRecord ? status : `${status},`}
                                </span>
                            </span>
                        )}
                    </span>
                </div>
            );
        });
    };
    useEffect(()=>
    {
        if(filters)
        {
            setCurrentPage(1);
        }
    }, [filters]);

    const columns = [
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: InstalledProgramsData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: InstalledProgramsData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: InstalledProgramsData) =>
                new Date(data.capturedDate).toLocaleString(),
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: InstalledProgramsData) =>
                new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: InstalledProgramsData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.program-changes'),
            size: 6,
            format: (data: InstalledProgramsData) =>
            {
                if (data.programs.length > 0)
                {
                    const programNames = mapPrograms(data.programs);
                    return renderPrograms(programNames);
                }
                else
                {
                    return 'No programs available';
                }
            },
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: InstalledProgramsData) => openDetailColumn(data),
        },
    ];

    useEffect(() =>
    {
        if (installedProgramsData)
        {
            setLastPage(
                Math.floor(installedProgramsData.numberOfEntries / entriesPerPage)
            );
            pdfExportSetter({
                type: DataType.PROGRAMS,
                recordIds: installedProgramsData.contents.map((item) => item.id),
                sortBy: filters.sortBy,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(installedProgramsData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.PROGRAMS)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [installedProgramsData, pdfExportSetter]);

    return (
        <>
            <DataDetailsModal<InstalledProgramsData>
                title={t('data-type.details.programs-and-applications')}
                open={open}
                data={installedProgramsDetails}
                detailItems={detailItems}
                onClose={handleCloseModal}
                comments={{
                    contentId: installedProgramsDetails?.id,
                    type: CommentType.PROGRAMS,
                }}
            >
                <InstalledProgramsDetails
                    {...(installedProgramsDetails as InstalledProgramsData)}
                />
            </DataDetailsModal>
            {useDesktopScreen() ? (
                <DataTable<InstalledProgramsData>
                    isLoading={installedProgramsStatus === RequestStatus.InProgress}
                    columns={columns}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber) =>
                        onPageChange(pageNumber)
                    }
                    onChangeEntriesPerPage={(entriesPerPage) =>
                        updateEntriesPerPage(entriesPerPage)
                    }
                    datas={datas}
                    {...installedProgramsData}
                />
            ) : (
                <DataTableMobile
                    isLoading={installedProgramsStatus === RequestStatus.InProgress}
                    contents={installedProgramsData?.contents || []}
                    setOpenModal={handleOpen}
                    onPageChange={(pageNumber) =>
                        onPageChange(pageNumber)
                    }
                    onChangeEntriesPerPage={(entriesPerPage) =>
                        updateEntriesPerPage(entriesPerPage)
                    }
                    entriesPerPage={entriesPerPage}
                    numberOfEntries={installedProgramsData?.numberOfEntries}
                    currentPage={installedProgramsData?.currentPage}
                    enablePagination
                />
            )}
        </>
    );
}
