import { APICaptureData, readAPICaptureData } from './captures';
import { TextMMSData, MMSType } from 'src/models/captures/textMMSData.model';


export interface APITextMMSResponse extends APICaptureData {
    deviceNumber: string;
    externalNumber: string;
    contactName: string;
    message: string;
    mmsType: MMSType;
    alertWords: string[];
    fileId: string;
    fileUrl: string;
    aiScanStatus?: number
    comments?: number;
}

export function readAPITextMMSResponse(
    data: APITextMMSResponse
): TextMMSData
{
    const {
        deviceNumber,
        externalNumber,
        contactName,
        message,
        mmsType,
        alertWords,
        fileId,
        fileUrl,
        aiScanStatus,
        comments,
        ...captureData
    } = data;

    return {
        ...readAPICaptureData(captureData),
        deviceNumber,
        externalNumber,
        contactName,
        message,
        mmsType,
        alertWords,
        fileId,
        fileUrl,
        aiScanStatus,
        comments,
    };
}
