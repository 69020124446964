import {
    Device,
    DeviceCreationParams,
    DeviceLogs,
    DeviceParams,
    DeviceUpdateParams,
    PlatformVersionUpateResponse,
    PlatformVersionUpdateParams,
    DevicePermissions,
} from 'src/models/administration/deviceData.model';
import { APIPaginationParams, transformPaginationParams } from '../pagination';
import { APIPlatform, readPlatform, transformPlatform } from '../platform';
import { PlatformVersion } from 'src/models/administration/platformVersionData.model';
import { VersionsParams } from './agencies';

export interface APIDeviceParams extends APIPaginationParams {
  deviceId?: string;
  clientId?: string;
  agencyId?: string;
  searchValue?: string;
}

export interface APIDeviceResponse {
  id: string;
  createdAt: string;
  createdBy: string;

  deviceAlias: string;
  platform: APIPlatform;

  activated: boolean;
  enabled: boolean;
  disableDevice: boolean;

  deviceModel: string;
  activatedAt: Date;
  lastConnectAt: Date;
    officerId: string
  clientId: string;
  clientName: string;
  clientPhones: string[];

  agencyId: string;
  agencyName: string;

  device: string;
  localName: string;
  ipAddress: string;
  versionInstalled: string;
  versionUpdateOn:Date
  macAddress: string;
  osVersion?: string;
  totalRAM?: number;
  availableRAM?: number;
}

export interface APIDeviceCreationParams {
  deviceAlias: string;
  platform: APIPlatform;
  clientId: string;
}

export interface APIDeviceUpdateParams {
  deviceAlias: string;
  enabled: boolean;
  disableDevice?: boolean;
}

export function transformCreationDeviceParams({
    alias,
    platform,
    client,
}: DeviceCreationParams): APIDeviceCreationParams
{
    return {
        deviceAlias: alias,
        platform: transformPlatform(platform),
        clientId: client,
    };
}

export function transformDeviceUpdateParams(
    params: DeviceUpdateParams
): APIDeviceUpdateParams
{
    return {
        deviceAlias: params.alias,
        enabled: params.enabled,
        disableDevice: params.disableDevice,
    };
}

export function updatePlatformParams(
    params?: PlatformVersionUpdateParams
): PlatformVersionUpdateParams
{
    return {
        id: params?.id,
        version: params?.version,
        platform: params?.platform,
        downloadUrl: params?.downloadUrl,
        downgrade: params?.downgrade,
        agencyIds: params?.agencyIds,
    };
}


export function readAPIAgencyCompareResponse(params: PlatformVersion[]): PlatformVersion[]
{
    const processedData = params.map((platformVersion) =>
    {
        const { platform, version, downloadUrl, agencyIds } = platformVersion;
        return {
            id: platformVersion._id?.toString(),
            platform,
            version,
            downloadUrl,
            agencyIds,
        };
    });
    return processedData;
}

export function readAPIAgencyCurrentResponse(params: VersionsParams): VersionsParams
{
    const currentVersions = params?.currentVersions?.map((platformVersion) =>
    {
        const { platform, version, downloadUrl, downgrade, agencyIds, isChecked } = platformVersion;
        return {
            id: platformVersion._id?.toString(),
            platform,
            version,
            isChecked,
            downloadUrl,
            downgrade,
            agencyIds,
        };
    });
    const formData = {
        currentVersions,
    };
    return formData;
}

export function transformDeviceParams(params: DeviceParams): APIDeviceParams
{
    const { deviceId, clientId, agencyId, searchValue, ...paginationParams } = params;

    return {
        deviceId,
        clientId,
        agencyId,
        searchValue,
        ...transformPaginationParams(paginationParams),
    };
}

export function platformAPIResponse(
    data: PlatformVersionUpateResponse
): PlatformVersionUpateResponse
{
    return {
        status: data.status,
        message: data.message,
    };
}

export function readAPIDeviceResponse(data: APIDeviceResponse): Device
{
    return {
        id: data.id,
        createdBy: data.createdBy,
        createdAt: new Date(data.createdAt),

        alias: data.deviceAlias,
        platform: readPlatform(data.platform),

        activated: data.activated,
        enabled: data.enabled,
        disableDevice: data.disableDevice,
        officerId: data.officerId,
        clientId: data.clientId,
        clientName: data.clientName,
        clientPhones: data.clientPhones,
        userId: data.officerId,
        agencyId: data.agencyId,
        agencyName: data.agencyName,

        device: data.device,
        localName: data.localName,
        ipAddress: data.ipAddress,
        versionInstalled: data.versionInstalled,
        versionUpdateOn: data.versionUpdateOn,
        macAddress: data.macAddress,
        osVersion: data?.osVersion,
        totalRAM: data?.totalRAM,
        availableRAM: data?.availableRAM,
        deviceModel: data.deviceModel,
        activatedAt: data.activatedAt,
        lastConnectAt: data.lastConnectAt,

    };
}

export function readAPIDeviceLogResponse(data: DeviceLogs): DeviceLogs
{
    return {
        id: data.id,
        fileName: data.fileName,
        fileSizeBytes: data.fileSizeBytes,
        fileUrl: data.fileUrl,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
        clientCreatedAt: data.clientCreatedAt,
    };
}

export interface DevicePermissionResponse
{
    id: string;
    permission: string;
    device: string;
    permissionType: string;
    date: Date;
}

export function readAPIDevicePermissionResponse(data: DevicePermissionResponse): DevicePermissions
{
    return {
        id: data.id,
        device: data.device,
        permission: data.permission,
        permissionType: data.permissionType,
        date: data.date,
    };
}
