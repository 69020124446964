import { ErrorData } from './../models/errors/errorData.model';
export function handleError(errorData?: ErrorData, fieldName?: string, profileField?:string):
    boolean
{
    if(Array.isArray(errorData?.message))
    {
        const fieldError = errorData?.message?.find(message => message.property === fieldName);
        return fieldError !== undefined;
    }
    if(profileField && errorData?.message)
    {
        return profileField !== undefined;
    }


    return false;
}

export function handleErrorText(errorData?: ErrorData, fieldName?: string, profileField?:string):
    string
{
    if(Array.isArray(errorData?.message))
    {
        const fieldError = errorData?.message?.find(message => message.property === fieldName);
        const errors: string[] = [];
        if (fieldError)
        {
            const vall = fieldError.constraints;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Object.entries(vall as any).forEach(el => errors.push(el[1] as string));
        }

        return errors.join('\n');
    }
    if(profileField)
    {
        return errorData?.message ?? '';
    }

    return '';
}

export function getErrorConstraints(fieldName: string, errorData?: ErrorData): ErrorData {
    const messages = errorData && Array.isArray(errorData.message)
        ? errorData.message
        : [];
    
    const fieldErrors = messages.find((error) => error.property === fieldName);
    const firstConstraint = fieldErrors?.constraints
    ? Object.values(fieldErrors.constraints as { [key: string]: string })[0] 
    : '';

    const errorConstraints = {
        message: [
            {
                ...fieldErrors,
                constraints: { arrayNotEmpty: firstConstraint },
            },
        ],
    };

    return errorConstraints as unknown as ErrorData;
}

