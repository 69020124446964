import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { PaginatedContent } from 'src/models/paginatedResult.model';
import { TableExportFormat, useTableExporter } from '../table-exporter/TableExporterProvider';
import { ColumnSelector } from 'src/templates/column-selector/ColumnSelector';

import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
    selectAlertsFilter,
    selectFilters,
    changeFilters,
    changeAlertsFilter,
    selectTableDatatype,
} from 'src/state/captures/capturesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AGENCYPAGE, ALERTSPAGE } from 'src/models/alertsType.enum';
import {
    changeDeviceDetailsFilters,
    selectDeviceDetailsFilters,
} from 'src/state/administrationMeta/administrationMetaSlice';
import { DataType } from 'src/models/dataType.enum';

export type ColumnData<DataType> = Omit<TableCellProps, 'size'> & {
    label: string;
    size?: number;
    value?: (data: DataType) => string | number | undefined | React.ReactNode;
    format?: (data: DataType) => React.ReactNode;
    sortByType?: string;
    sortByDirection?: number;
    dateFormat?: (data: DataType) => string;
    disable?: boolean;
};

export interface DataTableProps<DataType>
    extends Partial<PaginatedContent<DataType>> {
    columns: ColumnData<DataType>[];
    isLoading?: boolean;
    pagination?: boolean;
    onPageChange: (newPage: number, entriesPerPage: number) => void;
    entriesPerPage: number;
    onChangeEntriesPerPage: (newEntriesPerPage: number) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    datas?: any;
}

export function DataTable<DataType>({
    columns,
    isLoading = false,
    contents = [],
    currentPage = 1,
    numberOfEntries = 0,
    pagination,
    onPageChange,
    entriesPerPage,
    onChangeEntriesPerPage,
    datas = [],
}: Readonly<DataTableProps<DataType>>): React.ReactElement
{
    const { t } = useTranslation();
    const { format, setExportFormat } = useTableExporter();
    const dispatch = useDispatch();
    const filters = useSelector(selectFilters);
    const alertsFilter = useSelector(selectAlertsFilter);
    const Agencypage = window.location.pathname === AGENCYPAGE;
    const dataType = useSelector(selectTableDatatype);
    const deviceFilters = useSelector(selectDeviceDetailsFilters);
    const [paginationEnabled, setPaginationEnabled] = useState<boolean>(true);

    useEffect(() =>
    {
        if (pagination === false )
        {
            setPaginationEnabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationEnabled]);
    // eslint-disable-next-line
    function checkListSeenuser(content: any): boolean {
        let viewedUser = false;
        if (content?.isSeen || (datas && Array.isArray(datas) && datas.includes(content.id)))
        {
            viewedUser = true;
        }
        return viewedUser;
    }

    // eslint-disable-next-line
      function checkdeleteagency(content: any): boolean {
        let viewedUser = false;
        if(content.deleted === true)
        {
            viewedUser = true;
        }
        return viewedUser;
    }

    function handleChangeEntriesPerPage(
        event: React.ChangeEvent<HTMLInputElement>
    ): void
    {
        const newEntriesPerPage = parseInt(event.target.value, 10);
        onChangeEntriesPerPage(newEntriesPerPage);
    }
    function handlePageChange(_event: unknown, newPage: number): void
    {
        onPageChange(newPage + 1, entriesPerPage);
    }

    // eslint-disable-next-line
    function onSortBy(sortByType?: string, theseFilters?: any): void
    {
        const newPage = 1;
        const isAlertsPage = window.location.pathname === ALERTSPAGE;
        const sortByValue = theseFilters?.sortBy?.includes(sortByType)
            && theseFilters?.sortBy?.includes(-1) ? 1 : -1;
        if (isAlertsPage)
        {
            dispatch(
                changeAlertsFilter({
                    sortBy: `{"${sortByType}": ${sortByValue}}`,
                })
            );
        }
        else if (dataType === DataType.DEVICE_INFORMATION)
        {
            dispatch(
                changeDeviceDetailsFilters({
                    sortBy: `{"${sortByType}": ${sortByValue}}`,
                })
            );
        }
        else
        {
            dispatch(
                changeFilters({
                    sortBy: `{"${sortByType}": ${sortByValue}}`,
                })
            );
        }
        onPageChange(newPage, entriesPerPage);
    }

    function renderCircularProgress(): React.ReactNode
    {
        return (
            <TableRow>
                <TableCell colSpan={999}>
                    <CircularProgress sx={{ margin: '4rem calc((100% - 40px) / 2)' }} />
                </TableCell>
            </TableRow>
        );
    }

    function renderNoContent(): React.ReactNode
    {
        return (
            <TableRow>
                <TableCell colSpan={999}>
                    <Typography
                        variant="h3"
                        sx={{
                            color: (theme) => theme.palette.common.lightGrey,
                            margin: '4rem auto',
                            textAlign: 'center',
                        }}
                    >
                        {t('data-type.table.no-content')}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }

    // eslint-disable-next-line
    function renderCellContent(column:ColumnData<DataType>, content:any, userSeenBy:boolean)
        :React.ReactNode
    {
        if(column.disable) return <></>;
        let cellContent;
        if (column.format)
        {
            cellContent = column.format(content);
        }
        else if (column.value)
        {
            cellContent = column.value(content);
        }
        else if (column.dateFormat)
        {
            cellContent = column.dateFormat(content) || '-';
        }
        else
        {
            cellContent = '';
        }
        return (
            <TableCell
                key={column.label}
                align={column.align}
                sx={{
                    wordBreak: 'break-word',
                    color: userSeenBy ? '#BDBDBD' : 'rgb(68, 68, 68)',
                    textAlign: column.label === t('data-type.table.last-connect-date')
                        ? 'center' : 'left',
                    '@media (max-width: 1024px)':
                        {
                            padding: '8px',
                        },
                }}
            >
                {cellContent}
            </TableCell>
        );
    }

    function renderBody(): React.ReactNode
    {
        if (isLoading)
        {
            return renderCircularProgress();
        }

        if (!contents?.length)
        {
            return renderNoContent();
        }

        return contents.map((content, index) =>
        {
            let userSeenBy: boolean;

            if (Agencypage && checkdeleteagency(content))
            {
                userSeenBy = true;
            }
            if (checkListSeenuser(content))
            {
                userSeenBy = true;
            }

            return (
                <TableRow
                    key={`row-${index + 1}`}
                >
                    {columns.map((column) => renderCellContent(column, content, userSeenBy))}
                </TableRow>
            );
        });
    }

    return (
        <>
            <TableContainer>
                <Table aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {columns.map(({ label, disable, size = 1, sortByType }) => (
                                !disable && (
                                    <TableCell
                                        key={label}
                                        sx={{
                                            width: `${size}%`,
                                            backgroundColor: '#EAEAEA',
                                            marginRight: -1,
                                            borderRight: '1px solid white',
                                            textTransform: 'capitalize',
                                        }}
                                        onClick={(event) =>
                                        {
                                            if (
                                                window.location.pathname ===
                                                ALERTSPAGE && sortByType
                                            )
                                            {
                                                return onSortBy(sortByType, alertsFilter);
                                            }
                                            if (dataType && sortByType)
                                            {
                                                return onSortBy(sortByType, deviceFilters);
                                            }
                                            if (sortByType)
                                            {
                                                return onSortBy(sortByType, filters);
                                            }
                                        }}
                                    >
                                        {label}
                                        {' '}
                                        {sortByType ? <SwapVertIcon /> : null}
                                    </TableCell>
                                )))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderBody()}
                    </TableBody>
                </Table>
            </TableContainer>
            {paginationEnabled &&
            (
                <TablePagination
                    showFirstButton
                    showLastButton
                    component="div"
                    count={numberOfEntries}
                    rowsPerPage={entriesPerPage}
                    page={currentPage - 1}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeEntriesPerPage}
                />
            )}
            {format && format !== TableExportFormat.PDF ? (
                <ColumnSelector
                    format={format}
                    columns={columns}
                    data={{
                        contents,
                        currentPage,
                        numberOfEntries,
                    }}
                    onClose={() => setExportFormat(undefined)}
                />
            ) : null}
        </>
    );
}
