import {
    APICaptureData,
    readAPICaptureData,
} from './captures';
import {
    ScreenshotData,
    ImageListItem,
} from 'src/models/captures/screenshotData.model';


export interface APIScreenshotResponse extends APICaptureData {
    aiScanStatus: number,
    appOnFocus: string,
    filesCount: number,
    alertWords: string[],
    images: ImageListItem[],
    clientCreatedAtLast: Date,
    clientCreatedAtFirst: Date,
    lastId: string,
    createdAtLast: Date,
    createdAtFirst: Date,
}

export function readAPIScreenshotResponse(
    data: APIScreenshotResponse
): ScreenshotData
{
    const {
        aiScanStatus,
        filesCount,
        appOnFocus,
        alertWords,
        clientCreatedAtLast,
        clientCreatedAtFirst,
        createdAtLast,
        createdAtFirst,
        images,
        lastId,
        ...captureData
    } = data;
    const clickedImage = {
        clientCreatedAt: new Date(),
        createdAt: new Date(),
    };
    return {
        ...readAPICaptureData(captureData),
        clickedImage,
        imageIndex: 0,
        aiScanStatus,
        filesCount,
        appOnFocus,
        alertWords,
        images,
        lastId,
        clientCreatedAtLast,
        clientCreatedAtFirst,
        createdAtFirst,
        createdAtLast,
    };
}

// eslint-disable-next-line
export function readAPIScreenshotmetaResponse(
// eslint-disable-next-line
    data: any
// eslint-disable-next-line
): any
{
    const {
        aiScanStatus,
        appOnFocus,
        alertWords,
        fileUrl,
        filescount,
        device,
        devices,
        deviceId,
        createdAtDate,
        ...captureData
    } = data;
    const clickedImage = {
        clientCreatedAt: new Date(),
        createdAt: new Date(),
    };
    return {
        ...readAPICaptureData(captureData),
        clickedImage,
        imageIndex: 0,
        aiScanStatus,
        appOnFocus,
        alertWords,
        fileUrl,
        device: data.userName,
        deviceId: data.device,
        deviceAlias: data.devices.deviceAlias,
        filesCount: data.numberofscreenshots,
        createdAtDate: data.createdAtDate,
        createdAtFirst: data.createdAtFirst,
    };
}
