import React, { useEffect, FocusEvent, useState } from 'react';

import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    Snackbar,
    SnackbarProps,
    TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    ExtensionParams, ExtensionManagement,
} from 'src/models/administration/extensionData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    createExtension,
    selectExtensionCreationStatus,
    updateExtension,
    selectExtensionUpdateStatus,
} from 'src/state/administration/administrationSlice';
import {
    Platform, platformStringKey,
} from 'src/models/platforms.enum';


const initialValues: ExtensionParams = {
    extension: '',
    platform: '',
};

export function ExtensionAddForm(props: Readonly<ExtensionManagement>): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const creationStatus = useSelector(selectExtensionCreationStatus);
    const updateStatus = useSelector(selectExtensionUpdateStatus);
    const [notificationState, setNotificationState] = useState<SnackbarProps>();
    const [formValues, setFormValues] = useState<ExtensionParams>(initialValues);

    useEffect(() =>
    {
        if(props.id)
        {
            setFormValues((current) => ({
                ...current,
                id: props.id,
                platform: props.platform,
                extension: props.extension,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const platforms = Object.values(Platform).map((value) =>
    {
        return {
            value,
            name: t(platformStringKey(value)),
        };
    });
    useEffect(() =>
    {
        if( creationStatus === RequestStatus.Success || updateStatus === RequestStatus.Success)
        {
            const subject = t('extension-form.extension');
            setNotificationState({
                open: true,
                message: t('common.messages.saved-successfully', { subject }),

            });
            setTimeout(() =>
            {
                props.closeAddModal(true);
                props.onComplete(true);
            }, 1000);
        }
        else if( creationStatus === RequestStatus.Failure || updateStatus === RequestStatus.Failure)
        {
            setNotificationState({
                open: true,
                message: t('user.extension-already-exist'),

            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creationStatus, updateStatus, t, dispatch, setNotificationState]);

    function onSubmit(event: React.FormEvent<HTMLFormElement>): void
    {
        event.preventDefault();
        if(props.id)
        {
            dispatch(updateExtension(formValues));
        }
        else
        {
            dispatch(createExtension(formValues));
        }

    }

    function updateValues(
        { name, value }: EventTarget & (HTMLInputElement | HTMLTextAreaElement),
        trim = false
    ): void
    {
        setFormValues((current) => ({
            ...current,
            [name]: value,
        }));
    }

    function onInputChange(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void
    {
        updateValues(event.target);
    }

    function onChangePlatform(data: string | undefined): void
    {
        data && setFormValues((current) => ({
            ...current,
            platform: data,
        }));
    }

    function onBlur(event: FocusEvent<HTMLInputElement>): void
    {
        updateValues(event.target, true);
    }

    function onClose(): void
    {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }

    return (
        <>
            <Box m={4}>
                <Box>
                    <form onSubmit={onSubmit}>
                        <Grid container spacing={5}>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    name="extension"
                                    label={t('Extension')}
                                    variant="outlined"
                                    value={formValues.extension}
                                    onChange={onInputChange}
                                    onBlur={onBlur}
                                    disabled={creationStatus === RequestStatus.InProgress}
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <Autocomplete
                                    disablePortal
                                    id="platform"
                                    value={ platforms.find(
                                        platform => platform.value === props.platform
                                    )}
                                    options={platforms ?? []}
                                    onChange={(_, data) => onChangePlatform(data?.value)}
                                    isOptionEqualToValue={(option, value) =>
                                        option.value === value.value}
                                    getOptionLabel={(option) => option.name}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            label={t('common.nouns.platform')}
                                        />
                                    )}
                                    disabled={!!props.id}
                                />
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="flex-end" sx={{ mt: 3 }}>
                            {
                                creationStatus === RequestStatus.InProgress
                                    ? <CircularProgress />
                                    :(
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            sx={{ mt: 0 }}
                                        >
                                            <Button
                                                sx={{
                                                    mr: 1,
                                                    backgroundColor: 'transparent',
                                                    color: theme => theme.palette.common.goldYellow,
                                                    '&.Mui-disabled': {
                                                        backgroundColor: 'inherit',
                                                        // eslint-disable-next-line
                                                        color: theme => theme.palette.common.goldYellow,
                                                    },
                                                }}
                                                onClick={ ()=> props.closeAddModal(false) }
                                            >
                                                {t('form.cancel')}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                            >
                                                {t('common.actions.save')}
                                            </Button>
                                        </Box>
                                    )
                            }
                        </Box>
                    </form>
                </Box>
            </Box>
            <Snackbar
                open={notificationState?.open}
                autoHideDuration={2000}
                onClose={onClose}
            >
                <Alert
                    severity={
                        (creationStatus === RequestStatus.Success ||
                             updateStatus === RequestStatus.Success)
                            ? 'success'
                            : 'error'
                    }
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}
