import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Theme } from '@mui/material';
import TextField from '@mui/material/TextField';

import { FiltersBar } from 'src/components/filters-bar/FiltersBar';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    selectReportFilters,
    setReportFilters,
} from 'src/state/administration/administrationSlice';
import { UserFilters } from 'src/models/administration/usersData.model';
import { DateFilterChange } from 'src/utils/dateUtils';
import { changeReportFilters, selectReportDateFilter } from 'src/state/captures/capturesSlice';

export function ReportsFilters(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const filters = useSelector(selectReportFilters);
    const filter = useSelector(selectReportDateFilter);
    function onChangeDateFilter(
        field: keyof UserFilters,
        newdate?: Date | null | undefined
    ): void
    {
        const dateTime =
        DateFilterChange(
            field,
            newdate as Date,
            filter?.startDate,
            filters?.endDate
        );
        dispatch(setReportFilters({ [field]: newdate?.toISOString() }));
        dispatch(changeReportFilters({
            startDate: dateTime.startDateTime,
            endDate: dateTime.endDateTime,
        }));
    }

    return (
        <FiltersBar>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    label={t('data-type.filters.start-date')}
                    value={filter?.startDate ? new Date(filter?.startDate) : null}
                    onChange={(newValue) => onChangeDateFilter('startDate', newValue)}
                    renderInput={(params) => (
                        <TextField
                            onKeyDown={(e) => e.preventDefault()}
                            sx={{
                                backgroundColor: (theme: Theme) => theme.palette.common.white,
                                borderRadius: 1,
                            }}
                            {...params}

                        />
                    )}
                />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                    label={t('data-type.filters.end-date')}
                    value={filter?.endDate ? new Date(filter?.endDate) : null}
                    onChange={(newValue) => onChangeDateFilter('endDate', newValue)}
                    renderInput={(params) => (
                        <TextField
                            onKeyDown={(e) => e.preventDefault()}
                            sx={{
                                backgroundColor: (theme: Theme) => theme.palette.common.white,
                                borderRadius: 1,
                            }}
                            {...params}

                        />
                    )}
                />
            </LocalizationProvider>
        </FiltersBar>
    );
}
