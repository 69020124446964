import React, { useEffect } from 'react';
import {
    CircularProgress,
    Box,
    Stack,
    Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    BackNavigation,
    getClient,
    selectClient,
    selectClientRetrieveStatus,
} from 'src/state/administration/administrationSlice';
import { AdministrationHeader } from 'src/templates/administration-header/AdministrationHeader';
import { AdministrationPage } from 'src/templates/administration-page/AdministrationPage';
import { ClientDevicesTable } from 'src/templates/client-devices-table/ClientDevicesTable';
import Grid from '@mui/material/Grid';
import {
    AdminSettingSearchFilter,
} from 'src/templates/admin-setting-search-filter/AdminSettingSearchBar';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

export function ClientDeviceManagement(): React.ReactElement
{
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const client = useSelector(selectClient);
    const status = useSelector(selectClientRetrieveStatus);

    useEffect(() =>
    {
        if (params.id)
        {
            dispatch(getClient(params.id));
        }
    }, [params, dispatch]);

    function render(): React.ReactElement | null
    {
        if (status === RequestStatus.Failure)
        {
            return (
                <div>
                    {t('common.messages.something-wrong')}
                </div>
            );
        }
        else if (status === RequestStatus.InProgress)
        {
            return (
                <CircularProgress />
            );
        }
        else if (status === RequestStatus.Success && client)
        {
            return (
                <>
                    <AdministrationHeader
                        labels={[
                            t('menu.clients-management'),
                            `${client.firstName} ${client.lastName}`,
                            t('common.nouns.devices'),
                        ]}
                    />
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        padding={'1em 0'}
                    >
                        <Button
                            variant={'contained'}
                            onClick={() =>
                            {
                                window.history.back();
                                dispatch(BackNavigation(true));
                            }}
                            sx={{ marginRight: 'auto' }}
                        >
                            <KeyboardDoubleArrowLeftIcon/>
                            {t('common.actions.back')}
                        </Button>
                        <Box sx={{ mr: 20}}>
                            <Grid
                                item
                                md={3}
                                sm={12}
                                xs={12}
                            >
                                <AdminSettingSearchFilter/>
                            </Grid>
                        </Box>
                    </Stack>
                    <ClientDevicesTable
                        client={client}
                    />
                </>
            );
        }
        else
        {
            return null;
        }
    }

    return (
        <AdministrationPage>
            { render() }
        </AdministrationPage>
    );
}
