import {
    Box,
    Button,
    Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'src/components/modal/Modal';
import { TopBar } from 'src/components/top-bar/TopBar';
import {
    resetCustomAWLCreationStatus,
} from 'src/state/administration/administrationSlice';
import { noop } from 'src/utils/common';
import { AgencyFormValues, DEFAULT_VALUES } from '../agency-form/AgencyForm';
import { CustomAWLTable } from 'src/templates/custom-awl-table/CustomAWLTable';
import { AWLContext } from 'src/templates/awl-creation-tabs/AWLCreationTabs';
import { Role } from 'src/models/roles.enum';
import { selectRole } from 'src/state/authorization/authorizationSlice';
import { AWLTableContainerProps } from 'src/templates/awl-table-container/AWLTableContainer';
import {
    AgencyAWLConfigurationContextProvider,
    useGetConfiguration,
} from 'src/templates/awl-index-tabs/AgencyAWLConfigurationContext';
import {
    CustomAWLCreationForm,
} from 'src/templates/custom-awl-creation-form/CustomAWLCreationForm';
import {
    AWLEdit,
} from 'src/templates/awl-edit/AWLEdit';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export interface AgencyEditionFormModalProps {
    agency: AgencyFormValues;
    open?: boolean;
    onClose?: () => void;
}

export function AgencyAlertWordsModal({
    agency,
    open = false,
    onClose = noop,
}: Readonly<AgencyEditionFormModalProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [alertId, setAlertId] = useState<string>('');
    const [isModalAddWords, setIsModalAddWords] = useState<boolean>(false);
    const [isModalEditWords, setIsModalEditWords] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<AgencyFormValues>(DEFAULT_VALUES);
    const role = useSelector(selectRole);
    const getConfiguration = useGetConfiguration();
    const context = AWLContext.Agency;
    const tableContainerProps: AWLTableContainerProps<typeof context> = {
        type: context,
    };

    function onSuccessAdd(): void
    {
        setInitialValues(agency);
        setIsModalAddWords(false);
        dispatch(getConfiguration(agency.id));
        dispatch(resetCustomAWLCreationStatus());
    }
    function onFailAdd(): void
    {
        setIsModalAddWords(true);
        dispatch(resetCustomAWLCreationStatus());
    }

    function selectIsModalEdit(id: string ): void
    {
        setAlertId(id);
        setIsModalEditWords(true);
    }
    function onSuccessEdit(): void
    {
        setInitialValues(agency);
        setIsModalEditWords(false);
        dispatch(getConfiguration(agency.id));
        dispatch(resetCustomAWLCreationStatus());
    }
    function onFailEdit(): void
    {
        setIsModalEditWords(true);
        dispatch(resetCustomAWLCreationStatus());
    }
    function onCancel(): void
    {
        onClose();
    }

    function onBackToList(): void
    {
        setInitialValues(agency);
        setIsModalAddWords(false);
        setIsModalEditWords(false);
        dispatch(getConfiguration(agency.id));
        dispatch(resetCustomAWLCreationStatus());
    }

    useEffect(() =>
    {
        setInitialValues(agency);
    }, [initialValues, setInitialValues, agency]);
    useEffect(() =>
    {
        if (agency.id)
        {
            dispatch(getConfiguration(agency.id));
        }
    }, [dispatch, getConfiguration, agency]);

    if(role)
    {
        tableContainerProps.canAdd = [
            Role.Administrator,
            Role.OfficerSupervisor,
            Role.AgencyAdministrator,
        ].includes(role);
        tableContainerProps.agencySelection = [
            Role.Administrator,
            Role.Employee,
        ].includes(role);
        tableContainerProps.tableProps = {
            editable: [Role.Administrator,
                Role.OfficerSupervisor,
                Role.AgencyAdministrator].includes(role),
            blocked: role !== Role.OfficerSupervisor,
        };
    }
    if(isModalAddWords)
    {
        return (
            <Modal
                title={agency.agencyName+ ' ' +t('menu.alert-word-management')}
                open={open}
                onClose={onCancel}
                showCloseButton={true}
                maxWidth="md"
                fullWidth
            >
                { role && (
                    <CustomAWLCreationForm
                        mode={role}
                        assignedTo={agency.id}
                        onBackToList={onBackToList}
                        onSuccess={onSuccessAdd}
                        onFailure={onFailAdd}
                    />
                ) }
            </Modal>
        );
    }
    else if(isModalEditWords)
    {
        return (
            <Modal
                title={agency.agencyName+ ' ' +t('menu.alert-word-management')}
                open={open}
                onClose={onCancel}
                showCloseButton={true}
                maxWidth="md"
                fullWidth
            >
                { role && (
                    <AWLEdit
                        mode={role}
                        context="custom"
                        alertId={alertId}
                        onBackToList={onBackToList}
                        onSuccess={onSuccessEdit}
                        onFailure={onFailEdit}
                    />
                ) }
            </Modal>
        );
    }
    else return (
        <Modal
            title={agency.agencyName+ ' ' +t('menu.alert-word-management')}
            open={open}
            onClose={onCancel}
            showCloseButton={true}
            maxWidth="md"
            fullWidth
        >
            <AgencyAWLConfigurationContextProvider>
                <Box m={4}>
                    <TopBar
                        buttons={[
                            {
                                text: t('alert-word.Add-new-SAW'),
                                icon: <AddCircleIcon />,
                                onClick: () => setIsModalAddWords(true),
                            },
                        ]}
                    />
                    <CustomAWLTable
                        {...tableContainerProps.tableProps}
                        agencyId={agency.id}
                        selectIsModalEdit={selectIsModalEdit}
                    />
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        padding="1em 0"
                        gap={1}
                    >
                        <Button
                            type="button"
                            variant="outlined"
                            onClick={onCancel}
                        >
                            {t('common.actions.close')}
                        </Button>
                    </Stack>
                </Box>
            </AgencyAWLConfigurationContextProvider>
        </Modal>
    );
}
