/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'src/components/modal/Modal';
import {
    getClientDisbaleAWLs,
    getOfficerDisbaleAWLs,
    resetClientAWLCreationStatus,
    resetOfficerAWLCreationStatus,
    selectClientAWLCreationStatus,
    selectOfficerAWLCreationStatus,
    updateClientAWLToggle,
} from 'src/state/administration/administrationSlice';
import { noop } from 'src/utils/common';
import {
    ClientFormValues,
    DEFAULT_VALUES as CLIENT_DEFAULT_VALUES,
} from '../client-form/ClientForm';

import { AWLContext } from 'src/templates/awl-creation-tabs/AWLCreationTabs';
import { Client } from 'src/models/administration/clientData.model';
import { selectRole } from 'src/state/authorization/authorizationSlice';
import { AWLTableContainerProps } from 'src/templates/awl-table-container/AWLTableContainer';
import CustomTopBarWithButton, {
    AgencyAWLConfigurationContextProvider,
    useGetConfiguration,
} from 'src/templates/awl-index-tabs/AgencyAWLConfigurationContext';
import {
    ClientAWLCreationForm,
} from 'src/templates/client-awl-creation-form/ClientAWLCreationForm';
import {
    AWLEdit,
} from 'src/templates/awl-edit/AWLEdit';
import { ClientAWLTable } from 'src/templates/client-awl-table/ClientAWLTable';

import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';
import { userIsInRole } from 'src/utils/roleUtils';
import { Role } from 'src/models/roles.enum';

import { RootState } from 'src/state/store';
import { Officer } from 'src/models/administration/officerData.model';
import {
    OfficerFormValues,
    DEFAULT_VALUES as OFFICER_DEFAULT_VALUES,
} from '../officer-form/OfficerForm';
import { updateOfficerAWLToggle } from 'src/services/administration/alertWordList';
import { OfficerAWLCreationForm } from '../officer-awl-creation-form/OfficerAWLCreationForm';
import { OfficerAWLTable } from '../officer-awl-table/OfficerAWLTable';

export interface ClientAlertWordsModalProps {
    user?: Officer;
    client?: Client;
    open?: boolean;
    onClose?: () => void;
}

export function ClientAlertWordsModal({
    client,
    user,
    open = false,
    onClose = noop,
}: Readonly<ClientAlertWordsModalProps>): React.ReactElement
{
    type FormValues = ClientFormValues | OfficerFormValues;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [alertId, setAlertId] = useState<string>('');
    const [isModalAddWords, setIsModalAddWords] = useState<boolean>(false);
    const [isModalEditWords, setIsModalEditWords] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<FormValues>(
        CLIENT_DEFAULT_VALUES ?? OFFICER_DEFAULT_VALUES
    );
    const data = useSelector((state : RootState) => state.administration.clientDisableAWLs
    ?? state.administration.officerDisableAWLs );
    const [isAddButtonDisabled, setIsAddButtonDisabled] =
     useState<boolean>(data?.disableAWL?? false);
    const ClientStatus = useSelector(selectClientAWLCreationStatus);
    const officerStatus = useSelector(selectOfficerAWLCreationStatus);
    const role = useSelector(selectRole);
    const getConfiguration = useGetConfiguration();
    const context = client ? AWLContext.Client : AWLContext.Officer;
    const tableContainerProps: AWLTableContainerProps<typeof context> = {
        type: context,
    };
    const { id: clientId, firstName, lastName } = client || {};
    const {
        id: userId,
        firstName: userFirstName,
        lastName: userLastName,
    } = user || {};
    const fullName = client
        ? `${firstName} ${lastName}`
        : `${userFirstName} ${userLastName}`;
    const title = `${fullName} - ${t('menu.alert-word-management')}`;


    useEffect(() =>
    {
        if(clientId)
        {
            dispatch (getClientDisbaleAWLs({ clientId }));
        }
        else if(userId)
        {
            dispatch(getOfficerDisbaleAWLs({ userId }));
        }

    }, [clientId, userId, dispatch]);

    useEffect(() =>
    {
        setIsAddButtonDisabled(data?.disableAWL ?? false);
    }, [data]);

    useEffect(() =>
    {
        setInitialValues(client as FormValues ?? user as FormValues);
    }, [initialValues, setInitialValues, client, user]);

    useEffect(() =>
    {
        callResetDispatch();
    }, [dispatch, getConfiguration, client, user]);

    function callDispatch ():void
    {
        if(client)
        {
            dispatch(getConfiguration(client.id));
            dispatch(resetClientAWLCreationStatus());
        }
        else if(user)
        {
            dispatch(getConfiguration(user.id));
            dispatch(resetOfficerAWLCreationStatus());
        }
    }
    function callResetDispatch ():void
    {
        if(client)
        {
            dispatch(resetClientAWLCreationStatus());
        }
        else if(user)
        {
            dispatch(resetOfficerAWLCreationStatus());
        }
    }
    function onSuccessAdd(): void
    {
        setInitialValues(client as FormValues ?? user as FormValues );
        setIsModalAddWords(false);
        callDispatch();

    }
    function onFailAdd(): void
    {
        setIsModalAddWords(true);
        callResetDispatch();
    }

    function selectIsModalEdit(id: string ): void
    {
        setAlertId(id);
        setIsModalEditWords(true);
    }
    function onSuccessEdit(): void
    {
        setInitialValues(client as FormValues ?? user as FormValues );
        setIsModalEditWords(false);
        callDispatch();

    }
    function onFailEdit(): void
    {
        setIsModalEditWords(true);
        callResetDispatch();

    }
    function onCancel(): void
    {
        onClose();
    }

    function onBackToList(): void
    {
        setInitialValues(client as FormValues ?? user as FormValues);
        setIsModalAddWords(false);
        setIsModalEditWords(false);
        callDispatch();
    }

    const handleToggleChange = (): void =>
    {
        const isDisabled = !isAddButtonDisabled;
        setIsAddButtonDisabled(isDisabled);
        saveToggleValue(isDisabled);
    };

    const saveToggleValue = (isDisabled: boolean): void =>
    {
        if(client)
        {
            const payload = {
                disableAWL: isDisabled,
                clientId: client.id,
            };
            dispatch(updateClientAWLToggle(payload));
        }
        else if(user)
        {
            const payload = {
                disableAWL: isDisabled,
                userId: user.id,
            };
            dispatch(updateOfficerAWLToggle(payload));
        }

    };

    if(role)
    {
        tableContainerProps.canAdd = true;
        tableContainerProps.tableProps = {
            editable: true,
        };
    }
    if(isModalAddWords || isModalEditWords)
    {
        return (
            <Modal
                title={title}
                open={open}
                onClose={ isModalAddWords ? onSuccessAdd: onSuccessEdit }
                showCloseButton={true}
                maxWidth="md"
                fullWidth
            >
                <>
                    {isModalAddWords && role && client && (
                        <ClientAWLCreationForm
                            status={ClientStatus}
                            assignedTo={client.id}
                            onBackToList={onBackToList}
                            onSuccess={onSuccessAdd}
                            onFailure={onFailAdd}
                        />
                    ) }
                    {isModalAddWords && role && user &&(
                        <OfficerAWLCreationForm
                            status={officerStatus}
                            assignedTo={user.id}
                            onBackToList={onBackToList}
                            onSuccess={onSuccessAdd}
                            onFailure={onFailAdd}
                        />
                    )}
                    { role && isModalEditWords && client && (
                        <AWLEdit
                            mode={role}
                            context={'client'}
                            alertId={alertId}
                            onBackToList={onBackToList}
                            onSuccess={onSuccessEdit}
                            onFailure={onFailEdit}
                        />
                    ) }
                    {role && isModalEditWords && user && (
                        <AWLEdit
                            mode={role}
                            context={'officer'}
                            alertId={alertId}
                            onBackToList={onBackToList}
                            onSuccess={onSuccessEdit}
                            onFailure={onFailEdit}
                        />
                    )}
                </>
            </Modal>
        );
    }
    else return (
        <Modal
            title={title}
            open={open}
            onClose={onCancel}
            showCloseButton={true}
            maxWidth="md"
            fullWidth
        >
            <AgencyAWLConfigurationContextProvider>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    padding={'1em 0'}
                    margin={'-2em 0em 0em 0em'}
                />
                <Box m={4}>
                    <CustomTopBarWithButton
                        buttonConfig={{
                            isDisabled: isAddButtonDisabled,
                            label: t('alert-word.Add-new-SAW'),
                            onClick: () => setIsModalAddWords(true),
                        }}
                    />
                    {
                        client && (

                            <ClientAWLTable
                                clientId={client?.id}
                                selectIsModalEdit={selectIsModalEdit}
                                isAddButtonDisabled={isAddButtonDisabled}
                            />
                        )
                    }
                    {
                        user && (

                            <OfficerAWLTable
                                userId={user?.id}
                                selectIsModalEdit={selectIsModalEdit}
                                isAddButtonDisabled={isAddButtonDisabled}
                            />
                        )
                    }

                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        padding="1em 0"
                        gap={1}
                    >

                        {
                            (userIsInRole([Role.Administrator,
                                Role.AgencyAdministrator,
                                Role.Employee]))? (
                                    <Grid
                                        sx={{ marginRight: 'auto' }}
                                    >
                                        {t('common.actions.block-officer')}
                                        <ToggleSwitch
                                            sx={{ ml: 1, fontSize: 2 }}
                                            checked={isAddButtonDisabled}
                                            onChange={ handleToggleChange }
                                        />
                                    </Grid>
                                ) : null
                        }
                        <Button
                            type="button"
                            variant="outlined"
                            onClick={onCancel}
                        >
                            {t('common.actions.close')}
                        </Button>
                    </Stack>
                </Box>
            </AgencyAWLConfigurationContextProvider>
        </Modal>
    );
}
