import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'src/models/userModels';
import {
    clearUser,
    getOffUser,
    selectUserFilters,
    selectUsers,
    selectUsersStatus,
    setUser,
    selectAdminFilters,
} from 'src/state/administration/administrationSlice';
import { generatePath, useNavigate, useLocation } from 'react-router-dom';
import {
    ADMIN_SETTINGS,
    ALERT_WORDS_PAGE,
    USER_MANAGEMENT_EDIT,
} from 'src/utils/routes';
import { DataTable, ColumnData } from 'src/components/data-table/DataTable';
import { Box, Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { RequestStatus } from 'src/models/requestStatus.enum';
import { Role } from 'src/models/roles.enum';
import { noop } from 'src/utils/common';
import BuildIcon from '@mui/icons-material/Build';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Officer } from 'src/models/administration/officerData.model';
import { selectRole } from 'src/state/authorization/authorizationSlice';

export interface UserTableProps {
    roles?: Role[];
    onEdit?: (user: User) => void;
    onConfig?: (user: User) => void
    onAlert?: (user: Officer) => void;
}

export function OfficersTable({
    roles,
    onEdit = noop,
    onConfig = noop,
    onAlert = noop,
}: Readonly<UserTableProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const users = useSelector(selectUsers);
    const filters = useSelector(selectUserFilters);
    const status = useSelector(selectUsersStatus);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const adminFilter = useSelector(selectAdminFilters);
    const role = useSelector(selectRole);
    const location = useLocation();
    const isRestricted =
        location.pathname === ADMIN_SETTINGS ||
        (ALERT_WORDS_PAGE &&
            role !== Role.Officer && role !== Role.OfficerSupervisor);

    useEffect(() =>
    {
        setTimeout(() => dispatch(clearUser()), 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        dispatch(
            getOffUser({
                pageNumber: 1,
                entriesPerPage,
                ...{ ...filters, role: roles, ...adminFilter },
            })
        );
    }, [dispatch, entriesPerPage, filters, roles, adminFilter]);

    function onPageChange(pageNumber: number): void
    {
        dispatch(getOffUser({
            pageNumber,
            entriesPerPage,
            ...{ ...filters, role: roles, ...adminFilter },
        }));
    }

    const getStatusColumn = (data: User): React.ReactNode =>
    {
        const textStatus: string = data.enabled
            ? t('common.status.active')
            : t('common.status.inactive');

        return (
            <Box
                sx={{
                    textTransform: 'capitalize',
                    color: (theme) =>
                        data.enabled
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                }}
            >
                {textStatus}
            </Box>
        );
    };
    const getActionColumn = (data: User): React.ReactNode => (
        <IconButton
            aria-label="edit"
            onClick={() =>
            {
                if (onEdit.length)
                {
                    onEdit(data);
                }
                else
                {
                    dispatch(setUser(data));
                    navigate(
                        generatePath(USER_MANAGEMENT_EDIT, { id: data.id as string })
                    );
                }
            }
            }
        >
            <EditIcon />
        </IconButton>
    );
    const getConfigColumn = (user: User): React.ReactNode => (
        <Button onClick={() => onConfig(user)}>
            <BuildIcon />
        </Button>
    );
    const openAlertwordColumn = (user: Officer): React.ReactNode => (
        <Button onClick={() => onAlert(user)}>
            <ListAltIcon />
        </Button>
    );

    const columns: ColumnData<User>[] = [
        {
            label: t('user.firstname'),
            size: 3,
            value: (data: User) => data.firstName,
        },
        {
            label: t('user.lastname'),
            size: 3,
            value: (data: User) => data.lastName,
        },
        {
            label: t('user.email'),
            size: 3,
            value: (data: User) => data.email,
        },
        {
            label: t('user.role'),
            size: 2,
            value: (data: User) => data.role,
        },
        {
            label: t('user.status'),
            size: 2,
            value: (data: User) =>
                data.enabled
                    ? t('common.status.active')
                    : t('common.status.inactive'),
            format: (data: User) => getStatusColumn(data),
        },
        !(location.pathname === ALERT_WORDS_PAGE)&& {
            label: t('data-type.table.action'),
            size: 1,
            align: 'center' as const,
            format: (data: User) => getActionColumn(data),
        },

        !isRestricted && {
            label: t('common.nouns.configuration'),
            size: 1,
            align: 'center',
            format: (user: User) => getConfigColumn(user),
        },
        !isRestricted && {
            label: t('data-type.details.alert-words'),
            size: 1,
            align: 'center',
            format: (user: Officer) => openAlertwordColumn(user),
        },
    ].filter(Boolean) as ColumnData<User>[];
    return (

        <DataTable<User>
            isLoading={status === RequestStatus.InProgress}
            columns={columns}
            onPageChange={onPageChange}
            entriesPerPage={entriesPerPage}
            onChangeEntriesPerPage={setEntriesPerPage}
            {...users}
        />

    );
}
