import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import { PrintedDocumentData } from 'src/models/captures/printedDocumentData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    changeColumns,
    changeFilters,
    changeTableDataType,
    changeTotalRecord,
    getPrintedDocuments,
    selectFilters,
    selectPrintedDocumentDetails,
    selectPrintedDocuments,
    selectPrintedDocumentsStatus,
    selectallPageData,
    setPrintedDocumentDetails,
    updateSeenListStatus,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import {
    DataDetailsModal,
    DetailedItem,
} from '../data-type-detail/DataDetailModal';
import { PrintedDocumentDetail } from './PrintedDocumentDetail';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { CommentType } from 'src/models/commentType.enum';
import { checkFilterChange } from 'src/utils/checkFilterChange';

export function PrintedDocumentAlertsTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();
    const filters = useSelector(selectFilters);
    const printedDocumentsStatus = useSelector(selectPrintedDocumentsStatus);
    const printedDocumentsData = useSelector(selectPrintedDocuments);
    const printedDocumentDetails = useSelector(selectPrintedDocumentDetails);

    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [currentFilter, setCurrentFilter] = useState({});
    const [open, setOpen] = useState(false);
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const allPageData = useSelector(selectallPageData);
    // eslint-disable-next-line
    const [lastPage, setLastPage] = useState(0);
    const prevPage = useRef(1);
    const [datas, setDatas] = useState<string[] | undefined>(undefined);

    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };
    function onDetailButtonClick(printedDocumentData: PrintedDocumentData): void
    {
        openRecordData(printedDocumentData.id);

        dispatch(setPrintedDocumentDetails(printedDocumentData));

        if (!printedDocumentData.isSeen)
        {
            dispatch(updateSeenListStatus({
                detailsId: printedDocumentData.id,
                dataType: DataType.PRINTED_DOCS,
            }));
        }
        setOpen(true);
    }
    function onPageChange(pageNumber: number): void
    {
        if (printedDocumentsData && pageNumber!==lastPage &&
            (pageNumber === prevPage.current + 1 || pageNumber === prevPage.current - 1))
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(
                    printedDocumentsData?.contents[
                        printedDocumentsData.contents.length - 1
                    ]?.id
                );
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(Array.isArray(lastRecordDatas) && lastRecordDatas.length > 0 ?
                    lastRecordDatas[lastRecordDatas.length - 1]?.id : '');
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        prevPage.current = pageNumber;
    }

    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }
    function onCloseModalClick(): void
    {
        dispatch(setPrintedDocumentDetails(undefined));
    }


    useEffect(() =>
    {
        if(filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if(filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {

        const rowsPerPage = filters?.rowsPerPage ?? entriesPerPage;
        dispatch(getPrintedDocuments({
            skipRecordId,
            pageNumber: checkFilterChange(currentFilter, filters) ? currentPage : 1,
            entriesPerPage: rowsPerPage,
            ...filters,
        }));
        setCurrentFilter(filters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const getFlagColumn = (data: PrintedDocumentData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.PRINTED_DOCS}
            onChange={()=>
            {
                onPageChange(printedDocumentsData?.currentPage ?? 1);
            }}
        />
    );
    const openDetailColumn = (data: PrintedDocumentData): React.ReactNode => (
        <IconButton onClick={() => onDetailButtonClick(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: PrintedDocumentData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: PrintedDocumentData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: PrintedDocumentData) =>
                new Date(data.capturedDate).toLocaleString(),
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: PrintedDocumentData) =>
                new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: PrintedDocumentData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.printer-name'),
            size: 7,
            value: (data: PrintedDocumentData) => data.printerName,
            sortByType: 'printerName',
        },
        {
            label: t('data-type.table.alert-word'),
            size: 2,
            value: (data: PrintedDocumentData) => data.alertWords?.join(', '),
            sortByType: 'alertWords',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: PrintedDocumentData) => openDetailColumn(data),
        },
    ];

    const detailItems: DetailedItem<PrintedDocumentData>[] = columns
        .filter((column) => column.value)
        .map(({ label, value }) => ({
            label,
            text: value,
        }));

    useEffect(() =>
    {
        if (printedDocumentsData)
        {
            pdfExportSetter({
                type: DataType.PRINTED_DOCS,
                recordIds: printedDocumentsData.contents.map((item) => item.id),
                sortBy: filters.sortBy,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(printedDocumentsData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.PRINTED_DOCS)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printedDocumentsData, pdfExportSetter]);

    useEffect(() =>
    {
        if(filters)
        {
            setCurrentPage(1);
        }
    }, [filters]);

    return (
        <>
            {printedDocumentDetails ? (
                <DataDetailsModal<PrintedDocumentData>
                    title={t('data-type.details.printed-documents-title')}
                    open={open}
                    data={printedDocumentDetails}
                    detailItems={detailItems}
                    onClose={onCloseModalClick}

                    isLoading={printedDocumentsStatus === RequestStatus.InProgress}
                    comments={{
                        contentId: printedDocumentDetails?.id,
                        type: CommentType.PRINTED_DOCS,
                    }}
                >
                    <PrintedDocumentDetail fileUrl={printedDocumentDetails.fileUrl} />
                </DataDetailsModal>
            ) : null}
            {useDesktopScreen() ? (
                <DataTable<PrintedDocumentData>
                    isLoading={printedDocumentsStatus === RequestStatus.InProgress}
                    columns={columns}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    entriesPerPage={entriesPerPage}
                    datas={datas}
                    {...printedDocumentsData}
                />
            ) : (
                <DataTableMobile
                    isLoading={printedDocumentsStatus === RequestStatus.InProgress}
                    contents={printedDocumentsData?.contents || []}
                    setOpenModal={onDetailButtonClick}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    numberOfEntries={printedDocumentsData?.numberOfEntries}
                    currentPage={printedDocumentsData?.currentPage}
                    enablePagination
                />
            )}
        </>
    );
}
