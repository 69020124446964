import {
    Report,
    ReportParams,
    ReportFileParams,
    ReportsListFileResponse,
} from 'src/models/administration/reportData.model';
import { GenerateReportParams } from 'src/models/captures/report.model';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import {
    readAPIReportFilesResponse,
    readAPIReportResponse,
    transformAPIRequest,
    transformReportParams,
}
    from '../data-transfer-objects/administration/reports';
import { transformGenerateReportParams } from '../data-transfer-objects/capture/reports';
import { readAPIPaginatedResponse } from '../data-transfer-objects/pagination';
import instance from '../instance';
import { CaptureParams } from 'src/models/captures/capturedData.model';
import { CallHistoryData } from 'src/models/captures/callHistoryData.model';
import { transformCaptureParams } from '../data-transfer-objects/capture/captures';
import { DataType } from 'src/models/dataType.enum';
import { readAPIEmailResponse } from '../data-transfer-objects/capture/emails';
import { readAPIFilesTrackingResponse } from '../data-transfer-objects/capture/filesTracking';
import { readAPIGeolocationResponse } from '../data-transfer-objects/capture/geolocation';
import { readAPILogonEventResponse } from '../data-transfer-objects/capture/logonEvents';
import { readAPIWebsitesResponse } from '../data-transfer-objects/capture/websites';
import { readAPISearchesResponse } from '../data-transfer-objects/capture/searches';
import { readAPIExternalDeviceResponse } from '../data-transfer-objects/capture/externalDevices';
import { readAPIProcessesResponse } from '../data-transfer-objects/capture/process';
import { readAPIScreenshotResponse } from '../data-transfer-objects/capture/photos';
import {
    readAPIInstalledProgramsResponse,
} from '../data-transfer-objects/capture/installedPrograms';
import { readAPITextSMSResponse } from '../data-transfer-objects/capture/textSMS';
import { readAPIScreenCaptureResponse } from '../data-transfer-objects/capture/screenCaptures';
import { EmailData } from 'src/models/captures/emailData.model';
import { FilesTrackingData } from 'src/models/captures/filesTrackingData.models';
import { GeolocationData } from 'src/models/captures/geolocationData.model';
import { LogonEventData } from 'src/models/captures/logonEventData.model';
import { WebsiteData } from 'src/models/captures/websiteData.model';
import { SearchData } from 'src/models/captures/searchData.model';
import { ExternalDeviceData } from 'src/models/captures/externalDeviceData.model';
import { ProcessData } from 'src/models/captures/processData.model';
import { PhotoData } from 'src/models/captures/photoData.model';
import { InstalledProgramsData } from 'src/models/captures/installedProgramsData.model';
import { TextSMSData } from 'src/models/captures/textSMSData.model';
import { ScreenshotData } from 'src/models/captures/screenshotData.model';
import { KeystrokeData } from 'src/models/captures/keystrokeData.model';
import { readAPICallHistoryResponse } from '../data-transfer-objects/capture/callHistory';
import { readAPIKeystrokeResponse } from '../data-transfer-objects/capture/keystrokes';
import { readAPIPrintedDocumentsResponse } from '../data-transfer-objects/capture/printedDocuments';
import { PrintedDocumentData } from 'src/models/captures/printedDocumentData.model';
import { readAPIAlertsResponse } from '../data-transfer-objects/capture/alerts';
import { AlertsData } from 'src/models/captures/alertsData.model';
import { readAPIScreenshotmetaResponse } from '../data-transfer-objects/capture/screenshots';
import {
    readAPIDeviceDetailsResponse,
} from '../data-transfer-objects/administration/deviceDetails';
import { Client } from 'src/models/administration/clientData.model';
import { readAPIClientResponse } from '../data-transfer-objects/administration/clients';
import { User } from 'src/models/userModels';
import { readAPIUserResponse } from '../data-transfer-objects/administration/users';
import { Device } from 'src/models/administration/deviceData.model';
import { readAPIDeviceResponse } from '../data-transfer-objects/administration/devices';

export async function getReports(params: ReportParams): Promise<PaginatedContent<Report>>
{
    const response = await instance.get(
        'report',
        { params: transformReportParams(params) }
    );

    return readAPIPaginatedResponse(response.data, readAPIReportResponse);
}

export async function getReport(reportId: string): Promise<Report>
{
    const response = await instance.get(`report/${reportId}`);

    return readAPIReportResponse(response.data);
}

export async function getReportFiles(
    params: ReportFileParams
)
: Promise<PaginatedContent<ReportsListFileResponse>>
{
    const response = await instance.get(
        `report/${params.id}/files`,
        { params: transformReportParams(params) }
    );

    return readAPIPaginatedResponse(response.data, readAPIReportFilesResponse);
}

export async function createReport(data: Report): Promise<Report>
{
    const response = await instance.post(
        'report',
        transformAPIRequest(data)
    );

    return readAPIReportResponse(response.data);
}

export async function updateReport(data: Report): Promise<Report>
{
    const response = await instance.patch(
        `report/${data.id}`,
        transformAPIRequest(data)
    );

    return readAPIReportResponse(response.data);
}

export async function deleteReport(id: string): Promise<void>
{
    const response = await instance.post(
        `report/delete/${id}`
    );
    return response.data;
}

export async function generateReport(params: GenerateReportParams): Promise<Blob>
{

    const response = await instance.patch(
        '/report/generate',
        transformGenerateReportParams(params),
        { responseType: 'arraybuffer' }
    );

    return new Blob([response.data], { type: 'application/pdf' });
}

export async function generateReportWithXLSXImages(params: GenerateReportParams): Promise<Blob>
{

    const response = await instance.patch(
        '/report/generate/xlsx',
        transformGenerateReportParams(params),
        { responseType: 'arraybuffer' }
    );

    return new Blob(
        [response.data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
    );
}

export async function getAllToExportData<T>(
    captureParams: CaptureParams
    // eslint-disable-next-line
): Promise<PaginatedContent<any>>
{
    const response = await instance.get(
        'report/export/getExportRecords',
        { params: transformCaptureParams(captureParams) }
    );
    // eslint-disable-next-line
    let apiReadResponse: (data: any) => T;

    switch (captureParams.dataType)
    {
    case DataType.CALLS:
        apiReadResponse = readAPICallHistoryResponse as unknown as (data: CallHistoryData) => T;
        break;
    case DataType.EMAILS:
        apiReadResponse = readAPIEmailResponse as unknown as (data: EmailData) => T;
        break;
    case DataType.FILES_TRACKING:
        apiReadResponse = readAPIFilesTrackingResponse as unknown as (data: FilesTrackingData) => T;
        break;
    case DataType.GEOLOCATION:
        apiReadResponse = readAPIGeolocationResponse as unknown as (data: GeolocationData) => T;
        break;
    case DataType.LOGON_EVENTS:
        apiReadResponse = readAPILogonEventResponse as unknown as (data: LogonEventData) => T;
        break;
    case DataType.WEBSITES:
        apiReadResponse = readAPIWebsitesResponse as unknown as (data: WebsiteData) => T;
        break;
    case DataType.WEBSITES_SEARCH:
        apiReadResponse = readAPISearchesResponse as unknown as (data: SearchData) => T;
        break;
    case DataType.EXTERNAL_MEDIA:
        apiReadResponse =
            readAPIExternalDeviceResponse as unknown as (data: ExternalDeviceData) => T;
        break;
    case DataType.PROCESSES:
        apiReadResponse = readAPIProcessesResponse as unknown as (data: ProcessData) => T;
        break;
    case DataType.PHOTOS:
        apiReadResponse = readAPIScreenshotResponse as unknown as (data: PhotoData) => T;
        break;
    case DataType.PROGRAMS:
        apiReadResponse =
            readAPIInstalledProgramsResponse as unknown as (data: InstalledProgramsData) => T;
        break;
    case DataType.SMS:
        apiReadResponse = readAPITextSMSResponse as unknown as (data: TextSMSData) => T;
        break;
    case DataType.MMS:
        apiReadResponse = readAPITextSMSResponse as unknown as (data: TextSMSData) => T;
        break;
    case DataType.SCREENSHOTS:
        apiReadResponse = readAPIScreenshotResponse as unknown as (data: ScreenshotData) => T;
        break;
    case DataType.SCREEN_CAPTURES:
        apiReadResponse = readAPIScreenCaptureResponse as unknown as (data: ScreenshotData) => T;
        break;
    case DataType.SCREENSHOTS_META:
        apiReadResponse = readAPIScreenshotmetaResponse as unknown as (data: ScreenshotData) => T;
        break;
    case DataType.PRINTED_DOCS:
        apiReadResponse =
            readAPIPrintedDocumentsResponse as unknown as (data: PrintedDocumentData) => T;
        break;
    case DataType.ALERTS:
        apiReadResponse = readAPIAlertsResponse as unknown as (data: AlertsData) => T;
        break;
    case DataType.DEVICE_INFORMATION:
        apiReadResponse = readAPIDeviceDetailsResponse as unknown as (data: AlertsData) => T;
        break;
    case DataType.AGENCY_OFFICER:
        apiReadResponse = readAPIUserResponse as unknown as (data: User) => T;
        break;
    case DataType.AGENCY_CLIENT:
        apiReadResponse = readAPIClientResponse as unknown as (data: Client) => T;
        break;
    case DataType.AGENCY_DEVICES:
        apiReadResponse = readAPIDeviceResponse as unknown as (data: Device) => T;
        break;
    case DataType.CLIENT_DEVICES:
        apiReadResponse = readAPIDeviceResponse as unknown as (data: Device) => T;
        break;
    default:
        apiReadResponse = readAPIKeystrokeResponse as unknown as (data: KeystrokeData) => T;
    }
    return readAPIPaginatedResponse(response.data, apiReadResponse);
}
