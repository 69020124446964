import React, { useState, useEffect } from 'react';
import { Snackbar, Alert, SnackbarProps, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { noop } from 'src/utils/common';
import {
    deleteInstructions,
    selectInstructiondeleteStatus,
    resetDeleteInstructionStatus,
} from 'src/state/administration/administrationSlice';
import { RequestStatus } from 'src/models/requestStatus.enum';
import ConfirmationPanel from 'src/templates/confirmation-panel/ConfirmationPanel';
import { Modal } from 'src/components/modal/Modal';
interface instructionConfirmationFormProps {
    id?: string;
    DeleteModal?: () => void;
    open: boolean
    type:string
}

function InstructionDelete({
    id,
    DeleteModal = noop,
    open,
    type,
}: Readonly<instructionConfirmationFormProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [notificationState, setNotificationState] = useState<SnackbarProps>();
    const deleteStatus = useSelector(selectInstructiondeleteStatus);
    async function DeleteSubmit(data?: string): Promise<void>
    {
        if (id)
        {
            dispatch(deleteInstructions(id));
            // setFormValues(initialValues);
            setTimeout(() =>
            {
                dispatch(resetDeleteInstructionStatus());
                DeleteModal();
            }, 3000);
        }
    }

    function onCloseNotification(): void
    {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }
    useEffect(() =>
    {

        let message = '';

        if (deleteStatus === RequestStatus.Success)
        {
            message = t('user.instruction-deleted');
        }
        else if (deleteStatus === RequestStatus.Failure)
        {
            message = t('common.messages.something-wrong');
        }
        if (message)
        {

            setNotificationState({ open: true, message });
        }

    }, [deleteStatus, setNotificationState, t, dispatch]);

    return (
        <>
            <Modal
                title={t('menu.delete-instruction')}
                open={open}
                maxWidth="md"
                fullWidth
                onClose={DeleteModal}
                showCloseButton={true}
            >
                <Box>
                    <ConfirmationPanel
                        data={type}
                        DeleteModal={DeleteModal}
                        DeleteSubmit={DeleteSubmit}
                        type={'Instruction'}
                    />
                </Box>
            </Modal>

            <Snackbar
                autoHideDuration={6000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert
                    severity={deleteStatus === RequestStatus.Failure ? 'error' : 'success'}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default InstructionDelete;
