import React, { Dispatch, SetStateAction } from 'react';
import { ColumnData } from 'src/components/data-table/DataTable';
import ModalExport from 'src/components/export-modal/ExportModal';
import {
    TableExportFormat, usePDFExport,
} from 'src/components/table-exporter/TableExporterProvider';
import { ALERTSPAGE } from 'src/models/alertsType.enum';
import { CaptureFilters } from 'src/models/captures/capturedData.model';
import { GenerateReportParams } from 'src/models/captures/report.model';
import { DataType } from 'src/models/dataType.enum';
import { PaginatedContent } from 'src/models/paginatedResult.model';
import { noop, processAndGenerateXlsx } from 'src/utils/common';
import { xlsxImageDatatypes } from 'src/utils/reportsUtils';

function exportData(
    columns: ColumnData<DataType>[],
    selection: number[],
    format: TableExportFormat,
    filters:CaptureFilters | undefined,
    totalRecordCount:number|undefined,
    dataType: DataType|undefined,
    exportParams: GenerateReportParams,
    isGeolocation?:boolean
): void
{
    const selectedColumns = columns.filter((_, index) => selection.includes(index));
    switch (format)
    {
    case TableExportFormat.ALLXLSX:
        processAndGenerateXlsx(
            totalRecordCount,
            dataType,
            filters,
            selectedColumns,
            exportParams,
            isGeolocation
        );
        break;
    default:
        // eslint-disable-next-line no-console
        console.error('Not supported.');
    }
}

export interface ColumnSelectorÁllProps<T> {
    format: TableExportFormat;
    columns: ColumnData<DataType>[];
    data: PaginatedContent<T>;
    onClose?: () => void;
    filename?: string;
    filters:CaptureFilters | undefined;
    setAllToXlsx?:Dispatch<SetStateAction<boolean>>
    totalRecordCount :number|undefined
    dataType:DataType|undefined,
    isGeolocation?:boolean,
}

export function ColumnSelectorAll<T>({
    format,
    columns,
    data,
    onClose = noop,
    filename = 'export',
    filters,
    totalRecordCount,
    setAllToXlsx,
    dataType,
    isGeolocation,
}: Readonly<ColumnSelectorÁllProps<T>>): React.ReactElement
{

    const exportParams = usePDFExport();
    const exportableColumns = columns.filter((column) => column.value);
    const isAlertsPage = window.location.pathname === ALERTSPAGE;
    const alertFilter = JSON.parse(sessionStorage.getItem('AlertsFilter') as string);

    if (
        format !== TableExportFormat.CSV &&
        (
            (xlsxImageDatatypes.includes(exportParams?.type as DataType) && !isAlertsPage) ||
            alertFilter?.alertsdatatype === DataType.SMS
        )
    )
    {
        exportableColumns.push({ label: 'Image' });
    }

    const options = exportableColumns.map((column, index) => ({
        label: column.label,
        value: index,
        checked: true,
    }));
    const onCloseModal=():void=>
    {
        if(setAllToXlsx)
        {
            setAllToXlsx(false);
        }
    };
    return (
        <ModalExport
            options={options}
            open={true}
            onExport={(selection) =>
            {
                exportData(
                    exportableColumns,
                    selection,
                    format,
                    filters,
                    totalRecordCount,
                    dataType,
                    exportParams as GenerateReportParams,
                    isGeolocation
                );
                onCloseModal();
            } }
            onCloseRequest={onCloseModal}
            format={format}
        />
    );
}
