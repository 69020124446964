import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FilesTrackingData } from 'src/models/captures/filesTrackingData.models';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getFilesTracking,
    getFileTrackingDetails,
    selectFilters,
    selectFilesTracking,
    selectFilesTrackingStatus,
    selectFileTrackingDetails,
    selectFileTrackingDetailsStatus,
    updateSeenListStatus,
    selectallPageData,
    changeFilters,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import { IconButton } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { CommentType } from 'src/models/commentType.enum';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { USER_TIME_ZONE } from 'src/utils/environment';
import { timeZoneFormat } from 'src/utils/dateUtils';
export function FilesTrackingAlertsTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();
    const filters = useSelector(selectFilters);
    const filesTrackingStatus = useSelector(selectFilesTrackingStatus);
    const filesTrackingData = useSelector(selectFilesTracking);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const allPageData = useSelector(selectallPageData);
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const prevPage = useRef(1);
    const [lastPage, setLastPage] = useState(0);
    const [currentFilter, setCurrentFilter] = useState({});

    const filesTrackingDetailsStatus = useSelector(
        selectFileTrackingDetailsStatus
    );
    const filesTrackingDetailsData = useSelector(selectFileTrackingDetails);

    const [datas, setDatas] = useState<string[] | undefined>(undefined);

    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };
    const handleOpen = (filestrackingId: FilesTrackingData): void =>
    {
        openRecordData(filestrackingId.id);

        dispatch(getFileTrackingDetails(filestrackingId.id));

        if (!filestrackingId.isSeen)
        {
            dispatch(updateSeenListStatus({
                detailsId: filestrackingId.id,
                dataType: DataType.FILES_TRACKING,
            }));
        }
        setShowModal(true);
    };
    function onPageChange(pageNumber: number): void
    {
        if (filesTrackingData && pageNumber !== lastPage &&
            (pageNumber === prevPage.current + 1 ||
                pageNumber === prevPage.current - 1))
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(filesTrackingData?.contents[
                    filesTrackingData.contents.length - 1]?.id);
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(Array.isArray(lastRecordDatas) &&
                    lastRecordDatas.length > 0 ?
                    lastRecordDatas[lastRecordDatas.length - 1]?.id : '');
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        prevPage.current = pageNumber;
    }
    useEffect(() =>
    {
        if(filters)
        {
            setCurrentPage(1);
        }
    }, [filters]);
    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }

    useEffect(() =>
    {
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);
        }

        if (filters)
        {
            setCurrentFilter(filters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const rowsPerPage = filters?.rowsPerPage !== undefined ?
            filters.rowsPerPage :
            entriesPerPage;

        dispatch(getFilesTracking({
            skipRecordId,
            pageNumber: checkFilterChange(currentFilter, filters) ?
                currentPage : 1,
            entriesPerPage: rowsPerPage,
            ...filters,
        }));
        setCurrentFilter(filters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const getFlagColumn = (data: FilesTrackingData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.FILES_TRACKING}
            onChange={() =>
            {
                onPageChange(filesTrackingData?.currentPage ?? 1);
            }}
        />
    );
    const openDetailColumn = (data: FilesTrackingData): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value:
                ({ flag }: FilesTrackingData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: FilesTrackingData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: FilesTrackingData) =>
                new Date(data.capturedDate).toLocaleString(),
            sortByType: 'clientCreatedAt',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: FilesTrackingData) =>
                new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: FilesTrackingData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.device-name'),
            size: 3,
            value: (data: FilesTrackingData) => data.deviceName,
            sortByType: 'deviceName',
        },
        {
            label: t('data-type.table.device-type'),
            size: 2,
            value: (data: FilesTrackingData) => data.deviceClass,
            sortByType: 'deviceClass',
        },
        {
            label: t('data-type.table.activity'),
            size: 3,
            value: (data: FilesTrackingData) => data.activity,
            sortByType: 'activity',
        },
        {
            label: t('data-type.table.file-name'),
            size: 3,
            value: (data: FilesTrackingData) => data.fileName,
            sortByType: 'fileName',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: FilesTrackingData) => openDetailColumn(data),
        },
    ];

    const detailItems = [
        {
            label: t('data-type.details.received-date'),
            text: (data: FilesTrackingData) => timeZoneFormat(
                new Date(data.receivedDate),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.details.captured-date'),
            text: (data: FilesTrackingData) => timeZoneFormat(
                new Date(data.capturedDate),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: FilesTrackingData) => data.device,
        },
        {
            label: t('data-type.table.device-type'),
            text: (data: FilesTrackingData) => data.deviceClass,
        },
        {
            label: t('data-type.table.activity'),
            text: (data: FilesTrackingData) => data.activity,
        },
        {
            label: t('data-type.table.file-name'),
            text: (data: FilesTrackingData) => data.fileName,
        },
    ];
    useEffect(() =>
    {

        if (filesTrackingData)
        {
            setLastPage(Math.floor(
                filesTrackingData.numberOfEntries / entriesPerPage
            ));
            pdfExportSetter({
                type: DataType.FILES_TRACKING,
                recordIds: filesTrackingData.contents.map((item) => item.id),
                sortBy: filters.sortBy,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(filesTrackingData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.FILES_TRACKING)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesTrackingData, pdfExportSetter]);

    return (

        <>
            <DataDetailsModal<FilesTrackingData>
                title={t('data-type.details.files-tracking')}
                open={showModal}
                data={filesTrackingDetailsData}
                detailItems={detailItems}
                isLoading={filesTrackingDetailsStatus
                    === RequestStatus.InProgress}
                onClose={() => setShowModal(false)}
                comments={{
                    contentId: filesTrackingDetailsData?.id,
                    type: CommentType.FILES_TRACKING,
                }}
            />

            {useDesktopScreen() ? (
                <DataTable<FilesTrackingData>
                    isLoading={filesTrackingStatus
                        === RequestStatus.InProgress}
                    columns={columns}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    datas={datas}
                    {...filesTrackingData}
                />

            ) : (
                <DataTableMobile
                    isLoading={filesTrackingStatus === RequestStatus.InProgress}
                    contents={filesTrackingData?.contents || []}
                    setOpenModal={handleOpen}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber) =>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage) =>
                    {
                        updateEntriesPerPage(entriesPerPage);
                    }}
                    numberOfEntries={filesTrackingData?.numberOfEntries}
                    currentPage={filesTrackingData?.currentPage}
                    enablePagination
                />
            )}
        </>
    );
}
