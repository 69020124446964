import React, { useEffect } from 'react';
import { Header } from 'src/templates/header/Header';
import { TopBar } from 'src/components/top-bar/TopBar';
import {
    Box,
    Button,
    Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { AWLContext } from 'src/templates/awl-creation-tabs/AWLCreationTabs';
import { Role } from 'src/models/roles.enum';
import { selectRole } from 'src/state/authorization/authorizationSlice';
import { AWLTableContainerProps } from 'src/templates/awl-table-container/AWLTableContainer';
import {
    AgencyAWLConfigurationContextProvider,
    useGetConfiguration,
} from 'src/templates/awl-index-tabs/AgencyAWLConfigurationContext';
import { ProfilesTable } from 'src/templates/profiles-table/ProfilesTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AWL_SUGGESTED_NEW, INSTRUCTION_CREATE, USER_MANAGEMENT_CREATE } from 'src/utils/routes';
import { SuggestedAWLTable } from 'src/templates/suggested-awl-table/SuggestedAWLTable';
import {
    AdministrationSettingsSelect,
} from 'src/templates/administration-settings-select/AdministrationSettingsSelect';
import { AdminSettings } from 'src/models/adminSettings.enum';
import {
    selectAdminSettingsMenu,
    resetProfileErrorValue,
    resetAddInstructionStatus,
    resetProfileStatus,
} from 'src/state/administration/administrationSlice';
import { UsersTable } from 'src/templates/users-table/UsersTable';
import { UserAgents } from 'src/templates/user-agents/UserAgents';
import { VersionManagement } from 'src/templates/version-management/VersionManagement';
import { Extensions } from 'src/templates/extension-page/Extension';
import Grid from '@mui/material/Grid';
import {
    AdminSettingSearchFilter,
} from 'src/templates/admin-setting-search-filter/AdminSettingSearchBar';
import {
    DeviceConfigurationManagement, ServiceConfigurationItem,
} from 'src/templates/device-configuration-management/DeviceConfigurationManagement';
import { User } from 'src/models/userModels';
import { Officer } from 'src/models/administration/officerData.model';
import { INITIALCONFIGURATION_ID } from 'src/utils/environment';
import { Modal } from 'src/components/modal/Modal';
import { NewProfile } from '../profiles/new-profile/NewProfile';
import { InstructionTable } from 'src/templates/instruction/InstructionTable';
import { DeviceInformationPage } from 'src/templates/device-information/DeviceInformationPage';
import { DeviceCacheManagement } from 'src/templates/device-cache-management/DeviceCacheManagement';
import { CreateButton } from 'src/components/top-bar/CreateButton';
import { splitPascalCase } from 'src/utils/common';
import TableExporterProvider from 'src/components/table-exporter/TableExporterProvider';

export const initialConfigurations:ServiceConfigurationItem = {
    label: '',
    override: false,
    app: '',
    captureInterval: 0,
    syncInterval: 0,
    syncRate: 0,
    enabled: false,
    id: INITIALCONFIGURATION_ID,
};
export interface UserTableProps {
    roles?: Role[]
    onEdit?: (user: User) => void
    onConfig?: (user: User) => void
    onAlert?: (user: Officer) => void
}
type AgencyAlertWordsParams = {
    id: string;
}

export function AdminSettingsManagement(): JSX.Element
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const adminSettingMenu = useSelector(selectAdminSettingsMenu);
    const { id } = useParams<AgencyAlertWordsParams>();
    const role = useSelector(selectRole);
    const getConfiguration = useGetConfiguration();
    const context = AWLContext.Suggested;
    const [open, setOpen] = React.useState(false);

    const tableContainerProps: AWLTableContainerProps<typeof context> = {
        type: context,
    };
    useEffect(() =>
    {
        if (id)
        {
            dispatch(getConfiguration(id));
        }
    }, [dispatch, getConfiguration, id]);

    if(role)
    {
        tableContainerProps.canAdd = [
            Role.Administrator,
            Role.Employee,
        ].includes(role);
        tableContainerProps.tableProps = {
            editable: role === Role.Administrator,
            toggleable: role === Role.OfficerSupervisor,
        };
    }

    function closeModal(): void
    {
        setOpen(false);
    }
    return (
        <>
            <Header />
            <Box m={4} >
                <TopBar
                    labels={[
                        t('menu.settings'),
                        t('menu.admin-settings'),
                        adminSettingMenu !== undefined
                            ? splitPascalCase(AdminSettings[adminSettingMenu])
                            :'',
                    ]}
                    icon={<SettingsRoundedIcon fontSize="large"/>}
                />
                <Box sx={{ width: '20em', mb: 4 }}>
                    <AdministrationSettingsSelect />
                </Box>
                {
                    (adminSettingMenu === AdminSettings.SuggestedAlertWords) && (
                        <AgencyAWLConfigurationContextProvider>

                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                            >
                                <Box sx={{ mt: 2.5, mr: 3 }} >
                                    <Grid
                                        item
                                        md={3}
                                        sm={12}
                                        xs={12}
                                    >
                                        <AdminSettingSearchFilter/>
                                    </Grid>
                                </Box>
                                <TopBar
                                    buttons={[
                                        {
                                            text: (
                                                <CreateButton
                                                    width="140px"
                                                    label={t('alert-word.Add-new-SAW')}
                                                />
                                            ),
                                            icon: <AddCircleIcon />,
                                            onClick: () => navigate(AWL_SUGGESTED_NEW),
                                        },
                                    ]}
                                />
                            </Stack>

                            <SuggestedAWLTable {...tableContainerProps.tableProps} />
                        </AgencyAWLConfigurationContextProvider>
                    ) }
                {
                    (adminSettingMenu === AdminSettings.ProfileManagement) && (
                        <>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                padding={'1em 0'}
                                margin={'-2em 0em 0em 0em'}
                            >

                                <Box sx={{ mr: 3 }}>
                                    <Grid
                                        item
                                        md={3}
                                        sm={12}
                                        xs={12}
                                    >
                                        <AdminSettingSearchFilter/>
                                    </Grid>
                                </Box>

                                <Button
                                    startIcon={<AddCircleIcon />}
                                    onClick={() =>
                                    {
                                        setOpen(true);
                                        dispatch(resetProfileErrorValue());
                                        dispatch(resetProfileStatus());
                                    }
                                    }
                                >
                                    {t('profiles.new-profile')}
                                </Button>
                            </Stack>
                            <ProfilesTable />
                            <Modal
                                title={t('profiles.new-profile')}
                                open={open}
                                onClose={closeModal}
                                showCloseButton={true}
                                maxWidth="md"
                                fullWidth
                            >
                                <Box>
                                    <NewProfile onCloseModal={closeModal} />
                                </Box>
                            </Modal>
                        </>
                    )
                }
                {
                    (adminSettingMenu === AdminSettings.Instruction) && (
                        <>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                            >
                                <Box sx={{ mt: 2.5, mr: 3 }}>
                                    <Grid
                                        item
                                        md={3}
                                        sm={12}
                                        xs={12}
                                    >
                                        <AdminSettingSearchFilter/>
                                    </Grid>
                                </Box>
                                <TopBar
                                    buttons={[
                                        {
                                            text: (
                                                <CreateButton
                                                    width="140px"
                                                    label={t('menu.create-new-instruction')}
                                                />
                                            ),
                                            icon: <AddCircleIcon />,
                                            onClick: () =>
                                            {
                                                navigate(INSTRUCTION_CREATE);
                                                dispatch(resetAddInstructionStatus());
                                            },
                                        },
                                    ]}
                                />
                            </Stack>

                            <InstructionTable />
                        </>
                    )
                }
                {
                    (adminSettingMenu === AdminSettings.Users) && (
                        <>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                            >
                                <Box sx={{ mt: 2.5, mr: 3 }}>
                                    <Grid
                                        item
                                        md={3}
                                        sm={12}
                                        xs={12}
                                    >
                                        <AdminSettingSearchFilter/>
                                    </Grid>
                                </Box>
                                <TopBar
                                    buttons={[
                                        {
                                            text: (
                                                <CreateButton
                                                    width="140px"
                                                    label={t('user.create-new-user')}
                                                />
                                            ),
                                            icon: <AddCircleIcon />,
                                            onClick: () => navigate(USER_MANAGEMENT_CREATE),
                                        },
                                    ]}
                                />
                            </Stack>

                            <UsersTable />
                        </>
                    )
                }
                {
                    (adminSettingMenu === AdminSettings.AgentManagement) && (
                        <>

                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                padding={'1em 0'}
                            >
                                <Box sx={{}}>
                                    <Grid
                                        item
                                        md={3}
                                        sm={12}
                                        xs={12}
                                    >
                                        <AdminSettingSearchFilter/>
                                    </Grid>
                                </Box>
                            </Stack>
                            <UserAgents />
                        </>
                    )
                }
                {
                    (adminSettingMenu === AdminSettings.VersionManagement) && (
                        <VersionManagement />
                    )}
                {(adminSettingMenu ===
                    AdminSettings.DeviceConfigurationManagement )&& (
                    <>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            padding={'1em 0'}
                        >
                            <Box>
                                <Grid
                                    item
                                    md={3}
                                    sm={12}
                                    xs={12}
                                />
                            </Box>
                        </Stack>
                        <DeviceConfigurationManagement
                            configuration={initialConfigurations}
                            disableConfig={false}
                        />
                        {' '}
                    </>
                )}
                {
                    (adminSettingMenu === AdminSettings.Extensions) && (
                        <>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                padding={'1em 0'}
                            >
                                <Box
                                    sx={{ mr: 22, mt: 2 }}
                                >
                                    <Grid
                                        item
                                        md={3}
                                        sm={12}
                                        xs={12}
                                    >
                                        <AdminSettingSearchFilter/>
                                    </Grid>
                                </Box>

                            </Stack>
                            <Extensions />
                        </>
                    )
                }
                {
                    (adminSettingMenu === AdminSettings.DeviceInformation) && (
                        <DeviceInformationPage />
                    )
                }
                {
                    (adminSettingMenu === AdminSettings.DeviceCacheManagement) && (
                        <TableExporterProvider>
                            <DeviceCacheManagement />
                        </TableExporterProvider>
                    )
                }
            </Box>
        </>
    );
}
