import { Box, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnData, DataTable } from 'src/components/data-table/DataTable';
import { OfficerAlertWordsList } from 'src/models/administration/alertWordListData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getOfficerAWLs,
    resetOfficerAWLRetrieveStatus,
    selectOfficerAWLRetrieveStatus,
    selectOfficerAWLs,
} from 'src/state/administration/administrationSlice';
import EditIcon from '@mui/icons-material/Edit';
import { noop } from 'src/utils/common';
import { ToggleSwitch } from 'src/components/toggle-switch/ToggleSwitch';

export interface OfficerAWLTableProps {
    userId?: string;
    selectIsModalEdit?: (setid: string) => void;
    isAddButtonDisabled: boolean;
}

export function OfficerAWLTable({
    userId,
    selectIsModalEdit = noop,
    isAddButtonDisabled,
} : Readonly<OfficerAWLTableProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const officerAWLs = useSelector(selectOfficerAWLs);
    const status = useSelector(selectOfficerAWLRetrieveStatus);
    const [entriesPerPage, setEntriesPerPage] = useState(10);

    const getEnabledColumn = (isEnabled: boolean): React.ReactNode => (
        <ToggleSwitch
            checked={isEnabled}
            disabled
        />
    );
    const getEditColumn = (id: string): React.ReactNode => (
        <Button
            startIcon={<EditIcon />}
            onClick={() => selectIsModalEdit(id)}
            disabled={isAddButtonDisabled}
        />
    );

    const columns: ColumnData<OfficerAlertWordsList>[] = [
        {
            label: t('common.nouns.name'),
            size: 30,
            value: (alertWordsList: OfficerAlertWordsList) => alertWordsList.name,
        },
        {
            label: t('data-type.table.date-of-creation'),
            size: 30,
            value: (alertWordsList: OfficerAlertWordsList) => alertWordsList.createdAt.toString(),
        },
        {
            label: t('common.nouns.enabled'),
            size: 10,
            format: ({ isEnabled }) => getEnabledColumn(isEnabled),
        },
        {
            label: t('common.actions.edit'),
            size: 10,
            format: ({ id }) => getEditColumn(id),
        },
    ];

    function onPageChange(pageNumber: number, entriesPerPage: number): void
    {
        dispatch(getOfficerAWLs({ pageNumber, entriesPerPage, userId }));
    }

    function onChangeEntriesPerPage(entriesPerPage: number): void
    {
        dispatch(getOfficerAWLs({ pageNumber: 1, entriesPerPage, userId }));
        setEntriesPerPage(entriesPerPage);
    }

    function renderBody(): React.ReactElement
    {
        if ([status].includes(RequestStatus.InProgress))
        {
            return <CircularProgress />;
        }
        else if (status === RequestStatus.Failure)
        {
            return (
                <div>
                    {t('common.messages.something-wrong')}
                </div>
            );
        }
        else
        {
            return (
                <DataTable<OfficerAlertWordsList>
                    isLoading={status === RequestStatus.InProgress}
                    columns={columns}
                    onPageChange={onPageChange}
                    entriesPerPage={entriesPerPage}
                    onChangeEntriesPerPage={onChangeEntriesPerPage}
                    {...officerAWLs}
                />
            );
        }
    }

    useEffect(() =>
    {
        dispatch(getOfficerAWLs({ pageNumber: 1, entriesPerPage: 10, userId }));
        return () =>
        {
            dispatch(resetOfficerAWLRetrieveStatus);
        };
    }, [userId, dispatch]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems={[status].includes(RequestStatus.InProgress)? 'center':''}
        >
            {renderBody()}
        </Box>
    );
}
