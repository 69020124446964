import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ScreenCaptureData } from 'src/models/captures/screenCaptureData.model';
import {
    changeFilters,
    getAllToExportDatas,
    getScreenCaptures,
    getScreenCapturesJPEG,
    resetAlertsFilterData,
    resetscreenCapturesData,
    selectFilters,
    selectScreenCaptures,
    selectViewDataIdStatus,
    selectViewDataIds,
    selectViewDataJPEG,
    selectViewDataJPEGStatus,
} from 'src/state/captures/capturesSlice';
import GridView from 'src/components/grid-image-view';
import { DataType } from 'src/models/dataType.enum';
import {
    TableExportFormat,
    usePDFExport,
    usePDFExportSetter,
    useTableExporter,
} from 'src/components/table-exporter/TableExporterProvider';
import { Button, CircularProgress, Grid } from '@mui/material';
import { downloadImages } from 'src/utils/common';
import DownloadIcon from '@mui/icons-material/Download';
import TableExporter from 'src/components/table-exporter/TableExporter';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { PDFExport } from '../common-export-form/PDFExport';
import { JPEGExport } from '../common-export-form/JPEGExport';
import DataTypeUpdateFlagAll from '../data-type-update-flag-all/DatatypeUpdateFlagAll';

export default function ScreenCaptureAlertsGrid(
    { showDetails }: { readonly showDetails: (photoData: ScreenCaptureData) => void }
): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const screenCaptures = useSelector(selectScreenCaptures);
    const filters = useSelector(selectFilters);
    const pdfExportSetter = usePDFExportSetter();
    const pdfExporter = usePDFExport();
    const { setExportFormat } = useTableExporter();
    const [selectedImages, setSelectedImages] = React.useState<Array<ScreenCaptureData>>([]);
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
    const [selectLoading, setSelectLoading] = useState(false);
    const [format, setFormat] = useState<TableExportFormat | undefined>(undefined);
    const [formatAll, setFormatAll] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [pdfType, setPdfType] = React.useState('');
    const [alltoExport, setAlltoExport]=useState(false);
    const viewDataIds = useSelector(selectViewDataIds);
    const viewDataIdStatus = useSelector(selectViewDataIdStatus);
    const viewDataJPEG = useSelector(selectViewDataJPEG);
    const ViewDataJPEGstatus = useSelector(selectViewDataJPEGStatus);

    React.useEffect(() =>
    {
        dispatch(resetAlertsFilterData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSetSelectedImages = (
        image: ScreenCaptureData,
        checked: boolean
    ): void =>
    {
        if (image)
        {
            if (checked)
            {
                setSelectedImages((prevSelectedImages: ScreenCaptureData[]) => [
                    ...prevSelectedImages,
                    image,
                ]);
                setSelectedFileIds((prevSelectedFileIds: string[]) => [
                    ...prevSelectedFileIds,
                    image.fileId,
                ]);
            }
            else
            {
                setSelectedImages((prevSelectedImages: ScreenCaptureData[]) =>
                    prevSelectedImages.filter(
                        (selectedImage) => selectedImage.fileId !== image.fileId
                    ));
                setSelectedFileIds((prevSelectedFileIds: string[]) =>
                    prevSelectedFileIds.filter(
                        (fileId) => fileId !== image.fileId
                    ));
            }
        }
    };

    async function handlerAllPDFExport(): Promise<void>
    {
        setFormatAll('PDF');
        dispatch(getAllToExportDatas({
            ...filters,
            pageNumber: 1,
            entriesPerPage: 10,
            exportSkipId: 0,
            exportlimit: screenCaptures?.numberOfEntries,
            pdfExport: true,
            dataType: DataType.SCREEN_CAPTURES,
        }));
        setAlltoExport(true);
    }

    const handleALLJPEGExport = (): void =>
    {
        setIsLoading(true);
        dispatch(
            getScreenCapturesJPEG({
                ...filters,
            })
        );

    };

    const handleSelectPDF = (): void =>
    {
        if (selectedImages.length > 0)
        {
            pdfExportSetter({
                type: DataType.SCREEN_CAPTURES,
                recordIds: selectedImages.map((item) => item.id),
            });
            setPdfType('selected');
        }
    };

    async function handlerPDFExport(tableExportFormat: TableExportFormat): Promise<void>
    {
        setFormat(tableExportFormat);
        if (tableExportFormat === TableExportFormat.PDF && pdfExporter)
        {
            await TableExporter.asPDF(pdfExporter);
        }
        else
        {
            setExportFormat(tableExportFormat);
        }
        setFormat(undefined);
    }

    React.useEffect(() =>
    {
        if (pdfType)
        {
            handlerPDFExport(TableExportFormat.PDF);
        }
        setPdfType('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfType]);

    const prevFilters = React.useRef(filters);

    React.useEffect(() =>
    {
        if(filters.isGridView === true)
        {
            const filtersChanged = checkFilterChange(filters, prevFilters.current);

            if(!filtersChanged)
            {
                dispatch(resetscreenCapturesData());
            }

            getScreenCaptures({
                pageNumber: 1,
                entriesPerPage: 10,
                ...filters,
            });
            prevFilters.current = filters;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <>
            <Grid container mb="1rem" mt="rem">
                <Grid item md={6} xs={12}>
                    <PDFExport
                        selectedValue={handlerAllPDFExport}
                        ids={viewDataIds}
                        idStatus={viewDataIdStatus}
                        data={DataType.SCREEN_CAPTURES}
                        formatAll={formatAll}
                        setFormatAll={setFormatAll}
                        alltoExport={alltoExport}
                    />
                    <JPEGExport
                        selectedValue={handleALLJPEGExport}
                        ids={viewDataJPEG}
                        idStatus={ViewDataJPEGstatus}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                    />
                    {selectedImages.length > 0 ? (
                        <>
                            <Button
                                variant="text"
                                startIcon={
                                    TableExporter.loading
                                && format === TableExportFormat.PDF
                                        ? <CircularProgress size="1rem" />
                                        : <DownloadIcon />
                                }
                                onClick={(e) =>
                                {
                                    handleSelectPDF();
                                }}
                            >
                                {t('common.button.selected-to-pdf')}
                            </Button>
                            <Button
                                variant="text"
                                startIcon={
                                    selectLoading
                                        ? <CircularProgress size="1rem" />
                                        : <DownloadIcon />
                                }
                                onClick={() =>
                                {
                                    downloadImages(
                                        selectedImages,
                                        null,
                                        setSelectLoading
                                    );
                                }}
                            >
                                {t('common.button.selected-to-jpeg')}
                            </Button>
                        </>
                    )
                        : null}
                </Grid>
                <Grid item md={6} xs={12}>
                    <Grid
                        container
                        spacing={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        {filters.device && (
                            <Grid
                                item
                                md={4}
                                sm={12}
                                xs={12}
                            >
                                <DataTypeUpdateFlagAll
                                    dataType={DataType.SCREEN_CAPTURES}
                                    count={screenCaptures?.numberOfEntries}
                                />
                            </Grid>
                        )}
                        <Grid
                            item
                            md={4}
                            sm={12}
                            xs={12}
                        >
                            <Button
                                variant="contained"
                                onClick={(e) =>
                                {
                                    dispatch(changeFilters({
                                        alertwordsOnly: !filters.alertwordsOnly,
                                    }));
                                }}
                            >
                                {!filters.alertwordsOnly ?
                                    t('form.View-alert-words') : t('form.No-alert-words')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <GridView
                images={screenCaptures?.contents?.map((screen, index) => ({
                    fileId: screen.fileId,
                    date: screen.capturedDate,
                    fileUrl: screen.fileUrl,
                    count: index + 1,
                    totalCount: screenCaptures?.numberOfEntries,
                    onClick: () => showDetails(screen),
                    onCheck: handleSetSelectedImages,
                    aiScanStatus: screen.aiScanStatus,
                    alertWords: screen.alertWords || [],
                    device: screen.device,
                    deviceId: screen.deviceId,
                    platform: screen.platform,
                    username: screen.username,
                    flag: screen.flag,
                    dataType: DataType.SCREEN_CAPTURES,
                    id: screen.id,
                    captureStartDate: screen.captureStartDate,
                    captureEndDate: screen.captureEndDate,
                    capturedDate: screen.capturedDate,
                    receivedDate: screen.receivedDate,
                    comments: screen.comments,
                })) || []}
                selectedFileIds={selectedFileIds}
            />
        </>
    );
}
