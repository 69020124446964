import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable, ColumnData } from 'src/components/data-table/DataTable';
import { SuggestedAlertWordsList } from 'src/models/administration/alertWordListData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getSuggestedAWLs,
    selectSuggestedAWLRetrieveStatus,
    selectSuggestedAWL,
    resetSuggestedAWLRetrieveStatus,
    selectAdminFilters,
} from 'src/state/administration/administrationSlice';
import EditIcon from '@mui/icons-material/Edit';
import {
    Box,
    Button,
    CircularProgress,
} from '@mui/material';
import { generatePath, Link } from 'react-router-dom';
import { AWL_EDIT } from 'src/utils/routes';
import { ToggleTable } from '../toggle-table/ToggleTable';
import {
    useConfiguration,
    useConfigurationRetrieveStatus,
    useConfigurationSaveStatus,
    useSetSuggestedAWLs,
} from '../awl-index-tabs/AgencyAWLConfigurationContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { Modal } from 'src/components/modal/Modal';
import DeleteSuggestedAWL from './DeleteSuggestedAWL';

export interface SuggestedAWLTableProps
{
    toggleable?: boolean;
    editable?: boolean;
}

export function SuggestedAWLTable({
    toggleable = false,
    editable = false,
}: Readonly<SuggestedAWLTableProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const alertWordsList = useSelector(selectSuggestedAWL);
    const status = useSelector(selectSuggestedAWLRetrieveStatus);
    const filters = useSelector(selectAdminFilters);
    const [open, setOpen] = React.useState(false);
    const [id, setId] = useState<string>();
    const [suggestedAWLName, setSuggestedAWLName] = useState<string>('');
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    // Agency AWL Congfiguration
    const configuration = useConfiguration();
    const configurationRetrieveStatus = useConfigurationRetrieveStatus();
    const setSuggestedAWLs = useSetSuggestedAWLs();
    const configurationUpdateStatus = useConfigurationSaveStatus();

    function onPageChange(pageNumber: number, entriesPerPage: number): void
    {
        dispatch(getSuggestedAWLs(
            {
                pageNumber,
                entriesPerPage,
                ...filters,
            }
        ));
    }

    function onChangeEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
    }

    const getIdentifier = ({ id }: SuggestedAlertWordsList): string => id;

    const getEditColumn = (id: string): React.ReactNode => (
        <Button
            startIcon={<EditIcon />}
            component={Link}
            to={generatePath(AWL_EDIT, { id, context: 'suggested' })}
        />
    );
    const getDeleteColumn = (data: SuggestedAlertWordsList): React.ReactNode => (
        <Button
            onClick={() =>
            {
                setId(data.id);
                setSuggestedAWLName(data.name);
                setOpen(true);
            }}
        >
            <DeleteIcon />
        </Button>
    );

    const columns: ColumnData<SuggestedAlertWordsList>[] = [
        {
            label: t('common.nouns.id'),
            size: 10,
            value: (alertWordsList: SuggestedAlertWordsList) => alertWordsList.id,
        },
        {
            label: t('common.nouns.name'),
            size: 30,
            value: (alertWordsList: SuggestedAlertWordsList) => alertWordsList.name,
        },
        {
            label: t('common.nouns.profile'),
            size: 30,
            value: (alertWordsList: SuggestedAlertWordsList) => alertWordsList.profileName,
        },
        {
            label: t('data-type.table.date-of-creation'),
            size: 20,
            value: (alertWordsList: SuggestedAlertWordsList) => alertWordsList.createdAt.toString(),
        },

    ];

    if (editable)
    {
        columns.push(
            {
                label: t('common.actions.edit'),
                size: 10,
                format: ({ id }) => getEditColumn(id),
            },
            {
                label: t('common.actions.delete'),
                size: 1,
                format: (alertWordsList: SuggestedAlertWordsList) =>
                    getDeleteColumn(alertWordsList),
            }
        );
    }
    function closeModal(): void
    {
        setOpen(false);
        dispatch(getSuggestedAWLs(
            {
                pageNumber: 1,
                entriesPerPage,
                ...filters,
            }
        ));
    }
    useEffect(() =>
    {
        dispatch(getSuggestedAWLs(
            {
                pageNumber: 1,
                entriesPerPage,
                ...filters,
            }
        ));
        return () =>
        {
            dispatch(resetSuggestedAWLRetrieveStatus);
        };
    }, [dispatch, filters, entriesPerPage]);

    function renderToggleTable(): React.ReactElement
    {
        if (configurationRetrieveStatus === RequestStatus.InProgress)
        {
            return <CircularProgress />;
        }
        else if (configurationRetrieveStatus === RequestStatus.Failure || !configuration)
        {
            return (
                <div>
                    {t('common.messages.something-wrong')}
                </div>
            );
        }
        else
        {
            return (
                <ToggleTable<SuggestedAlertWordsList>
                    initialSelection={
                        configuration?.suggested.map(({ id }) => id)
                    }
                    blocked={
                        configurationUpdateStatus === RequestStatus.InProgress
                    }
                    toggleTitle={t('common.nouns.status')}
                    getIdentifier={getIdentifier}
                    onChange={setSuggestedAWLs}
                    isLoading={status === RequestStatus.InProgress}
                    columns={columns}
                    onPageChange={onPageChange}
                    entriesPerPage={entriesPerPage}
                    onChangeEntriesPerPage={onChangeEntriesPerPage}
                    {...alertWordsList}
                />
            );
        }
    }

    function renderDataTable(): React.ReactElement
    {
        if (status === RequestStatus.Failure)
        {
            return (
                <div>
                    {t('common.messages.something-wrong')}
                </div>
            );
        }

        return (
            <>
                <DataTable<SuggestedAlertWordsList>
                    isLoading={status === RequestStatus.InProgress}
                    columns={columns}
                    onPageChange={onPageChange}
                    entriesPerPage={entriesPerPage}
                    onChangeEntriesPerPage={onChangeEntriesPerPage}
                    {...alertWordsList}
                />
                <Modal
                    title={'suggestedAWL'}
                    open={open}
                    onClose={closeModal}
                    showCloseButton={true}
                    maxWidth="md"
                    fullWidth
                >
                    <Box>

                        <DeleteSuggestedAWL
                            DeleteModal={closeModal}
                            open={open}
                            id={id}
                            type={suggestedAWLName}
                        />

                    </Box>
                </Modal>
            </>
        );
    }

    return (
        <Box display="flex" alignItems={toggleable?'center':''} flexDirection="column">
            {
                toggleable
                    ? renderToggleTable()
                    : renderDataTable()
            }
        </Box>
    );
}
