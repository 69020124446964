import React from 'react';
import { Box, Button, Stack, Theme } from '@mui/material';

const iconStyle = {
    marginRight: '0.5rem',
};

const boxStyle = {
    '&::before': {
        content: '"|"',
        color: (theme: Theme) => theme.palette.common.goldYellow,
        margin: '0 1rem',
    },
};

export interface ButtonProps {
    // eslint-disable-next-line
    text: any;
    // eslint-disable-next-line
    icon?: any;
    onClick?: () => void;
}

export interface ReportsHeaderProps {
    // eslint-disable-next-line
    labels?: any;
    icon?: React.ReactElement;
    buttons?: ButtonProps[];
}

export function TopBar({ labels, icon, buttons }: Readonly<ReportsHeaderProps>): JSX.Element
{
    return (
        <Stack direction="row" sx={{ margin: '1rem 0' }}>
            <Stack
                direction="row"
                sx={{
                    fontSize: '1.5rem',
                    fontWeight: 700,
                    color: theme => theme.palette.common.lightGrey,
                    width: '70%',
                    '@media (max-width: 768px)':
                    {
                        fontSize: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        width: '80%',
                    },
                }}
            >
                <Box sx={iconStyle}>
                    {icon}
                </Box>
                {
                    // eslint-disable-next-line
                    labels?.map((label: any, index: any) => (
                        <Box
                            key={`row-${index + 1}`}
                            sx={index ? boxStyle : {}}
                        >
                            {label}
                        </Box>
                    ))
                }
            </Stack>
            <Stack
                justifyContent={'flex-end'}
                direction="row"
                sx={{
                    fontSize: '1.5rem',
                    fontWeight: 700,
                    color: theme => theme.palette.common.lightGrey,
                    width: '30%',
                    '@media (max-width: 768px)':
                    {
                        display: 'flex',
                        width: '50%',
                        justifyContent: 'flex-end',
                    },
                }}
            >
                <Box sx={{ float: 'left' }} >
                    {buttons?.map((button, key) => (
                        <Button
                            key={`row-${key + 1}`}
                            startIcon={button.icon}
                            onClick={button.onClick}
                        >
                            {button.text}
                        </Button>
                    ))}
                </Box>
            </Stack>
        </Stack>
    );
}
