import React, { useState } from 'react';
import {
    Alert,
    AlertProps,
    Button,
    Snackbar,
    SnackbarProps,
    Stack,
    Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdministrationHeader } from 'src/templates/administration-header/AdministrationHeader';
import { AdministrationPage } from 'src/templates/administration-page/AdministrationPage';
import { ClientsTable } from 'src/templates/clients-table/ClientsTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Client } from 'src/models/administration/clientData.model';
import { Role } from 'src/models/roles.enum';
import { userIsInRole } from 'src/utils/roleUtils';

import {
    ClientCreationFormModal,
} from 'src/templates/client-creation-form-modal/ClientCreationFormModal';

import {
    ClientEditionFormModal,
} from 'src/templates/client-edition-form-modal/ClientEditionFormModal';

import {
    ClientConfigFormModal,
} from 'src/templates/client-config-form-modal/ClientConfigFormModal';

import {
    ConfigurationOwner,
} from 'src/models/administration/configurationData.model';

import {
    ClientAlertWordsModal,
} from 'src/templates/client-alert-words/ClientAlertWordsModal';

import Grid from '@mui/material/Grid';

import {
    AdminSettingSearchFilter,
} from 'src/templates/admin-setting-search-filter/AdminSettingSearchBar';



export function ClientManagement(): JSX.Element
{
    const { t } = useTranslation();
    const [isCreationModalOpen, setIsCreationModalOpen] =
        useState<boolean>(false);
    const [clientInEdition, setClientInEdition] = useState<Client>();
    const [clientInConfig, setClientInConfig] = useState<Client>();
    const [clientInAlert, setClientInAlert] = useState<Client>();
    const [notificationState, setNotificationState] =
        useState<SnackbarProps & AlertProps>();

    // create or edit button
    function onModalClose(): void
    {
        setIsCreationModalOpen(false);
        setClientInEdition(undefined);
    }
    function openCreationModal(): void
    {
        setIsCreationModalOpen(true);
    }
    function onEdit(client: Client): void
    {
        setClientInEdition(client);
    }

    // config button
    function onConfigModalClose(): void
    {
        setClientInConfig(undefined);
    }
    function onConfig(client: Client): void
    {
        setClientInConfig(client);
    }

    // alertwords button
    function onAlertModalClose(): void
    {
        setClientInAlert(undefined);
    }
    function onAlert(client: Client): void
    {
        setClientInAlert(client);
    }

    // All
    function onCloseNotification(): void
    {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }
    function onSaveFinish(success: boolean): () => void
    {
        const subject = t('common.nouns.client');

        return () =>
        {
            if(success)
            {
                setNotificationState({
                    open: true,
                    message: success
                        ? t('common.messages.saved-successfully', { subject })
                        : t('common.messages.something-wrong'),
                    severity: success ? 'success' : 'error',
                });

                onModalClose();
                onConfigModalClose();
                onAlertModalClose();
            }
        };
    }
    function onDeleteFinish(success: boolean): () => void
    {
        const subject = t('common.nouns.client');
        return () =>
        {
            setNotificationState({
                open: true,
                message: success
                    ? t('common.messages.Delete-successfully', { subject })
                    : t('common.messages.something-wrong'),
                severity: success ? 'success' : 'error',
            });

            if (success)
            {
                onModalClose();
                onConfigModalClose();
                onAlertModalClose();
            }
        };
    }

    return (
        <>
            <AdministrationPage>
                <AdministrationHeader labels={[t('menu.clients-management')]} />
                <Stack direction="row" justifyContent="flex-end" padding={'1em 0'}>
                    <Box sx={{ mr: 3 }}>
                        <Grid
                            item
                            md={3}
                            sm={12}
                            xs={12}
                        >
                            <AdminSettingSearchFilter />
                        </Grid>
                    </Box>
                    {userIsInRole([Role.Administrator]) ||
                        userIsInRole([Role.Employee])||userIsInRole([Role.AgencyAdministrator]) ? (
                            <Button startIcon={<AddCircleIcon />} onClick={openCreationModal}>
                                {t('clients.add')}
                            </Button>
                        ) : null}
                </Stack>
                <ClientsTable
                    onEdit={onEdit}
                    onConfig={onConfig}
                    onAlert={onAlert}
                />
            </AdministrationPage>
            <ClientCreationFormModal
                open={isCreationModalOpen}
                onClose={onModalClose}
                onSuccess={onSaveFinish(true)}
                onFailure={onSaveFinish(false)}
            />
            {clientInEdition ? (
                <ClientEditionFormModal
                    client={clientInEdition}
                    open={!!clientInEdition}
                    onClose={onModalClose}
                    onSuccess={onSaveFinish(true)}
                    onDeleted={onDeleteFinish(true)}
                    onFailure={onSaveFinish(false)}
                />
            ) : null}
            {clientInConfig ? (
                <ClientConfigFormModal
                    client={clientInConfig}
                    context={ConfigurationOwner.Client}
                    open={!!clientInConfig}
                    onClose={onConfigModalClose}
                    onSuccess={onSaveFinish(true)}
                    onFailure={onSaveFinish(false)}
                />
            ) : null}
            {clientInAlert ? (
                <ClientAlertWordsModal
                    client={clientInAlert}
                    open={!!clientInAlert}
                    onClose={onAlertModalClose}
                />
            ) : null}
            <Snackbar
                autoHideDuration={6000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert severity={notificationState?.severity}>
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}
