import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/modal/Modal';
import { noop } from 'src/utils/common';
import {
    VersionUpdateForm,
} from 'src/templates/version-update-form/VersionUpdateForm';
import {
    PlatformVersion,
} from 'src/models/administration/platformVersionData.model';

export interface VersionEditionModalProps {
    data?: PlatformVersion;
    open?: boolean;
    onClose?: () => void;
    onActionComplete?: () => void;
}

export function VersionEditionModal({
    data,
    open = true,
    onClose = noop,
    onActionComplete = noop,
}: Readonly<VersionEditionModalProps>): React.ReactElement
{
    const { t } = useTranslation();

    function closeModal(status: boolean):void
    {
        onClose();
    }

    function onActionFinished(status: boolean):void
    {
        onActionComplete();
    }

    return (
        <Modal
            title={t('data-type.table.version')}
            open={open}
            onClose={closeModal}
            showCloseButton={true}
            maxWidth="md"
            fullWidth
        >
            <Box>
                <VersionUpdateForm
                    {...data}
                    closeModal={closeModal}
                    onComplete={onActionFinished}
                />
            </Box>
        </Modal>
    );
}
