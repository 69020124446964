import React, {
    useState,
    useEffect,
    useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MMSType, TextMMSData } from 'src/models/captures/textMMSData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getTextMMSs,
    selectFilters,
    changeTextMMS,
    selectTextMMSs,
    selectTextMMSsStatus,
    changeFilters,
    updateSeenListStatus,
    resettextMMSsData,
    selectallPageData,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';

import { DataTable } from 'src/components/data-table/DataTable';
import Chip from '@mui/material/Chip';
import { TextMMSDetails } from './TextMMSDetails';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
    Button,
    CircularProgress,
    IconButton,
    Typography,
} from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { flagStringKey } from 'src/models/flag.enum';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { ReactComponent as ScoutRed } from 'src/assets/icons/scout-red.svg';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import TextMMSAlertsGrid from './TextMMSAlertsGrid';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { HighlightText } from 'src/components/highlight-text/HighlightText';

export function TextMMSAlertsTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();
    const [showDetails, setShowDetails] = useState(false);

    const filters = useSelector(selectFilters);

    const textMMSsStatus = useSelector(selectTextMMSsStatus);
    const textMMSsData = useSelector(selectTextMMSs);

    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [currentFilter, setCurrentFilter] = useState({});
    const desktopScreen = useDesktopScreen();
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const allPageData = useSelector(selectallPageData);
    const [lastPage, setLastPage] = useState(0);
    const prevPage = useRef(1);
    const [imageError, setImageError] = useState<string[]>([]);

    function onPageChange(pageNumber: number): void
    {
        if (textMMSsData && pageNumber !== lastPage &&
            (pageNumber === prevPage.current + 1 ||
                pageNumber === prevPage.current - 1))
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(textMMSsData?.contents[
                    textMMSsData.contents.length - 1]?.id);
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(Array.isArray(lastRecordDatas) &&
                    lastRecordDatas.length > 0 ?
                    lastRecordDatas[lastRecordDatas.length - 1]?.id : '');
            }
        }
        else
        {
            setSkipRecordId('');
        }
        setCurrentPage(pageNumber);
        prevPage.current = pageNumber;
    }
    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }

    useEffect(() =>
    {
        dispatch(resettextMMSsData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters.isGridView]);

    useEffect(() =>
    {
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);

            if (filters)
            {
                setCurrentFilter(filters);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        const rowsPerPage = filters?.rowsPerPage ?? entriesPerPage;
        dispatch(getTextMMSs({
            skipRecordId,
            pageNumber: checkFilterChange(currentFilter, filters) ? currentPage : 1,
            entriesPerPage: filters.isGridView === true ? 100 : rowsPerPage,
            ...filters,
            alertwordsOnly: filters.isGridView === true ? filters.alertwordsOnly : false,
        }));
        setCurrentFilter(filters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const [datas, setDatas] = useState<string[] | undefined>(undefined);

    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };
    function showTextMMSDetails(textMMS: TextMMSData): void
    {
        openRecordData(textMMS.id);

        dispatch(changeTextMMS(textMMS));
        if (!textMMS.isSeen)
        {
            dispatch(updateSeenListStatus({ detailsId: textMMS.id, dataType: DataType.SMS }));
        }
        setShowDetails(true);
    }

    const getAiScanStatusColumn = (data: TextMMSData): React.ReactNode =>
    {
        if (data.aiScanStatus === 2) return <ScoutRed />;
        else if (data.aiScanStatus === 0)
            return (
                <HourglassEmptyOutlinedIcon
                    sx={{ fill: 'lightGrey' }}
                />
            );
        else return '';
    };
    const getFlagColumn = (data: TextMMSData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.MMS}
            onChange={() =>
            {
                onPageChange(textMMSsData?.currentPage ?? 1);
            }}
        />
    );
    const getSenderColumn = (data: TextMMSData): React.ReactNode => (
        data.mmsType === MMSType.INCOMING ? (
            <Chip
                label={t('data-type.table.received')}
                color="error"
                size="small"
            />
        ) : (
            <Chip
                label={t('data-type.table.sender')}
                color="success"
                size="small"
            />
        )
    );
    const getImageElement = (data: TextMMSData): React.ReactNode => (
        <img
            height="50"
            src={data.fileUrl}
            onError={() => setImageError([...imageError, data.id])}
            alt={new Date(data.capturedDate).toLocaleString()}
        />
    );
    const getMessageColumn = (data: TextMMSData): React.ReactNode => (
        <HighlightText
            textToHighlight={data?.message || ''}
            highlightWords={data?.alertWords || ['']}
        />
    );
    const getImageColumn = (data: TextMMSData): React.ReactNode =>
    {
        return imageError.includes(data.id) ? <AccessTimeIcon /> : getImageElement(data);
    };
    const openDetailColumn = (data: TextMMSData): React.ReactNode => (
        <IconButton
            aria-label="edit"
            onClick={() => showTextMMSDetails(data)}
        >
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.ai'),
            size: 1,
            align: 'center' as const,
            sortByType: 'aiScanStatus',
            format: (data: TextMMSData) => getAiScanStatusColumn(data),
        },
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: TextMMSData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: TextMMSData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: TextMMSData) =>
                data.date ? new Date(data.date).toLocaleString() : '',
            sortByType: 'date',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: TextMMSData) =>
                new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 2,
            value: (data: TextMMSData) => data.device,
            sortByType: 'device',
        },

        {
            label: t('data-type.table.device-number'),
            size: 2,
            value: (data: TextMMSData) => data.deviceNumber.replace('+', ''),
        },
        {
            label: t('data-type.table.external-number'),
            size: 2,
            value: (data: TextMMSData) => data.externalNumber.replace('+', ''),
        },
        {
            label: t('data-type.table.sender'),
            size: 1,
            align: 'center' as const,
            value: (data: TextMMSData) =>
            {
                return data.mmsType === MMSType.INCOMING
                    ? t('data-type.table.received')
                    : t('data-type.table.sender');
            },
            format: (data: TextMMSData) => getSenderColumn(data),
        },
        {
            label: t('data-type.table.message'),
            size: 4,
            value: (data: TextMMSData) => data?.message,
            sortByType: 'message',
            format: (data: TextMMSData) => getMessageColumn(data),
        },
        {
            label: t('data-type.table.image'),
            size: 2,
            align: 'center' as const,
            format: (data: TextMMSData) => getImageColumn(data),
        },
        {
            label: t('data-type.table.alert-word'),
            size: 2,
            value: (data: TextMMSData) => data.alertWords?.join(', '),
            sortByType: 'alertWords',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: TextMMSData) => openDetailColumn(data),
        },
    ];

    useEffect(() =>
    {
        if (textMMSsData)
        {
            setLastPage(Math.floor(
                textMMSsData.numberOfEntries / entriesPerPage
            ));
            pdfExportSetter({
                type: DataType.SMS,
                recordIds: textMMSsData.contents.map((item) => item.id),
                sortBy: filters.sortBy,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(textMMSsData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.MMS)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textMMSsData, pdfExportSetter]);
    useEffect(()=>
    {
        if(filters)
        {
            setCurrentPage(1);
        }
    }, [filters]);
    return (
        <>
            <TextMMSDetails
                open={showDetails}
                onClose={() => setShowDetails(false)}
            />
            <>
                {filters.isGridView === false && (
                    desktopScreen ? (
                        <DataTable<TextMMSData>
                            isLoading={textMMSsStatus === RequestStatus.InProgress}
                            columns={columns}
                            entriesPerPage={entriesPerPage}
                            onPageChange={(pageNumber) =>
                            {
                                onPageChange(pageNumber);
                            }}
                            onChangeEntriesPerPage={(entriesPerPage) =>
                            {
                                updateEntriesPerPage(entriesPerPage);
                            }}
                            datas={datas}
                            {...textMMSsData}
                        />
                    ) : (
                        <DataTableMobile
                            isLoading={textMMSsStatus === RequestStatus.InProgress}
                            contents={textMMSsData?.contents || []}
                            setOpenModal={showTextMMSDetails}
                            entriesPerPage={entriesPerPage}
                            onPageChange={(pageNumber) =>
                            {
                                onPageChange(pageNumber);
                            }}
                            onChangeEntriesPerPage={(entriesPerPage) =>
                            {
                                updateEntriesPerPage(entriesPerPage);
                            }}
                            numberOfEntries={textMMSsData?.numberOfEntries}
                            currentPage={textMMSsData?.currentPage}
                            enablePagination
                        />
                    )
                )}
                {filters.isGridView === true &&
                    textMMSsData && textMMSsData?.contents?.length > 0 ? (
                        <>
                            <TextMMSAlertsGrid showDetails={showTextMMSDetails} />
                            {textMMSsStatus === RequestStatus.InProgress && (
                                <div style={{ textAlign: 'center' }}>
                                    <CircularProgress
                                        style={{
                                            width: '4rem',
                                            height: '4rem',
                                            display: 'inline-block',
                                            marginTop: '-4%',
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        filters.isGridView === true &&
                    textMMSsData && textMMSsData?.contents?.length === 0 &&
                    textMMSsStatus === RequestStatus.Success &&
                    (
                        <>
                            {filters.alertwordsOnly && (
                                <Button
                                    variant="contained"
                                    sx={{ float: 'right', top: '-4px' }}
                                    onClick={(e) =>
                                    {
                                        dispatch(changeFilters({
                                            alertwordsOnly: !filters.alertwordsOnly,
                                        }));
                                    }}
                                >
                                    {!filters.alertwordsOnly
                                        ? t('form.View-alert-words')
                                        : t('form.No-alert-words')
                                    }
                                </Button>
                            )}
                            <Typography
                                variant="h3"
                                sx={{
                                    color: (theme) => theme.palette.common.lightGrey,
                                    margin: '4rem auto',
                                    textAlign: 'center',
                                }}
                            >
                                {t('data-type.table.no-content')}
                            </Typography>
                        </>
                    ))}
                {filters.isGridView === true &&
                    textMMSsData && textMMSsData?.contents?.length === 0 &&
                    textMMSsStatus === RequestStatus.InProgress && (
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <CircularProgress
                            style={{
                                width: '4rem',
                                height: '4rem',
                                display: 'inline-block',
                            }}
                        />
                    </div>
                )}
            </>
        </>
    );
}
