import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CallHistoryData } from 'src/models/captures/callHistoryData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getCallHistory,
    selectFilters,
    selectCallHistory,
    selectCallHistoryStatus,
    getCallHistoryDetails,
    selectCallHistoryDetails,
    selectCallHistoryDetailsStatus,
    updateSeenListStatus,
    changeFilters,
    selectallPageData,
    changeColumns,
    changeTotalRecord,
    changeTableDataType,
} from 'src/state/captures/capturesSlice';
import { DataTable } from 'src/components/data-table/DataTable';
import Chip from '@mui/material/Chip';
import { IconButton } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { DataTypeFlagItem } from 'src/templates/data-type-flag-item/DataTypeFlagItem';
import { DataType } from 'src/models/dataType.enum';
import { useDesktopScreen } from 'src/utils/checkDesktopScreen';
import { DataTableMobile } from 'src/components/data-table/DataTableMobile';
import { flagStringKey } from 'src/models/flag.enum';
import { secondsToHHMMSS, timeZoneFormat } from 'src/utils/dateUtils';
import { checkFilterChange } from 'src/utils/checkFilterChange';
import { usePDFExportSetter } from 'src/components/table-exporter/TableExporterProvider';
import { DataDetailsModal } from '../data-type-detail/DataDetailModal';
import { CommentType } from 'src/models/commentType.enum';
import { USER_TIME_ZONE } from 'src/utils/environment';

export function CallHistoryAlertsTable(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const pdfExportSetter = usePDFExportSetter();
    const filters = useSelector(selectFilters);
    const callHistoryStatus = useSelector(selectCallHistoryStatus);
    const callHistoryData = useSelector(selectCallHistory);
    const callHistoryDetailsStatus = useSelector(selectCallHistoryDetailsStatus);
    const callHistoryDetailsData = useSelector(selectCallHistoryDetails);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentFilter, setCurrentFilter] = useState({});
    const allPageData = useSelector(selectallPageData);
    const [skipRecordId, setSkipRecordId] = useState<string>();
    const prevPage = useRef(1);
    const [lastPage, setLastPage]=useState(0);
    const [datas, setDatas] = useState<string[] | undefined>(undefined);

    const openRecordData = (recordId: string): void =>
    {
        if (datas === undefined)
        {
            setDatas([recordId]);
            return;
        }
        if (!datas.includes(recordId))
        {
            setDatas([...datas, recordId]);
        }
    };

    const handleOpen = (callHistory: CallHistoryData): void =>
    {
        openRecordData(callHistory.id);
        dispatch(getCallHistoryDetails(callHistory.id));
        if (!callHistory.isSeen)
        {
            dispatch(updateSeenListStatus({ detailsId: callHistory.id, dataType: DataType.CALLS }));
        }
        setShowModal(true);
    };
    function onPageChange(pageNumber: number): void
    {
        if (callHistoryData && pageNumber!==lastPage && pageNumber!==lastPage+1 &&
            (pageNumber === prevPage.current + 1 || pageNumber === prevPage.current - 1))
        {
            if (pageNumber >= prevPage.current)
            {
                setSkipRecordId(callHistoryData?.contents[callHistoryData.contents.length - 1]?.id);
            }
            else
            {
                const lastRecordDatas = allPageData[pageNumber - 1];
                setSkipRecordId(Array.isArray(lastRecordDatas) && lastRecordDatas.length > 0 ?
                    lastRecordDatas[lastRecordDatas.length - 1]?.id : '');
            }
        }
        else
        {
            setSkipRecordId('');
        }

        setCurrentPage(pageNumber);
        prevPage.current = pageNumber;
    }
    function updateEntriesPerPage(entriesPerPage: number): void
    {
        setEntriesPerPage(entriesPerPage);
        onPageChange(1);
        dispatch(
            changeFilters({
                rowsPerPage: entriesPerPage,
            })
        );
    }

    useEffect(() =>
    {
        if (filters?.rowsPerPage !== undefined)
        {
            setEntriesPerPage(filters.rowsPerPage);

        }

        if (filters)
        {
            setCurrentFilter(filters);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if(filters)
        {
            setCurrentPage(1);
        }
    }, [filters]);

    useEffect(() =>
    {
        const rowsPerPage = filters?.rowsPerPage !== undefined ?
            filters.rowsPerPage :
            entriesPerPage;

        dispatch(getCallHistory({
            skipRecordId,
            pageNumber: checkFilterChange(currentFilter, filters) ? currentPage : 1,
            entriesPerPage: rowsPerPage,
            ...filters,
        }));
        setCurrentFilter(filters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, filters, currentPage]);

    const getFlagColumn = (data: CallHistoryData): React.ReactNode => (
        <DataTypeFlagItem
            capturedData={data}
            dataType={DataType.CALLS}

            onChange={() =>
            {
                onPageChange(callHistoryData?.currentPage ?? 1);
            }}
        />
    );

    const getCallType = (data: CallHistoryData): React.ReactNode => (
        data.type !== 'OUTGOING' ? (
            <Chip label={data.type?.toString()} color="error" size="small" />
        ) : (
            <Chip label={data.type?.toString()} color="success" size="small" />
        )
    );

    const openDetailColumn = (data: CallHistoryData): React.ReactNode => (
        <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <ManageSearchIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('data-type.table.flag'),
            size: 1,
            align: 'center' as const,
            value: ({ flag }: CallHistoryData) => t(flagStringKey(flag)),
            sortByType: 'flag',
            format: (data: CallHistoryData) => getFlagColumn(data),
        },
        {
            label: t('data-type.table.captured-date'),
            size: 3,
            value: (data: CallHistoryData) =>
                new Date(data?.date).toLocaleString(),
            sortByType: 'date',
        },
        {
            label: t('data-type.table.received-date'),
            size: 3,
            value: (data: CallHistoryData) =>
                new Date(data.receivedDate).toLocaleString(),
            sortByType: 'createdAt',
        },
        {
            label: t('data-type.table.device-alias'),
            size: 4,
            value: (data: CallHistoryData) => data.device,
            sortByType: 'device',
        },
        {
            label: t('data-type.table.device-number'),
            size: 2,
            value: (data: CallHistoryData) => data.deviceNumber?.replace('+', ''),
            sortByType: 'deviceNumber',
        },
        {
            label: t('data-type.table.external-number'),
            size: 2,
            value: (data: CallHistoryData) => data.externalNumber?.replace('+', ''),
            sortByType: 'externalNumber',
        },
        {
            label: t('data-type.table.contact-name'),
            size: 2,
            value: (data: CallHistoryData) => data.contactName,
            sortByType: 'contactName',
        },
        {
            label: t('data-type.table.type-of-call'),
            size: 2,
            align: 'center' as const,
            sortByType: 'type',
            value: (data: CallHistoryData) =>
            {
                return data.type !== 'OUTGOING'
                    ? t('data-type.table.received')
                    : t('data-type.table.sender');
            },
            format: (data: CallHistoryData) => getCallType(data),
        },
        {
            label: t('data-type.table.duration'),
            size: 1,
            value: (data: CallHistoryData) => secondsToHHMMSS(data.duration),
            sortByType: 'duration',
        },
        {
            label: t('data-type.table.detail'),
            size: 1,
            align: 'center' as const,
            format: (data: CallHistoryData) => openDetailColumn(data),
        },
    ];

    const detailItems = [
        {
            label: t('data-type.details.received-date'),
            text: (data: CallHistoryData) => timeZoneFormat(
                new Date(data.receivedDate),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.details.captured-date'),
            text: (data: CallHistoryData) => timeZoneFormat(
                new Date(data.date),
                USER_TIME_ZONE
            ),
        },
        {
            label: t('data-type.table.device-alias'),
            text: (data: CallHistoryData) => data.device,
        },
        {
            label: t('data-type.table.device-number'),
            text: (data: CallHistoryData) => data.deviceNumber?.replace('+', ''),
        },
        {
            label: t('data-type.table.external-number'),
            text: (data: CallHistoryData) => data.externalNumber?.replace('+', ''),
        },
        {
            label: t('data-type.table.contact-name'),
            text: (data: CallHistoryData) => data.contactName,
        },
        {
            label: t('data-type.table.type-of-call'),
            text: (data: CallHistoryData) => data.type,
        },
        {
            label: t('data-type.table.duration'),
            text: (data: CallHistoryData) => secondsToHHMMSS(data.duration),
        },
    ];

    useEffect(() =>
    {
        if (callHistoryData)
        {
            setLastPage(Math.floor(callHistoryData.numberOfEntries/entriesPerPage));
            pdfExportSetter({
                type: DataType.CALLS,
                recordIds: callHistoryData.contents.map((item) => item.id),
                sortBy: filters.sortBy,
            });
            dispatch(changeColumns(columns));
            dispatch(changeTotalRecord(callHistoryData.numberOfEntries));
            dispatch(
                changeTableDataType(DataType.CALLS)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callHistoryData, pdfExportSetter]);

    return (
        <>
            <DataDetailsModal<CallHistoryData>
                title={t('data-type.details.call-history-title')}
                open={showModal}
                data={callHistoryDetailsData}
                detailItems={detailItems}
                isLoading={callHistoryDetailsStatus === RequestStatus.InProgress}
                onClose={() => setShowModal(false)}
                comments={{
                    contentId: callHistoryDetailsData?.id,
                    type: CommentType.CALLS,
                }}
            />

            {useDesktopScreen() ? (
                <DataTable<CallHistoryData>
                    isLoading={callHistoryStatus === RequestStatus.InProgress}
                    columns={columns}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber)=>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage)=>
                    {
                        updateEntriesPerPage(entriesPerPage );
                    }}
                    datas={datas}
                    {...callHistoryData}
                />
            ) : (
                <DataTableMobile
                    isLoading={callHistoryStatus === RequestStatus.InProgress}
                    contents={callHistoryData?.contents || []}
                    setOpenModal={handleOpen}
                    entriesPerPage={entriesPerPage}
                    onPageChange={(pageNumber)=>
                    {
                        onPageChange(pageNumber);
                    }}
                    onChangeEntriesPerPage={(entriesPerPage)=>
                    {
                        updateEntriesPerPage(entriesPerPage );
                    }}
                    numberOfEntries={callHistoryData?.numberOfEntries}
                    currentPage={callHistoryData?.currentPage}
                    enablePagination
                />
            )}
        </>
    );
}
