/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-image-gallery/styles/css/image-gallery.css';
import { SMSType, TextSMSData } from 'src/models/captures/textSMSData.model';
import {
    changeTextSMS,
    getSMSIdDetails,
    getTextSMSsForDetails,
    selectAlertsFilter,
    selectFilters,
    selectSMSIdStatus,
    selectSMSIds,
    selectTextSMSsForDetails,
} from 'src/state/captures/capturesSlice';
import { Box, Button, CircularProgress, LinearProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import { TableExportFormat } from 'src/components/table-exporter/TableExporterProvider';
import TableExporter from 'src/components/table-exporter/TableExporter';
import { DataType } from 'src/models/dataType.enum';
import ScoutRed from 'src/assets/icons/ai-scan.png';
import { PDFExport } from '../common-export-form/PDFExport';
import { ALERTSPAGE } from 'src/models/alertsType.enum';

const pagingStyle = {
    minHeight: 30,
    padding: 10,
    width: '100%',
};

type DetailMessageProps = {
    textSMS: Readonly<TextSMSData>;
    data: Readonly<TextSMSData>;
    changeMessage: (textSMS: Readonly<TextSMSData>) => void;
};

function DetailMessage({
    textSMS,
    data,
    changeMessage,
}: Readonly<DetailMessageProps>): React.ReactElement
{
    const phone =
        textSMS.smsType === SMSType.INCOMING
            ? textSMS.externalNumber
            : textSMS.deviceNumber;
    const date = new Date(textSMS.date).toLocaleString();
    const color = '#FFFFFF';
    const backgroundColor =
        textSMS.smsType === SMSType.INCOMING ? '#555555' : '#287C66';
    const outline = textSMS.id === data.id ? '2px solid #FFFFFF' : 'none';
    const margin =
        textSMS.smsType === SMSType.INCOMING
            ? '10px 20% 0 10px'
            : '10px 10px 0 20%';
    const left = textSMS.smsType === SMSType.INCOMING ? '0' : '60%';

    let imageContent;

    if (textSMS.datatype === 'MMS' && textSMS.aiScanStatus === 2)
    {
        imageContent = <img src={ScoutRed} alt="" width="45px" />;
    }
    else
    {
        imageContent = ' ';
    }

    return (
        <Box
            id={textSMS.id}
            style={{
                margin: 5,
                width: '60%',
            }}
            onClick={() => changeMessage(textSMS)}
        >
            <Box
                key={textSMS.id}
                style={{
                    position: 'relative',
                    color,
                    left,
                    backgroundColor,
                    justifyContent: 'left',
                    borderRadius: 16,
                    padding: 10,
                    margin,
                    outline,
                    boxShadow: 'rgba(0, 0, 0, 0.5) 3px 3px 3px',
                }}
            >
                <small style={{ color: '#bbbbbb' }}>
                    <b>
                        {`${phone} (${date}):`}
                    </b>
                </small>
                <br />
                {
                    <img
                        alt=""
                        style={{
                            width: '100%',
                            height: 'auto',
                            paddingBottom: 20,
                            paddingTop: 10,
                        }}
                        src={textSMS.fileUrl}
                    />
                }
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }}>
                    {imageContent}
                </div>
                <div style={{ fontSize: 20, wordWrap: 'break-word' }}>
                    {textSMS.message}
                </div>
                {''}
                {textSMS.id === data.id ? (
                    <CheckCircleIcon
                        sx={{ float: 'right' }}
                        style={{
                            fontSize: 30,
                            position: 'relative',
                            bottom: 10,
                        }}
                    />
                ) : (
                    ''
                )}
            </Box>
        </Box>
    );
}

export function TextSMSDetails(data: Readonly<TextSMSData>): React.ReactElement
{
    const pageSize = 10;
    const paginationSensibility = 10;
    const dispatch = useDispatch();
    const messageContainer = useRef<HTMLDivElement>(null);
    const [initialPage, setInitialPage] = useState(0);
    const [upPage, setUpPage] = useState(0);
    const [downPage, setDownPage] = useState(0);
    const [loadingUp, setLoadingUp] = useState(false);
    const [loadingDown, setLoadingDown] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [textSMSList, setTextSMSList] = useState<TextSMSData[]>([]);
    const textSMSsData = useSelector(selectTextSMSsForDetails);
    const textSMSIdstatus = useSelector(selectSMSIdStatus);
    const textSMSIds = useSelector(selectSMSIds);
    const [hasScrolledToSelectedMessage, setHasScrolledToSelectedMessage] =
        useState(false);
    const [format, setFormat] = useState<TableExportFormat | undefined>(undefined);
    const [formatAll, setFormatAll] = useState<string | undefined>(undefined);
    const filters = useSelector(selectFilters);
    const alertsFilter = useSelector(selectAlertsFilter);
    const [lastPage, setLastPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const isAlertsPage = window.location.pathname === ALERTSPAGE;

    useEffect(() =>
    {
        setLoadingUp(true);
        dispatch(
            getTextSMSsForDetails({
                id: data.id,
                externalNumber: data.externalNumber,
                deviceNumber: data.deviceNumber,
                sizePerPage: pageSize,
                type: '',
                startDate: isAlertsPage ? alertsFilter.startDate : filters.startDate,
                endDate: isAlertsPage ? alertsFilter.endDate : filters.endDate,
                isDateFilterChanged: filters.isDateFilterChanged,
            })
        );
        if (textSMSsData)
        {
            setLastPage(Math.ceil(textSMSsData.numberOfEntries / 10));
        }
    }, []);

    useEffect(() =>
    {
        if (loadingUp || loadingDown)
        {
            if (textSMSsData?.contents?.length)
            {
                setCurrentPage(textSMSsData.currentPage);
                setLastPage(Math.ceil(textSMSsData.numberOfEntries / 10));
                const { currentPage } = textSMSsData;
                if (initialPage === 0)
                {
                    setInitialPage(currentPage);
                    setUpPage(currentPage);
                    setDownPage(currentPage);
                    setTextSMSList(textSMSsData.contents);
                }
                else if (currentPage < initialPage)
                {
                    setUpPage(currentPage);
                    setTextSMSList([
                        ...textSMSsData.contents,
                        ...textSMSList,
                    ]);
                }
                else if (currentPage > initialPage)
                {
                    setDownPage(currentPage);
                    setTextSMSList([
                        ...textSMSList,
                        ...textSMSsData.contents,
                    ]);
                }
            }
            else
            {
                finishPagination();
            }
        }
    }, [textSMSsData]);

    useEffect(() =>
    {
        if (textSMSList?.length)
        {
            finishPagination();
        }
    }, [textSMSList]);

    function scrollToMessage(messageId: string | undefined): void
    {
        if (messageId)
        {
            const element = document.getElementById(messageId);
            if (element)
            {
                element.scrollIntoView({
                    behavior: 'auto',
                    block: 'start',
                });
            }
        }
    }

    function scrollToBottom(container: HTMLElement | null): void
    {
        if (container)
        {
            const scrollLimit =
                container.scrollHeight - container.clientHeight - paginationSensibility;
            container.scrollTo({
                behavior: 'smooth',
                top: lastScrollTop < scrollLimit ? lastScrollTop : scrollLimit,
            });
        }
    }

    function scrollToTop(container: HTMLElement | null): void
    {
        if (container)
        {
            container.scrollTo({
                behavior: 'smooth',
                top: lastScrollTop > paginationSensibility ? lastScrollTop : paginationSensibility,
            });
        }
    }

    function finishPagination(): void
    {
        const container = messageContainer.current;

        if (container)
        {
            if (loadingUp && container.scrollTop < paginationSensibility)
            {
                if ((textSMSList.length > 10) && (textSMSsData?.contents?.length))
                {
                    scrollToMessage(textSMSList[textSMSsData?.contents?.length]?.id);

                }
                else
                {
                    scrollToTop(container);
                }
            }

            if (
                loadingDown &&
                container.scrollTop
                > container.scrollHeight - container.clientHeight - paginationSensibility)
            {
                scrollToBottom(container);
            }

            if (!hasScrolledToSelectedMessage)
            {
                const checkMarkMessage = textSMSList.find((textSMS) => textSMS.id === data.id);
                if (checkMarkMessage)
                {
                    scrollToMessage(checkMarkMessage.id);
                    setHasScrolledToSelectedMessage(true);
                }
            }
        }

        setTimeout(() =>
        {
            setLoadingUp(false);
            setLoadingDown(false);
        }, 1000);
    }

    function changeMessage(textSMS: TextSMSData): void
    {
        dispatch(changeTextSMS(textSMS));
    }

    const scrollHandler = (event: React.UIEvent<HTMLDivElement>): void =>
    {
        const scrollTop = Math.floor(event.currentTarget.scrollTop);
        const height = Math.floor(
            event.currentTarget.scrollHeight - event.currentTarget.clientHeight
        );
        if (scrollTop <= 10 && !loadingUp)
        {
            if (upPage > 1)
            {
                setLoadingUp(true);
                dispatch(
                    getTextSMSsForDetails({
                        page: upPage - 1,
                        id: textSMSList[0].id,
                        externalNumber: data.externalNumber,
                        deviceNumber: data.deviceNumber,
                        sizePerPage: pageSize,
                        type: 'UP',
                        startDate: isAlertsPage ? alertsFilter.startDate : filters.startDate,
                        endDate: isAlertsPage ? alertsFilter.endDate : filters.endDate,
                        isDateFilterChanged: filters.isDateFilterChanged,
                    })
                );
            }
        }
        else if (scrollTop >= height - 10 && !loadingDown)
        {
            if (lastPage !== currentPage && textSMSList.length < 11)
            {
                setLoadingDown(true);
                setLastScrollTop(scrollTop);
                dispatch(
                    getTextSMSsForDetails({
                        page: downPage + 1,
                        id: textSMSList[textSMSList.length - 1].id,
                        externalNumber: data.externalNumber,
                        deviceNumber: data.deviceNumber,
                        sizePerPage: pageSize,
                        type: 'DOWN',
                        startDate: isAlertsPage ? alertsFilter.startDate : filters.startDate,
                        endDate: isAlertsPage ? alertsFilter.endDate : filters.endDate,
                        isDateFilterChanged: filters.isDateFilterChanged,
                    })
                );
            }

        }

    };
    async function handlerPDFExport(tableExportFormat: TableExportFormat): Promise<void>
    {
        setFormat(tableExportFormat);
        if (tableExportFormat === TableExportFormat.PDF)
        {
            await TableExporter.asPDF({
                type: DataType.SMS_EXPORT,
                recordIds: textSMSList.map((item) => item.id),
            });
        }
        setFormat(undefined);
    }

    async function handlerAllPDFExport(): Promise<void>
    {
        setFormatAll('PDF');
        const formdata = {
            type: DataType.SMS,
            externalNumber: data.externalNumber,
            deviceNumber: data.deviceNumber,
            startDate: isAlertsPage ? alertsFilter.startDate : filters.startDate,
            endDate: isAlertsPage ? alertsFilter.endDate : filters.endDate,
            device: data.deviceId,
        };
        dispatch(getSMSIdDetails(formdata));
    }

    return (
        <Box style={{ height: '100%' }}>
            {textSMSList && textSMSList.length > 0 ? (
                <>
                    <Button
                        variant="text"
                        startIcon={
                            TableExporter.loading && format === TableExportFormat.PDF
                                ? <CircularProgress size="1rem" />
                                : <DownloadIcon />
                        }
                        onClick={() => handlerPDFExport(TableExportFormat.PDF)}

                    >
                        PDF
                    </Button>
                    <PDFExport
                        selectedValue={handlerAllPDFExport}
                        ids={textSMSIds}
                        idStatus={textSMSIdstatus}
                        data={DataType.SMS_EXPORT}
                        formatAll={formatAll}
                        setFormatAll={setFormatAll}
                    />
                </>
            ) : null}
            <Box
                ref={messageContainer}
                onScroll={scrollHandler}
                sx={{
                    height: 500,
                    overflowY: 'scroll',
                }}
            >
                <Box style={pagingStyle}>
                    {loadingUp ? (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    ) : (
                        ' '
                    )}
                </Box>
                {textSMSList?.map((textSMS) => (
                    <DetailMessage
                        key={textSMS.id}
                        textSMS={textSMS}
                        data={data}
                        changeMessage={changeMessage}
                    />
                ))}
                <Box style={pagingStyle}>
                    {loadingDown ? (
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    ) : (
                        ' '
                    )}
                </Box>
            </Box>
        </Box>
    );
}
