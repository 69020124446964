import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'src/components/data-table/DataTable';
import { PlatformVersion } from 'src/models/administration/platformVersionData.model';
import { RequestStatus } from 'src/models/requestStatus.enum';
import {
    getPlatformVersion,
    selectPlatformVersions,
    selectPlatformVersionsStatus,
    selectAdminFilters,
} from 'src/state/administration/administrationSlice';
import EditIcon from '@mui/icons-material/Edit';
import Update from '@mui/icons-material/SystemUpdateAlt';
import {
    Alert, AlertProps, Box, Checkbox, Grid, IconButton, Snackbar, SnackbarProps, Stack,
} from '@mui/material';
import { VersionEditionModal } from 'src/templates/version-edit-modal/VersionEditionModal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { TopBar } from 'src/components/top-bar/TopBar';
import { AdminSettingSearchFilter } from '../admin-setting-search-filter/AdminSettingSearchBar';
import { VersionBatchUpdateModal } from '../version-batch-update-modal/VersionBatchUpdateModal';
import { VersionAgencyModal } from '../version-batch-update-modal/VersionAgencyModal';
import {
    getAgencyFilterOptions,
    selectAgencyFilterOptions,
} from 'src/state/captures/capturesSlice';
import { CreateButton } from 'src/components/top-bar/CreateButton';

export function VersionManagement(): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(selectPlatformVersionsStatus);
    const platformVersions = useSelector(selectPlatformVersions);
    const agencyFilters = useSelector(selectAgencyFilterOptions);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [versionEditModal, setVersionEditModal] = useState<boolean>(false);
    const [versionBatchUpdateModal, setVersionBatchUpdateModal] = useState<boolean>(false);
    const [versionAgencyModal, setVersionAgencyModal] = useState<boolean>(false);
    const [editData, setEditData] = useState<PlatformVersion>();
    const [versionCreateModal, setVersionCreateModal] = useState<boolean>(false);
    const [agencyType, setAgencyType] = useState<string>();
    const filters = useSelector(selectAdminFilters);
    const [notificationState, setNotificationState] = useState<SnackbarProps & AlertProps>();
    // eslint-disable-next-line
    const [agencyModalData, setAgencyModalData] = useState<any>();
    // eslint-disable-next-line
    const [nonComplianceAgencyIds, setNonComplianceAgencyIds] = useState<any>();
    function showModal(data: PlatformVersion): void
    {
        setVersionEditModal(true);
        setEditData(data);
    }
    function showBatchUpdate(data: PlatformVersion): void
    {
        setVersionBatchUpdateModal(true);
        setEditData(data);
    }
    function showAgencyVerison(data: PlatformVersion): void
    {
        setAgencyType('compliant');
        const agencyArray: {
            agencyName: string;
            version: string | undefined;
            agencyId: string
        }[] = [];
        setVersionAgencyModal(true);
        setEditData(data);
        if ((agencyFilters?.length) && (data.agencyIds))
        {
            const ids = data?.agencyIds.map((item) => item.agency);
            const filterData = agencyFilters?.filter((item: { value: string; }) =>
                ids.includes(item.value));
            filterData?.map((agency: {
                value: string; name: string;
            }) =>
            {
                return agencyArray.push({
                    agencyName: agency.name,
                    version: data.version,
                    agencyId: agency.value,
                });
            });

            if (agencyArray.length)
            {
                setAgencyModalData({
                    contents: agencyArray,
                    numberOfEntries: agencyArray.length,
                    currentPage: 1,
                });
            }
        }
    }
    function showNonComplianceAgency(data: PlatformVersion): void
    {
        setEditData(data);
        setVersionAgencyModal(true);
        setAgencyType('non-compliant');
        if (data.agencyIds?.length)
        {
            const noncomplianceAgencyId = data?.agencyIds.map((item) =>
            {
                return item.agency;
            });
            const filterData = agencyFilters?.filter((item: { value: string; }) =>
                !noncomplianceAgencyId.includes(item.value));
            const ids = filterData?.map((item) => item.value);
            setNonComplianceAgencyIds(ids);

        }
        else
        {
            const ids = agencyFilters?.map((item) => item.value);
            setNonComplianceAgencyIds(ids);

        }
    }

    function handleAddButtonClick(): void
    {
        setVersionCreateModal(true);
    }
    function closeCreateModal(): void
    {
        setVersionCreateModal(false);
    }

    function closeModal(): void
    {
        setVersionEditModal(false);
        setVersionBatchUpdateModal(false);
        setVersionAgencyModal(false);
    }

    function onCloseNotification(): void
    {
        setNotificationState((current) => ({
            ...current,
            open: false,
        }));
    }
    function onComplete(): void
    {
        const subject = t('data-type.table.version');
        setNotificationState({
            open: true,
            message: t('common.messages.saved-successfully', { subject }),
        });
        setTimeout(() => dispatch(getPlatformVersion({
            pageNumber: 1,
            entriesPerPage,
            ...filters,
        })), 1000);
    }

    function onPageChange(pageNumber: number): void
    {
        dispatch(getPlatformVersion({ pageNumber, entriesPerPage, ...filters }));
    }
    useEffect(() =>
    {
        dispatch(getAgencyFilterOptions());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() =>
    {
        dispatch(getPlatformVersion({ pageNumber: 1, entriesPerPage, ...filters }));

    }, [dispatch, entriesPerPage, filters]);

    const getUpdateColumn = (data: PlatformVersion): React.ReactNode => (
        <IconButton
            aria-label="edit"
            onClick={() => showBatchUpdate(data)}
        >
            <Update />
        </IconButton>
    );
    const getCompliantAgency = (data: PlatformVersion): React.ReactNode => (
        <button
            style={{ color: 'green', cursor: 'pointer', border: 'none', background: 'none' }}
            onClick={() => showAgencyVerison(data)}
        >
            {data.agencyIds?.length}
        </button>
    );
    const getNonCompliantAgency = (data: PlatformVersion): React.ReactNode =>
    {
        // eslint-disable-next-line
        let unmatchedData: any;
        if (data?.agencyIds)
        {
            const ids = data?.agencyIds.map((item) => item.agency);
            if (ids.length > 0)
            {
                unmatchedData = agencyFilters?.filter((item) => !ids.includes(item.value));
                return (
                    <button
                        onClick={() => showNonComplianceAgency(data)}
                        style={{
                            color: 'red',
                            cursor: 'pointer',
                            border: 'none',
                            background: 'none',
                        }}
                    >
                        {unmatchedData?.length}
                    </button>
                );
            }
            else
            {
                return (
                    <button
                        onClick={() => showNonComplianceAgency(data)}
                        style={{
                            color: 'red',
                            cursor: 'pointer',
                            border: 'none',
                            background: 'none',
                        }}
                    >
                        {agencyFilters?.length}
                    </button>
                );
            }
        }
        else
        {
            return (
                <button
                    onClick={() => showNonComplianceAgency(data)}
                    style={{
                        color: 'red',
                        cursor: 'pointer',
                        border: 'none',
                        background: 'none',
                    }}
                >
                    {agencyFilters?.length}
                </button>
            );
        }
    };
    const getDowngrade = (data: PlatformVersion): React.ReactNode =>
    {
        if (!data.downgrade)
        {
            return '-';
        }
        return (
            <div>
                <Checkbox
                    checked={data.downgrade}
                />
            </div>
        );
    };
    const getEditColumn = (data: PlatformVersion): React.ReactNode => (
        <IconButton
            aria-label="edit"
            onClick={() => showModal(data)}
        >
            <EditIcon />
        </IconButton>
    );

    const columns = [
        {
            label: t('common.nouns.platform'),
            size: 1,
            value: (data: PlatformVersion) => data.platform,
        },
        {
            label: t('data-type.table.version'),
            size: 1,
            value: (data: PlatformVersion) => data.version,
        },
        {
            label: t('data-type.details.compliant-agency'),
            size: 1,
            value: (data: PlatformVersion) => getCompliantAgency(data),

        },
        {
            label: t('data-type.details.non-compliant-agency'),
            size: 1,
            value: (data: PlatformVersion) => getNonCompliantAgency(data),

        },
        {
            label: t('data-type.table.downloadUrl'),
            size: 1,
            value: (data: PlatformVersion) => data.downloadUrl,
        },
        {
            label: t('common.actions.batch-update'),
            size: 1,
            format: (data: PlatformVersion) => getUpdateColumn(data),
        },
        {
            label: t('common.actions.down-version'),
            size: 1,
            format: (data: PlatformVersion) => getDowngrade(data),

        },
        {
            label: t('common.actions.edit'),
            size: 1,
            format: (data: PlatformVersion) => getEditColumn(data),
        },
    ];

    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-end"
                padding={'1em 0'}
            >
                <Box sx={{ mt: 2.5, mr: 3 }}>
                    <Grid
                        item
                        md={3}
                        sm={12}
                        xs={12}
                    >
                        <AdminSettingSearchFilter />
                    </Grid>
                </Box>
                <TopBar
                    buttons={[
                        {
                            text: (
                                <CreateButton
                                    width="110px"
                                    label={t('menu.create-new-version')}
                                />
                            ),
                            icon: <AddCircleIcon />,
                            onClick: handleAddButtonClick,
                        },
                    ]}
                />
            </Stack>

            <DataTable<PlatformVersion>
                isLoading={status === RequestStatus.InProgress}
                columns={columns}
                onPageChange={onPageChange}
                entriesPerPage={entriesPerPage}
                onChangeEntriesPerPage={setEntriesPerPage}
                {...platformVersions}
            />
            {
                versionEditModal && (
                    <VersionEditionModal
                        data={editData}
                        open={versionEditModal}
                        onClose={closeModal}
                        onActionComplete={onComplete}
                    />
                )
            }

            {
                versionBatchUpdateModal && (
                    <VersionBatchUpdateModal
                        data={editData}
                        open={versionBatchUpdateModal}
                        onClose={closeModal}
                        onActionComplete={onComplete}
                    />
                )
            }

            {
                versionAgencyModal && (
                    <VersionAgencyModal
                        data={agencyModalData}
                        open={versionAgencyModal}
                        onClose={closeModal}
                        onActionComplete={onComplete}
                        versionData={editData?.agencyIds}
                        versionPlatform={editData?.platform}
                        editData={editData}
                        agencyType={agencyType}
                        nonComplianceAgencyIds={nonComplianceAgencyIds}
                    />
                )

            }

            {versionCreateModal && (
                <VersionEditionModal
                    open={versionCreateModal}
                    onClose={closeCreateModal}
                    onActionComplete={onComplete}
                />
            )}
            <Snackbar
                autoHideDuration={6000}
                open={notificationState?.open}
                onClose={onCloseNotification}
            >
                <Alert
                    severity={notificationState?.severity}
                >
                    {notificationState?.message}
                </Alert>
            </Snackbar>
        </>
    );
}
