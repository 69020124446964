import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/modal/Modal';
import { noop } from 'src/utils/common';
import {
    PlatformVersion,
} from 'src/models/administration/platformVersionData.model';
import { AgencyVersionUpdateForm } from '../agency-version-update-form/AgencyVersionUpdateForm';
import { getPlatformVersion } from 'src/services/administration/profiles';
import { useDispatch } from 'react-redux';

export interface VersionEditionModalProps {
    data?: PlatformVersion;
    open?: boolean;
    onClose?: () => void;
    onActionComplete?: () => void;
    // eslint-disable-next-line
    datas?: any;
}

export function AgencyVersionUpdateModal({
    data,
    open = true,
    onClose = noop,
    onActionComplete = noop,
    datas,
}: Readonly<VersionEditionModalProps>): React.ReactElement
{
    const { t } = useTranslation();
    const dispatch = useDispatch();

    function closeModal(status: boolean):void
    {
        onClose();
    }
    function onComplete(): void
    {
        setTimeout(() => dispatch(getPlatformVersion({
            pageNumber: 1,
            entriesPerPage: 10,
        })), 1000);
    }
    return (
        <Modal
            title={`${t('form.agency-version-management')} - ${datas.name}`}
            open={open}
            onClose={closeModal}
            showCloseButton={true}
            maxWidth="md"
            fullWidth
        >
            <Box>
                <AgencyVersionUpdateForm
                    {...data}
                    {...datas}
                    closeModal={closeModal}
                    onComplete={onComplete}
                    onActionComplete={onActionComplete}
                />
            </Box>
        </Modal>
    );
}
